import React, { Component } from 'react';
import Logo from "./icons/logo.png";
import * as API from './api.js';
import './style.css';
import loaderGIF from './res/loader.gif';
import Help from "./sidebaricon/Help.png";
import Settings from './sidebaricon/SettingsActive.png';
import footer from './res/footer.jpg';
import { Link, Redirect } from 'react-router-dom';
import SideBar from './SideBar';
import AppConstants from './AppConstants';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import dateFormat from 'dateformat';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import AeroMeghLogo from "./aeromeghLog.png";
import { formatToIndianRupees } from './ReusableComponents/reusableFunctions.js';
export default class Invoice extends Component {
    state = {
        user: {},
        type: "",
        org: {},
        loading: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        invoiceID: this.props.location.state.invoiceId,
        activityPopupObj: {
            item: undefined,
            open: undefined,
            msg: undefined,
        }
    }


    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentDidMount() {
        if (this.props.location.state.invoice) {
            let { invoice } = this.props.location.state
            console.log(invoice)
            let product = invoice.product
            let basePrice = invoice.price
            //let additionalCost = product.additional_cost * invoice.units
            //let subTotal = basePrice + additionalCost
            let discount = basePrice * (invoice.discount / 100)
            discount = discount.toFixed(2)
            let discounted = basePrice - discount
            //let tax = discounted * (product.tax_percent / 100)
            let total = discounted.toFixed(2)

            this.setState({
                invoice,
                basePrice,
                //additionalCost,
                //subTotal,
                discount,
                //discounted,
                //tax,
                total,
                loading: false
            })
        } else
            API.getInvoice(this.state.invoiceID).then(res => {
                let { invoice } = res
                let product = invoice.product
                let basePrice = invoice.price
                //let additionalCost = product.additional_cost * invoice.units
                //let subTotal = basePrice + additionalCost
                let discount = basePrice * (invoice.discount / 100)
                discount = discount.toFixed(2)
                let discounted = basePrice - discount
                //let tax = discounted * (product.tax_percent / 100)
                let total = discounted.toFixed(2)

                this.setState({
                    invoice,
                    basePrice,
                    //additionalCost,
                    //subTotal,
                    discount,
                    //discounted,
                    //tax,
                    total,
                    loading: false
                })
            }).catch(e => {
                this.setState({
                    activityPopupObj: {
                        item: 'ERROR',
                        open: true,
                        msg: "Currupted Invoice",
                    }
                })
            })
    }

    componentWillMount() {
        document.title = `Invoice - ${this.state.invoiceID} - AeroMegh`;
        if (this.props.location.state) {
            let { user, type, organizationID, modelNumber } = this.props.location.state;
            if (!user.sub) {
                user.sub = {
                    "plan": "aeromegh_plan_free"
                }
            }

            this.setState((state) => ({
                ...state,
                user,
                type,
                organizationID,
                modelNumber
            }), () => {
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                if (!user.sub) {
                    user.sub = {
                        "plan": "aeromegh_plan_free"
                    }
                }
                console.log("ROLE ", type);
                console.log("USER ", user)
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    downloadPDF = () => {
        const input = document.querySelector('.myDiv');

        html2canvas(input, {
            scale: 3,
            useCORS: true,
            backgroundColor: null,
        }).then(canvas => {
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const heightLeft = imgHeight;

            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });

            let position = 0;

            pdf.addImage(
                canvas.toDataURL('image/png'),
                'PNG',
                0,
                position,
                imgWidth,
                imgHeight
            );

            let remainingHeight = heightLeft;
            while (remainingHeight > pageHeight) {
                position -= pageHeight;
                pdf.addPage();
                pdf.addImage(
                    canvas.toDataURL('image/png'),
                    'PNG',
                    0,
                    position,
                    imgWidth,
                    imgHeight
                );
                remainingHeight -= pageHeight;
            }

            pdf.save('invoice.pdf');
        });
    };

    render() {
        let { user } = this.state
        return (
            user.username ? <div className="wrapper">
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >

                    <ActivityPopup
                        item={this.state.activityPopupObj?.item}
                        open={this.state.activityPopupObj?.open}
                        msg={this.state.activityPopupObj?.msg}
                        close={() => window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""))}
                    />

                    {this.state.redirectToInvoicePage && <Redirect to={{
                        pathname: `/invoice`,
                        state: this.state
                    }}></Redirect>}
                    <div className="top-bar-new" style={{ display: "flex", zIndex: '2' }}>
                        <div className="top-bar-text-new">Invoice</div>
                    </div>
                    <div className="main-content">
                        {/* <div className="print-button-wrapper">
                            <button className="addcustomer-button-empty-dashboard" onClick={() => {
                                window.print();
                            }}>
                                Print Invoice
                            </button>
                        </div> */}
                        {/* <div className="page" style={{ padding: "0px", height: "auto", minHeight: "600px", paddingBottom: "0px", width: "627px", minWidth: "627px" }}>
                            <div id="invoice-page">
                                {
                                    this.state.loading ? <img src={loaderGIF} style={{ margin: "auto" }} /> : <div id="invoice-contents">
                                        <div style={{ height: "100px", "width": "100%", display: "flex" }}><h3 style={{ margin: "auto", fontWeight: "bold" }}>INVOICE</h3></div>
                                        <table id="invoice-metadata-table">
                                            <tr id="invoice-metadata-table-row1">
                                                <th id="invoice-metadata-table-row1-invoice">ID</th>
                                                <th id="invoice-metadata-table-row1-created">Date: </th>
                                                <td id="invoice-metadata-table-row1-created-date">{new Date(this.state.invoice?.payment?.date_time || this.state.invoice.date_time).toLocaleDateString()}</td>
                                            </tr>
                                            <tr id="invoice-metadata-table-row2">
                                                <th id="invoice-metadata-table-row1-id">{this.state.invoice?.invoice_id || this.state.invoice.id}</th>
                                                <th id="invoice-metadata-table-row1-paid">Status: </th>
                                                <td id="invoice-metadata-table-row1-paid-date">{this.state.invoice?.payment?.payment_status || this.state.invoice.payment_status}</td>
                                            </tr>
                                        </table>
                                        <div id="invoice-pdrl">
                                            <h6>
                                            </h6>
                                        </div>
                                        <table id="invoice-product-table">
                                            {this.state.invoice?.product_type === AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.TASKS_CREDITS && <tr>
                                                <th>Product</th>
                                                <td>{`${this.state.invoice.name} (${Number(this.state.invoice.credits) / 1024} GB)`}</td>
                                            </tr>}
                                            <tr>
                                                <th>{this.state.invoice?.product_type === AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.TASKS_CREDITS ? "Payment id" : "Subscription:"}</th>
                                                <td>{this.state.invoice?.product_type === AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.TASKS_CREDITS ? this.state.invoice.payment_id : this.state.invoice.product.replaceAll("_", " ").split(" ").map(word => word.split("").map((letter, i) => i === 0 ? letter.toUpperCase() : letter.toLowerCase()).join("")).join(" ")} </td>
                                            </tr>
                                            {this.state.invoice?.product_type === AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.SERVICES_PLAN && <tr>
                                                <th>Subscription id:</th>
                                                <td>{this.state.invoice.payment?.subscription_id} </td>
                                            </tr>}
                                        </table>
                                        <table id="invoice-cost-breakdown-table">
                                            <tr style={{ margin: "1% 0%" }}>
                                                <th className="tr-left">Description</th>
                                                <th className="tr-right">Amount</th>
                                            </tr>
                                            <tr>
                                                <td className="tr-left">Price</td>
                                                <td className="tr-right">{(this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "inr" ? "₹" : (this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "usd" ? "$" : "₹"}{this.state.basePrice}</td>
                                            </tr>
                                            {this.state.invoice?.product_type === AppConstants.SUBSCRIPTOPTIONS.PRODUCT_TYPES.TASKS_CREDITS && <>
                                                <tr>
                                                    <td colspan="2">
                                                        <hr />
                                                    </td>
                                                </tr> <tr>
                                                    <td className="tr-left">Quantity</td>
                                                    <td className="tr-right">×{this.state.invoice.units}</td>
                                                </tr>
                                            </>}


                                            <tr>
                                                <td colspan="2">
                                                    <hr />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tr-left">Discount</td>
                                                <td className="tr-right">-{(this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "inr" ? "₹" : (this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "usd" ? "$" : "₹"}{this.state.discount}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <hr />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2">
                                                    <hr />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="tr-left"><h4>Total</h4></td>
                                                <td className="tr-right">{(this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "inr" ? "₹" : (this.state.invoice?.payment?.currency || this.state.invoice?.currency) === "usd" ? "$" : "₹"}{this.state?.invoice?.units ? (Number(this.state.invoice.units) * this.state.total) : this.state.total}</td>
                                            </tr>
                                        </table>
                                    </div>
                                }
                            </div>
                            <img src={footer} style={{ height: "auto", width: "100%" }} />
                        </div> */}

                        <div style={{ overflow: "auto", position: "relative" }}>
                            <a
                                onClick={() => this.downloadPDF()}
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '340px',
                                    textDecoration: 'underline',
                                    color: '#007BFF',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                }}
                            >
                                Download Invoice
                            </a>
                            <div style={{
                                width: '620px',
                                margin: '40px auto',
                                border: '1px solid rgba(215, 218, 224, 0.4)',
                                borderRadius: '5px',
                                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                color: "#3B3B3B",
                                backgroundColor: '#fff'
                            }}>
                                <div className='myDiv'>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        margin: '0px 30px',
                                        padding: '20px 0px',
                                        borderBottom: '1px solid rgba(215, 218, 224, 0.3)',
                                    }}>
                                        <div>
                                            <img src={AeroMeghLogo} alt="AElogo" width={"100px"} />
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <p style={{ margin: '0', fontSize: "13px", }}><span style={{ fontWeight: 600, color: "#1A1C21" }}>Invoice No.</span> - {this.state.invoice?.invoice_number || "-"}</p>
                                        </div>
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        color: "#727272",
                                        padding: "20px 30px"
                                    }}>
                                        <div>
                                            <div style={{ marginBottom: '20px' }}>
                                                <p style={{ margin: '0', fontWeight: 500 }}>To</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{`${this.state.invoice?.to?.name}` || "-"}</p>
                                                {this.state.invoice?.to?.mobileNumber && <p style={{ margin: '0' }}>{this.state.invoice?.to?.mobileNumber}</p>}
                                                <p style={{ margin: '0' }}>{this.state.invoice?.to?.address || "-"}</p>
                                            </div>
                                            <div>
                                                <p style={{ margin: '0', fontWeight: 500 }}>From</p>
                                                <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{this.state.invoice?.from?.name}</p>
                                                <p style={{ margin: '0' }}>{this.state.invoice?.from?.address}</p>
                                                <p style={{ margin: '0' }}>GSTIN - {this.state.invoice?.from?.gstin}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{
                                        borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                                        paddingTop: '10px',
                                        margin: "10px 30px 30px",
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}>
                                        <p style={{ margin: '0', fontSize: "14px" }}>
                                            <span style={{ fontWeight: 600, color: "#5E6470" }}>Invoice Date - </span>
                                            <span style={{ color: "#5E6470" }}>{dateFormat(this.state.invoice?.payment?.date_time, "dd mmm yyyy")}</span>
                                        </p>
                                        <p style={{ margin: '0', fontSize: "14px" }}>
                                            <span style={{ fontWeight: 600, color: "#5E6470" }}>Subscription id - </span>
                                            <span style={{ color: "#5E6470" }}>{this.state.invoice?.payment?.subscription_id}</span>
                                        </p>
                                    </div>

                                    <div style={{
                                        backgroundColor: '#D9D9D9',
                                        padding: '10px 25px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        fontWeight: 700,
                                        color: '#1E1E1E',
                                        height: "50px",
                                        alignItems: "center",
                                        fontSize: "18px"
                                    }}>
                                        <span>Payment Summary</span>
                                        <span>Rates</span>
                                    </div>

                                    <div style={{ padding: '10px 30px 50px' }}>
                                        <div style={{ marginBottom: '10px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                                <span>{this.state.invoice?.product?.replaceAll("_", " ").split(" ").map(word => word.split("").map((letter, i) => i === 0 ? letter.toUpperCase() : letter.toLowerCase()).join("")).join(" ")}</span>
                                                <span>₹ {formatToIndianRupees(this.state.basePrice || 0)}</span>
                                            </div>
                                        </div>
                                        {this.state.discount > 0 && <div style={{ marginBottom: '10px' }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                                <span>Discount</span>
                                                <span>₹ {formatToIndianRupees(this.state.discount || 0)}</span>
                                            </div>
                                        </div>}
                                        {/* {this.state.invoice?.applied_csgt_amount > 0 &&
                                            <div style={{ marginBottom: '10px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                                    <span>CGST</span>
                                                    <span style={{}}>+ ₹ {formatToIndianRupees(this.state.invoice?.applied_csgt_amount || 0)}</span>
                                                </div>
                                            </div>
                                        } */}
                                        {/* {this.state.invoice?.applied_sgst_amount > 0 &&
                                            <div style={{ marginBottom: '10px' }}>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                                    <span>SGST</span>
                                                    <span style={{}}>+ ₹ {formatToIndianRupees(this.state.invoice?.applied_sgst_amount || 0)}</span>
                                                </div>
                                            </div>
                                        } */}
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            paddingTop: '10px',
                                            marginTop: '10px',
                                            marginBottom: '20px',
                                            fontWeight: 'bold',
                                            borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                                            borderBottom: '1px solid rgba(215, 218, 224, 0.6)',
                                            paddingBottom: '10px',
                                            fontSize: "18px"
                                        }}>
                                            <span>Total Amount</span>
                                            <span>₹ {formatToIndianRupees(this.state.total || 0)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div> : <div />
        );
    }
}

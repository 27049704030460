import React, { useEffect, useState } from "react"
import Confetti from "react-confetti"
import { Link } from 'react-router-dom'
const PaymentSuccessful = (props) => {
    const [message, setMessage] = useState("");
    useEffect(() => {
        if (props?.location?.state) {
            setMessage(props.location.state.message)
        }
    }, [props?.location?.state])
    return <>
        <Confetti recycle={false} numberOfPieces={500} />
        <div style={{ width: "100%", height: '100vh', display: 'flex', justifyContent: "center", alignItems: "center" }}>
            <div className="page" style={{ padding: "40px", height: "fit-content", position: "relative", width: "500px" }}>
                <div style={{ textAlign: "center" }}>{message || "Payment successfull"}</div>
                <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
                    <Link to={{
                        pathname: "/profile-settings",
                        state: { view: 2 }
                    }} style={{
                        color: "#2989cf"
                    }}>

                        Ok
                    </Link>
                </div>
            </div>
        </div>
    </>
}
export default PaymentSuccessful
import React, { useState } from 'react';
import Search from './Search';
import DateTimePicker from '../../AeroGCSEnterprise/Components/FlightsFilter/DateTimePicker';
import './SearchHeader.css';
import Viewbuttons from './ViewButtons';
const SearchHeader = ({
    defaultFromDate,
    defaultToDate,
    placeholder,
    searchString,
    onFilterDataChange,
    isShowViewButtons,
    isShowDateTimePicker,
    defaultView,
    isDisabled
}) => {
    var today = new Date();
    var fromDateTime, toDateTime;
    if (defaultFromDate !== undefined && defaultToDate !== undefined) {
        if (defaultFromDate) {
            fromDateTime = new Date(defaultFromDate)
        } else {
            fromDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 1);
        }

        if (defaultToDate) {
            toDateTime = new Date(defaultToDate)
        } else {
            toDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999);
        }
    }


    if (!isShowDateTimePicker) {
        fromDateTime = undefined;
        toDateTime = undefined;
    }

    const [globalState, setGlobalState] = useState({
        fromDateTime: fromDateTime === undefined ? undefined : new Date(fromDateTime),
        toDateTime: toDateTime == undefined ? undefined : new Date(toDateTime),
        selectView: defaultView || "list",
        searchString: searchString || undefined,
    });


    const [openDatepicker, setOpenDatepicker] = useState({
        fromdatePicker: false,
        todatePicker: false,
    });

    const handleStateChange = (updatedField, value) => {
        var updatedValues = {};
        if (updatedField === "fromDateTime" || updatedField == "toDateTime") {
            updatedValues = { ...globalState, [updatedField]: value, dateChanged: true };
            setGlobalState((prev) => ({
                ...prev,
                ...updatedValues
            }))
            setOpenDatepicker((prev) => ({
                ...prev,
                fromdatePicker: false,
                todatePicker: false
            }))
        } else {
            updatedValues = { ...globalState, [updatedField]: value, searchChanged: true };
            setGlobalState((prev) => ({
                ...prev,
                ...updatedValues,

            }))
        }
        onFilterDataChange(updatedValues, updatedField);
    }

    return (
        <div className='main_wrapper_search'>
            <div className='first_section_search'>
                <Search placeholder={placeholder} isDisabled={isDisabled} globalState={globalState} handleStateChange={handleStateChange} />
            </div>
            <div className='second__section_search'>
                {isShowDateTimePicker && <div className='datePikcer_search '>
                    <DateTimePicker
                        formdatePicker={openDatepicker.fromdatePicker}
                        todatePicker={openDatepicker.todatePicker}
                        fromDateTime={globalState.fromDateTime}
                        toDateTime={globalState.toDateTime}
                        setOpenDatepicker={setOpenDatepicker}
                        handleFilterChange={handleStateChange}
                        styles={{ padding: "11px 10px" }}
                        format="yyyy"
                        view="year"
                    />
                </div>}
                {isShowViewButtons && (
                    <Viewbuttons globalState={globalState} handleStateChange={handleStateChange} />
                )}
            </div>
        </div>
    )
}


export default SearchHeader;
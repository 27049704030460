/*
- this component is used to assign the drone

*/

import React, { Component, useEffect } from "react";
import Geocode from "react-geocode";
import dateFormat from 'dateformat';
import Noimageset from '../assets/Images/Noimageset.png';
import ImageSetThumb from '../icons/ImageSetThumb.png'
import { SortComponent } from "./SortComponent";
Geocode.setApiKey("AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI");
Geocode.setLanguage("en");


export default class viewImageInList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sortTypeName: false,
            sortTypeDate: false,
        };
    }
    componentWillMount() {

    }
    render() {
        return (
            <>
                {this.props.inspect && this.props.inspect.length ? <table className="customers-table2">
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '40%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <tr className="customers-table-header-row">
                        <th></th>
                        <th>Name
                            <SortComponent
                                style={{ marginLeft: '5px' }}
                                sortAscending={() => this.props.onClickSort('A-Z')}
                                sortDecending={() => this.props.onClickSort('Z-A')}
                                sortASC={false}
                            />
                            {/* <sapn style={{ marginLeft: '5px' }}>
                                <img src={this.props.sortType === 'Z-A' ? sortUpActive : sortUp} style={{ width: '10px', height: '10px', cursor: 'pointer', margin: '0px 3px' }} onClick={() => { this.props.onClickSort('Z-A') }} />
                                <img src={this.props.sortType === 'A-Z' ? sortDownActive : sortDown} style={{ width: '10px', height: '10px', cursor: 'pointer', margin: '0px 3px' }} onClick={() => { this.props.onClickSort('A-Z') }} />
                            </sapn> */}
                        </th>
                        <th style={{ textAlign: 'center' }}>Date
                            <SortComponent
                                style={{ marginLeft: '5px' }}
                                sortAscending={() => this.props.onClickSort('First Modified')}
                                sortDecending={() => this.props.onClickSort('Last Modified')}
                                sortASC={false}
                            />
                            {/* <sapn style={{ marginLeft: '5px' }}>
                                <img src={this.props.sortType === 'Last Modified' ? sortUpActive : sortUp} style={{ width: '10px', height: '10px', cursor: 'pointer', margin: '0px 3px' }} onClick={() => { this.props.onClickSort('Last Modified') }} />
                                <img src={this.props.sortType === 'First Modified' ? sortDownActive : sortDown} style={{ width: '10px', height: '10px', cursor: 'pointer', margin: '0px 3px' }} onClick={() => { this.props.onClickSort('First Modified') }} />
                            </sapn> */}
                        </th>
                        <th style={{ textAlign: 'center' }}>Size (MB)</th>
                        <th style={{ textAlign: 'center' }}>Resolution (MP)</th>
                    </tr>


                    {this.props.inspect.map((image, key) =>
                        <tr className="customers-table-data-row1" style={{ height: "45px !impotant", paddingLeft: '5px !important' }}
                        >

                            <td style={{ display: 'flex' }}>
                                <span style={{ width: '20px', marginRight: '5px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {this.props.showCheckboxes ? <input type='checkbox' id={image.file_name} checked={image.checked} style={{ margin: '8px 0px', height: '15px', width: '15px' }}
                                        onClick={() => { this.props.onClickOnCheckBox(Number(key)) }}
                                    /> : <> </>
                                    }
                                </span>
                                <span style={{ height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                    <img id="ProjectTableImage"
                                        style={{ objectFit: 'cover', padding: 'unset' }}
                                        onError={(e) => { e.currentTarget.src = ImageSetThumb }}
                                        loading="lazy"
                                        src={image.thumbURL}
                                    />
                                </span>
                            </td>
                            <td style={{ cursor: 'pointer', }} onClick={() => {
                                this.props.onClickOnPoint(Number(key))
                            }}>{image.file_name}</td>
                            <td style={{ textAlign: 'center', paddingLeft: "20px" }}>{dateFormat(image.created_on, 'dd-mm-yyyy')}</td>
                            <td style={{ textAlign: 'center', paddingLeft: "20px" }}>{image.file_size}</td>
                            <td style={{ textAlign: 'center', paddingLeft: "20px" }}>{this.props.task && this.props.task.linked_map || Number(image.resolution) == 0 ? "-" : image.resolution}</td>
                        </tr>
                    )}
                </table> : <div className="rmp-dashboard-empty-data-message" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                    <img style={{ height: '180px', width: '180px' }} src={Noimageset} />
                    <p style={{ marginTop: '10px' }}>No images available</p>
                </div>}
            </>
        );
    }
}
import React, { useEffect, useState } from 'react';
import TableView from '../../ReusableComponents/TableView/TableView';
import * as API from '../../api.js';
import { BreadCrumbs } from '../../ReusableComponents/BreadCrumbs.js';

const TeamGivenAcces = (props) => {

    const [loading, setLoading] = useState(true)
    const [assignedProjects, setAssignedProjects] = useState([])
    const [assignedDrones, setAssignedDrones] = useState([])

    const getAssigndData = () => {
        setLoading(true)
        try {
            API.getAssigndData(props?.location?.state?.teamUser?.username).then((data) => {
                setAssignedProjects(data.projects)
                setAssignedDrones(data.drones)
                setLoading(false)
            })
        } catch (err) {
            console.log("error : ", err);
        }
    }

    useEffect(() => {
        getAssigndData()
    }, [])

    return (<div className="wrapper">
        <div className="right-content-new">
            <div className="top-bar-new">
                <div className="top-bar-text-new">
                    <BreadCrumbs
                        data={[
                            {
                                displayName: "Teams",
                                pathname: "/teams",
                                state: { user: props.location.state.user }
                            },
                            { displayName: `${props?.location?.state?.teamUser?.username}` }]}
                    />
                </div>
            </div>

            <div className="main-content-new gcs-projects-screen">
                <div style={{}}>
                    <div style={{ width: "100%" }}>
                        <div style={{ fontSize: "12px", fontWeight: "300", color: "#3c3c3c", padding: "10px 0px" }}>Projects</div>
                        <TableView
                            isLoading={false}
                            onClickRow={() => { }}
                            columns={[
                                { Header: 'Project Name', accessor: 'project_name', width: "30%" },
                                { Header: 'Role', accessor: 'title', width: "70%" },
                            ]}
                            data={assignedProjects}
                            onStateChange={(changedState) => {
                            }}
                        />
                    </div>
                    <hr />
                    <div style={{ width: "100%" }}>
                        <div style={{ fontSize: "12px", fontWeight: "300", color: "#3c3c3c", padding: "10px 0px" }}>Drones</div>
                        <TableView
                            isLoading={false}
                            onClickRow={() => { }}
                            columns={[
                                { Header: 'Drone Id', accessor: 'drone_id', width: "25%" },
                                { Header: 'UIN', accessor: 'uin', width: "25%" },
                                { Header: 'Drone Name', accessor: 'drone_name', width: "25%" },
                                { Header: 'Role', accessor: 'title', width: "25%" },
                            ]}
                            data={assignedDrones}
                            onStateChange={(changedState) => {
                            }}
                        />
                    </div>
                    <hr />
                </div>
            </div>
        </div>
    </div >)
}

export default TeamGivenAcces;
import React, { Component } from 'react';
import waiting from '../res/waiting.png';
import { getActiveTeam } from '../Teams/index.js';
import success from '../assets/check_circle_24px.png';
import Popup from "reactjs-popup";
import closeButton from '../close-button.png';
import { Modal } from "react-bootstrap";
import * as API from '../api.js';
import '../style.css';
import Settings from '../sidebaricon/Settings.png';
import editIcon from '../edit.svg';
import editActive from '../Icon/editActive.svg';
import ButtonWithIcon from '../ReusableComponents/ButtonWithIcon.js';
import ChangeMobileNumber from '../userAuth/ChangeMobileNumber.js';
import verifiedIcon from '../assets/tick-mark.png';

class MyProfile extends Component {

    state = {
        subscriptions: [],
        user: {},
        type: "",
        avatarURL: '',
        createIcon: waiting,
        org: {},
        models: [],
        model: {},
        subscriptionTitleMap: {
            "aerogcs_plan_pro": "AeroMegh Pro",
            "aerogcs_plan_free": "AeroMegh Free",
            "aeromegh_plan_pro": "AeroMegh Pro",
            "aeromegh_plan_free": "AeroMegh Free",
            "aerostream_plan_starter": "AeroStream Starter",
            "aerostream_plan_pro": "AeroStream Pro"
        },
        saveBtnStyle: {
            fontSize: '11px',
            fontWeight: '500',
            color: 'white',
            backgroundColor: '#2989cf',
            display: 'flex',
            width: '65px',
            height: '25px',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
        },
        isPersonalDetailsEditable: false,
        isAddressDetailsEditable: false,
        personalDetailsWarning: { invalidPhone: false, invalidEmail: false },
        activeTeam: undefined,
        changeMobileNumberPopup: false,
        avatarUrlData: '',
        avatarUrlData1: '',
        countryLoader: true,
        stateLoader: true
    }

    getAllSubscriptions = () => {
        API.getAllSubscriptions().then((data) => {
            console.log(data);
            this.setState((state) => ({
                ...state,
                subscriptions: data.subscriptions
            }))
        }, (e) => { console.log(e); })
    }

    isNoAnyChangesMade = () => {
        return (
            this.state.user.address === this.props.user.address
            && this.state.user.first_name === this.props.user.first_name
            && this.state.user.last_name === this.props.user.last_name
            && this.state.user.state === this.props.user.state
            && this.state.user.country === this.props.user.country
            && this.state.user.phone === this.props.user.phone
            && this.state.avatarUrlData == this.state.avatarUrlData1
        )
    }

    submitEditUser = (e, mobileVerified, updateAvtar) => {
        if (e) e.preventDefault()

        const submitUser = (user) => {
            API.Useredit(user).then(() => {
                API.getUserType().then(([blah, type, user]) => {
                    if (!user.sub) {
                        user.sub = {
                            "plan": "aeromegh_plan_free"
                        }
                    }
                    this.setState((state) => ({
                        ...state,
                        user,
                        user: user,
                        type,
                    }), () => {
                        this.props.save()
                    })
                })
            })
        }

        if (updateAvtar) { submitUser(this.state.user) }
        else if (this.state.user.phone !== this.props.user.phone && !mobileVerified) this.setState({ changeMobileNumberPopup: true })
        else {
            this.setState((state) => ({ ...state, loading: true, }))
            let user = this.state.user
            user.country = user.country == '-' ? '-' : user.country
            user.state = user.state == '-' ? '-' : user.state
            user.phone = mobileVerified || this.state.user.phone
            submitUser(user)
        }
    }

    toggleSubCancelPopup = () => { document.getElementById("send-plan-popup-button").click() }

    getAeromeghPlans = () => {
        API.getAeromeghPlans().then(res => {
            if (res.plans.length > 0) {
                this.setState((state) => ({
                    ...state,
                    plans: res.plans
                }))
            } else {
                return 0
            }
        }).catch(e => { console.log(e) })
    }

    getSelectedActiveTeam = async () => {
        try {
            let selectedActiveTeam = await getActiveTeam();
            this.setState({ activeTeam: selectedActiveTeam })
        } catch (err) { console.log("error : ", err) }
    }

    getCountries = () => {
        API.getCountries().then((data) => {
            let country = this.state.user.country && this.state.user.country != '-' ? this.state.user.country : '-'
            this.setState((state) => ({
                ...state,
                countryLoader: false,
                countries: data.countries,
                user: {
                    ...state.user,
                    country: country,
                }
            }))
            if (country != '-') {
                country = this.state.countries?.find(data => data.country == country)
                console.log("selecte country : ", country);
                this.getStates(country?.iso_code)
            }
        }, (e) => {
            console.log(e);
        })
    }
    getStates = (iso_code) => {
        if (iso_code) {
            API.getStates(iso_code).then((data) => {
                let userstate = this.state.countryChange && data.states && data.states.length > 0 ? '-' : this.state.user.state
                if (!this.state.countryChange && this.state.user.state)
                    userstate = this.state.user.state
                this.setState((state) => ({
                    ...state,
                    stateLoader: false,
                    states: data.states,
                    user: {
                        ...state.user,
                        state: userstate,
                        countryChange: false
                    }
                }))
            }, (e) => {
                console.log(e);
            })
        }
    }

    componentDidMount() { API.getViewerMinutesStats().then(r => { this.setState({ viewMinutesStats: r }) }).catch(e => { alert(e) }) }

    canUserUpgrade = (sub) => { return this.state.subscriptionTitleMap[sub] == "AeroMegh Free" ? true : false }

    componentWillMount() {
        document.title = "My Profile - AeroMegh";
        if (this.props) {
            let { user, type } = this.props;
            if (!user.sub) {
                user.sub = {
                    "plan": "aeromegh_plan_free"
                }
            }
            if (user.avatar_url && user.avatar_url.length > 0) {
                this.setState((state) => ({
                    ...state,
                    avatarURL: `${user.avatar_url}?${user.sas_token}&t${new Date().getTime()}`
                }))

            } else {
                let avatarURL = `/images/${user.first_name[0].toLowerCase()}.PNG`
                this.setState((state) => ({
                    ...state,
                    avatarURL: avatarURL
                }))
            }
            this.setState((state) => ({
                ...state,
                user,
                type,
            }), async () => {

                this.getAeromeghPlans();
                this.getAllSubscriptions();
                this.getSelectedActiveTeam();
                this.getCountries();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                if (!user.sub) {
                    user.sub = {
                        "plan": "aeromegh_plan_free"
                    }
                }

                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), async () => {
                    this.getAeromeghPlans();
                    this.getAllSubscriptions();
                    this.getSelectedActiveTeam();
                    this.getCountries();
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    handleUserDetails = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        const check = (checkData) => {
            let expression = checkData == 'eml' ? /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/ : /^\d{10}$/;
            let len = checkData == 'eml' ? 30 : 10;

            if (value.length <= len) {
                this.setState({ user: { ...this.state.user, [name]: value || "" } })
                if (expression.test(value)) {
                    this.setState({
                        personalDetailsWarning: checkData == 'eml' ? { ...this.state.personalDetailsWarning, invalidEmail: false }
                            : { ...this.state.personalDetailsWarning, invalidPhone: false }
                    })
                }
                else this.setState({
                    personalDetailsWarning: checkData == 'eml' ? { ...this.state.personalDetailsWarning, invalidEmail: true }
                        : { ...this.state.personalDetailsWarning, invalidPhone: true }
                })
            }
        }

        if (name == 'phone') check()
        if (name == 'country') {
            this.setState({ user: { ...this.state.user, [name]: value || "" }, stateLoader: true }, () => {
                this.state.countries.forEach(country => {
                    if (country.country == value) this.getStates(country.iso_code)
                });
            })
        }
        else if (name == 'username') check('eml')
        else if (e.target.value.length <= 30) {
            this.setState({ user: { ...this.state.user, [name]: value || "" } })
            console.log("name : ", name, "\n value : ", value);
        }
    }

    getBase64 = file => {
        return new Promise(resolve => {
            let fileSize = file.size / 1000
            if (fileSize > (this.state.FILE_SIZE_LIMIT * 1000)) {
                this.setState((state) => ({
                    ...state,
                    selectedFile: file,
                    sizeCheckPopup: true
                }))
                console.log("Image upload failed !");
            }
            else {
                let baseURL = "";
                // Make new FileReader
                let reader = new FileReader();
                // Convert the file to base64 text
                reader.readAsDataURL(file);
                // on reader load somthing...
                reader.onload = () => {
                    // Make a fileInfo Object
                    baseURL = reader.result;
                    resolve(baseURL);
                };
                console.log("Upload Completed !");
            }
        });
    };

    checkPersonalDetails = () => {
        return (this.state.personalDetailsWarning.invalidPhone
            || this.state.user.first_name == ""
            || this.state.user.last_name == ""
        );
    }

    checkAddressDetails = () => {
        return (this.state.user.address == ""
            || this.state.user.country == ""
            || this.state.user.state == ""
        );
    }

    render() {
        let { user, type } = this.state
        let viewerMinutesUsagePercent = null
        if (this.state.viewMinutesStats) {
            if (this.state.viewMinutesStats.quota === 0) {
                viewerMinutesUsagePercent = 100
            } else {
                viewerMinutesUsagePercent = (this.state.viewMinutesStats.used / this.state.viewMinutesStats.quota) * 100
            }
        }
        return (
            this.props.user.username ? <div className="wrapper">
                <input id="avatarUpload" type="file"
                    style={{ display: 'none', width: '0px', height: '0px' }}
                    name="profile_photo" placeholder="Photo" onChange={async (e) => {
                        let val;
                        try { val = URL.createObjectURL(e.target.files[0]); } catch (e) { }
                        let avatar = await this.getBase64(e.target.files[0])
                        this.setState((state) => ({
                            ...state,
                            avatarURL: val,
                            avatarUrlData1: avatar,
                            user: {
                                ...state.user,
                                avatar: avatar
                            }
                        }), () => { this.submitEditUser(undefined, undefined, true) })
                    }} required="" capture />
                <div>
                    <Popup
                        trigger={<button id="send-plan-popup-button" style={{
                            display: "none",
                        }}></button>}
                        modal
                    >
                        <img
                            id="close-popup"
                            src={closeButton} style={{
                                width: "auto",
                                height: "26px",
                                position: "absolute",
                                top: "-13px",
                                right: "-13px",
                                cursor: "pointer"

                            }} onClick={() => {

                                document.getElementById("send-plan-popup-button").click();
                                this.setState((state) => ({
                                    ...state,
                                    createIcon: waiting,
                                    cancelSubsText: 'Cancelling Subscription Please Wait...',
                                }))
                            }}></img>
                        <div >
                            <div style={{ width: "100%", display: "flex" }}>
                                <img src={this.state.createIcon} style={{
                                    width: "auto",
                                    height: "64px",
                                    margin: "30px auto"
                                }} />
                            </div>

                            <>
                                <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{
                                    this.state.cancelSubsText
                                }</div>

                            </>

                        </div>
                    </Popup>
                    {this.state.changeMobileNumberPopup && <ChangeMobileNumber
                        phone={this.state.user.phone}
                        user={this.props.user}
                        sendOTPbyDefault
                        onClose={() => this.setState({ changeMobileNumberPopup: false })}
                        onMobileNumberChanged={(phone) => {
                            this.setState({
                                changeMobileNumberPopup: false
                            })
                            this.submitEditUser(false, phone)
                        }}
                    />}
                    <div className='setting-containt' >
                        <div style={{ display: 'flex', alignItems: "center", gap: "20px", marginBottom: "20px" }}>
                            <div>
                                <img src={this.state.avatarURL}
                                    style={{ borderRadius: "50%", height: '60px', width: '60px', cursor: "pointer" }}
                                    onClick={() => { document.getElementById('avatarUpload').click() }}
                                />
                            </div>
                            <div>
                                <div style={{ marginBottom: '-4px', fontWeight: "500", fontSize: '14px' }}>{this.props.user.first_name + " " + this.props.user.last_name}</div>
                                <div><span style={{
                                    color: '#1f384c80',
                                    fontSize: '11px'
                                }}>{this.state.activeTeam ? this.state.activeTeam.isOwner ? `${this.props.user.first_name + " " + this.props.user.last_name}` : this.state.activeTeam.teamName : '-'}</span></div>
                            </div>
                        </div>
                        <div style={{
                            border: '1px solid #2989cf2e',
                            borderRadius: '5px',
                            marginBottom: "20px",
                            padding: "0 20px"
                        }}>
                            <div style={{
                                display: "flex", justifyContent: "space-between",
                                padding: '15px 10px 10px 10px',
                            }}>
                                <div style={{ fontWeight: "500", fontSize: '14px' }}>Personal Details</div>
                                <div style={{ display: 'flex', gap: "20px", alignItems: 'center' }}>
                                    {this.state.isPersonalDetailsEditable ?
                                        <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', gap: "20px" }}>
                                            <div style={{ ...this.state.saveBtnStyle, border: '1px solid #2989cf', backgroundColor: 'white', color: '#2989cf' }}
                                                onClick={() => this.setState({
                                                    isPersonalDetailsEditable: false,
                                                    user: { ...this.props.user },
                                                    personalDetailsWarning: { invalidPhone: false, invalidEmail: false }
                                                })}
                                            >
                                                cancel
                                            </div>
                                            <div style={{ ...this.state.saveBtnStyle, opacity: this.checkPersonalDetails() ? "0.5" : "1", cursor: this.checkPersonalDetails() ? "not-allowed" : "pointer" }}
                                                onClick={() => {
                                                    if (!this.checkPersonalDetails()) {
                                                        this.setState({
                                                            isPersonalDetailsEditable: false
                                                        }, () => {
                                                            this.submitEditUser()
                                                        })
                                                    }
                                                }} >
                                                save
                                            </div>
                                        </div> : <></>}
                                    {!this.state.isPersonalDetailsEditable &&
                                        <img src={editActive} style={{ height: "30px", opacity: this.state.isAddressDetailsEditable ? '0.5' : '', cursor: this.state.isAddressDetailsEditable ? 'not-allowed' : 'pointer' }}
                                            onClick={() => {
                                                if (!this.state.isAddressDetailsEditable) {
                                                    this.setState({
                                                        isPersonalDetailsEditable: !this.state.isPersonalDetailsEditable
                                                    })
                                                }
                                            }}
                                        />}
                                </div>
                            </div>
                            <div style={{ width: "100%", padding: '0 10px 10px 10px', }}>
                                <div style={{ display: "flex", marginBottom: "10px" }}>
                                    <div style={{ width: "50%" }}>
                                        <div style={{ color: '#1f384c80', fontSize: '13px' }}>First name</div>
                                        {!this.state.isPersonalDetailsEditable ?
                                            <div><span style={{ fontSize: '13px' }}>{user.first_name || '-'}</span></div>
                                            : <div><input type='text'
                                                required
                                                autoFocus
                                                value={user?.first_name || ""}
                                                name='first_name'
                                                placeholder='First name'
                                                className='settings-input'
                                                onChange={(e) => {
                                                    let check = /^[A-Za-z]*$/;
                                                    if (check.test(e.target.value)) this.handleUserDetails(e)
                                                }}
                                            /></div>
                                        }
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <div style={{ color: '#1f384c80', fontSize: '13px' }}>Last name</div>
                                        {!this.state.isPersonalDetailsEditable ?
                                            <div><span style={{ fontSize: '13px' }}>{user.last_name || '-'}</span></div>
                                            : <div><input type='text'
                                                required
                                                value={user?.last_name || ""}
                                                name='last_name'
                                                placeholder='Last name'
                                                className='settings-input'
                                                onChange={(e) => {
                                                    let check = /^[A-Za-z]*$/;
                                                    if (check.test(e.target.value)) this.handleUserDetails(e)
                                                }}
                                            /></div>}
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginBottom: "10px" }}>
                                    <div style={{ width: "50%" }}>
                                        <div style={{ color: '#1f384c80', fontSize: '13px' }}>Phone Number</div>
                                        {!this.state.isPersonalDetailsEditable ?
                                            <div><span style={{ fontSize: '13px' }}>{user.phone || '-'}</span></div>
                                            : <div><input type='text'
                                                required
                                                value={user?.phone || ""}
                                                name='phone'
                                                placeholder='Phone'
                                                className='settings-input'

                                                onChange={(e) => {
                                                    let check = /^[0-9]*$/;
                                                    if (check.test(e.target.value) && e.target.value.length <= 10) this.handleUserDetails(e)
                                                }}
                                            /></div>}
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <div style={{ color: '#1f384c80', fontSize: '13px' }}>Email</div>
                                        <div><span style={{ fontSize: '13px', marginRight: '10px' }}>{user.username || '-'}</span>
                                            {<img src={verifiedIcon} alt='verified icon' style={{ height: '13px' }} />}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", }}>
                                    {this.state.personalDetailsWarning.invalidEmail || this.state.personalDetailsWarning.invalidPhone ? <div style={{ width: "50%" }}>
                                        <div style={{ color: 'red', fontSize: '10px' }}>
                                            {this.state.personalDetailsWarning.invalidEmail && this.state.personalDetailsWarning.invalidPhone
                                                ? 'Please ensure that the email address and phone number you provided.'
                                                : this.state.personalDetailsWarning.invalidEmail
                                                    ? 'please memtion valid email address.'
                                                    : this.state.personalDetailsWarning.invalidPhone
                                                        ? 'Invalid phone number.' : ''
                                            }
                                        </div>
                                    </div> : <></>}
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <div style={{
                            border: '1px solid #2989cf2e',
                            borderRadius: '5px',
                            padding: "0 20px"
                        }}>
                            <div style={{
                                display: "flex", justifyContent: "space-between",
                                padding: '15px 10px 10px 10px',
                            }}>
                                <div style={{ fontWeight: "500", fontSize: '14px' }}>Address</div>
                                <div style={{ display: 'flex', gap: "20px", alignItems: 'center' }}>
                                    {this.state.isAddressDetailsEditable ?
                                        <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', gap: "20px" }}>
                                            <div style={{ ...this.state.saveBtnStyle, border: '1px solid #2989cf', backgroundColor: 'white', color: '#2989cf' }}
                                                onClick={() => this.setState({ isAddressDetailsEditable: false })}
                                            >
                                                cancel
                                            </div>
                                            <div style={{ ...this.state.saveBtnStyle, opacity: !this.checkAddressDetails() ? "1" : "0.5", cursor: !this.checkAddressDetails() ? "pointer" : "not-allowed" }}
                                                onClick={() => {
                                                    if (!this.checkAddressDetails()) this.setState({ isAddressDetailsEditable: false }, () => { this.submitEditUser() })
                                                }}
                                            >
                                                save
                                            </div>
                                        </div> : <></>}
                                    {!this.state.isAddressDetailsEditable && <img src={editActive} style={{ height: "30px", opacity: this.state.isPersonalDetailsEditable ? '0.5' : '', cursor: this.state.isPersonalDetailsEditable ? 'not-allowed' : 'pointer', }}
                                        onClick={() => {
                                            if (!this.state.isPersonalDetailsEditable) {
                                                this.setState({ isAddressDetailsEditable: !this.state.isAddressDetailsEditable })
                                            }
                                        }}
                                    />}
                                </div>
                            </div>
                            <div style={{ width: "100%", padding: '0 10px 10px 10px', }}>
                                <div style={{ display: "flex", marginBottom: "10px" }}>
                                    <div style={{ width: "50%" }}>
                                        <div style={{ color: '#1f384c80', fontSize: 'small' }}>Country</div>
                                        {!this.state.isAddressDetailsEditable ?
                                            <div><span style={{ fontSize: 'small' }}>{user.country || '-'}</span></div>
                                            : <div>
                                                <select name="country" style={{ width: "25vw" }} className="add-user-form-text"   value={this.state.user.country} required
                                                    onChange={(e) => { this.handleUserDetails(e) }}
                                                >
                                                    <option value={'-'} disabled>
                                                        {this.state.countryLoader == true ?
                                                            `Loading Countries` : `Select Country`}
                                                    </option>
                                                    {this.state.countries?.length > 0 ?
                                                        this.state.countries.map((country) => {
                                                            return <option key={country.iso_code} value={country.country}>
                                                                {country.country}
                                                            </option>
                                                        }) :
                                                        <option>no countries found</option>
                                                    }
                                                </select>
                                            </div>
                                        }
                                    </div>
                                    <div style={{ width: "50%" }}>
                                        <div style={{ color: '#1f384c80', fontSize: 'small' }}>State</div>
                                        {!this.state.isAddressDetailsEditable ?
                                            <div><span style={{ fontSize: 'small' }}>{user.state || '-'}</span></div>
                                            : <div>
                                                {!this.state.stateLoader ? <select name="state" style={{ width: "25vw", opacity: this.state.stateLoader ? "0.5" : "1" }} className="add-user-form-text" value={this.state.user.state} required onChange={(e) => { this.handleUserDetails(e) }}>
                                                    <option value={'-'} disabled> {`Select State`}  </option>
                                                    {this.state.states?.map((state, key) => {
                                                        return <option value={state.name}>{state.name}</option>
                                                    })}
                                                </select> : <div className="add-user-form-text" style={{ opacity: "0.5", display: "flex", justifyContent: "left", alignItems: "center", width: "25vw" }}>Loading...</div>}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div style={{ display: "flex", marginBottom: "10px" }}>
                                    <div style={{ width: "50%" }}>
                                        <div style={{ color: '#1f384c80', fontSize: 'small' }}>Address</div>
                                        {!this.state.isAddressDetailsEditable ?
                                            <div><span style={{ fontSize: 'small' }}>{user.address || '-'}</span></div>
                                            : <div><input type='text'
                                                required
                                                value={user?.address || ""}
                                                name='address'
                                                placeholder='address'
                                                className='settings-input'
                                                onChange={(e) => this.handleUserDetails(e)}
                                            /></div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div > : <div />
        );
    }
}

export default MyProfile;
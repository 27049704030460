import React, { useEffect, useState } from 'react'
import { getCropsAndAgrochemicals, getUserType } from './api.js';
import { Popup } from './ReusableComponents/reusableFunctions.js';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
import TableView from './ReusableComponents/TableView/TableView.js';

const CropAndAgrochemicals = (props) => {
    const [user, setUser] = useState({});
    const [totalCount, setTotalCount] = useState(0)
    const [tableLoading, setTableLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const [cropsAndAgrochemicals, setCropsAndAgrochemicals] = useState([])

    const [tableState, setTableState] = useState({
        pageNumber: 1,
        pageSize: 15,
    })

    const getCropsAndAgrochemicalsData = async () => {
        try {
            setTableLoading(true)
            let cropsAndAgrochemicals = await getCropsAndAgrochemicals(undefined, tableState)
            console.log("cropsAndAgrochemicals : ", cropsAndAgrochemicals);
            setCropsAndAgrochemicals(cropsAndAgrochemicals.crops)
            setTotalCount(cropsAndAgrochemicals.totalCount)
            setLoading(false)
            setTableLoading(false)
        } catch (err) {
            setLoading(false)
            setTableLoading(false)
            console.log("error:", err);
        }
    }

    useEffect(() => {
        getCropsAndAgrochemicalsData()
    }, [tableState])

    const getUser = async () => {
        setLoading(true)

        const [blah, type, user] = await getUserType();
        setUser(user);
        getCropsAndAgrochemicalsData()
    }

    useEffect(() => {
        document.title = "All Drones Location - Admin";
        try {
            getUser()
        } catch (e) {
            console.log(e);
            Popup.alert("ERROR", e.message)
        }
    }, []);

    return <div className="wrapper">
        <div className="right-content-new" style={{ zIndex: 0 }}>
            <div className="top-bar-new" style={{ display: "flex" }}><div className="top-bar-text-new">Crops & Agrochemicals</div></div>
            {loading ? <LoaderComponent />
                : <div className='main-content-new'>
                    <div style={{ height: 'calc(100% - 100px)' }}>
                        <TableView
                            // showSrNo
                            headingLeft={"Crops & Agrochemicals"}
                            isLoading={tableLoading}
                            onStateChange={(state) => {
                                setTableState(state)
                            }}
                            onClickRow={flight => { }}
                            onClickLink={(flight) => { }}
                            data={cropsAndAgrochemicals.length != 0 ? cropsAndAgrochemicals : []}
                            columns={cropsAndAgrochemicals.length != 0 ? [
                                { Header: "Drone Id", accessor: "droneid", width: '20%' },
                                { Header: "Acre", accessor: "total_spray_area", width: '15%' },
                                { Header: "Crop", accessor: "crop_type", width: '20%' },
                                { Header: "Agrochemical", accessor: "pesticide", width: '15%' },
                                { Header: "Litre", accessor: "total_spray_volume", width: '30%' },
                            ] : []}
                            count={totalCount || 0}
                        />
                    </div>
                </div>
            }
        </div>
    </div>
};
export default CropAndAgrochemicals
import Services from "../src/sidebaricon/Services.svg"
import ServicesInactive from "../src/sidebaricon/Services-Inactive.svg"
import AeroGCS from "../src/sidebaricon/AeroGCS.svg"
import AeroGCSInactive from "../src/sidebaricon/AeroGCS-Inactive.svg"
import Drones from "../src/sidebaricon/Drone.png"
import DronesInactive from "../src/sidebaricon/Drone-Inactive.png"
import Teams from "../src/sidebaricon/Teams.svg"
import TeamsInactive from "../src/sidebaricon/Teams-Inactive.png"
import ControlCenter from "../src/sidebaricon/ControlCenter.svg"
import ControlCenterInactive from "../src/sidebaricon/ControlCenter-Inactive.svg"
import Projects from "../src/sidebaricon/Projects.svg"
import ProjectsInactive from "../src/sidebaricon/Projects-Inactive.png"
import Project from "../src/sidebaricon/ProjectNew.png"
import ProjectInactive from "../src/sidebaricon/ProjectNew-Inactive.png"
import Plans from "../src/sidebaricon/Plans.png"
import PlansInactive from "../src/sidebaricon/Plans-Inactive.png"
import Imagesets from "../src/sidebaricon/ImageSets.png"
import ImagesetsInactive from '../src/sidebaricon/ImageSets-Inactive.png';
import Videos from "../src/sidebaricon/Video.png"
import VideosInactive from "../src/sidebaricon/Video-Inactive.png"
import FlightLogs from "../src/sidebaricon/FlightLogs.png"
import FlightLogsInactive from "../src/sidebaricon/FlightLogs-Inactive.png"
import AeroStream from "../src/sidebaricon/AeroStreams.png"
import AeroStreamInactive from "../src/sidebaricon/AeroStreams-Inactive.png"
import Settings from './sidebaricon/SettingsActive.png';
import SettingsInactive from './sidebaricon/SettingsInactive.png';
import UsersActive from './sidebaricon/usersActive.svg';
import UsersInactive from './sidebaricon/users1.svg'
import UsersStats from './sidebaricon/UsersStats.svg'
import UsersStatsInactive from './sidebaricon/UsersStatsInactive.svg'
import systemIcon from './sidebaricon/system.svg'
import systemIconInative from './sidebaricon/systemInactive.svg'
import barChartIcon from './sidebaricon/barChart.svg'
import barChartIconInactive from './sidebaricon/barChartInactive.svg'
import weeklyReportIcon from './sidebaricon/weekly-report-icon.svg'
import weeklyReportIconInactive from './sidebaricon/weekly-report-icon-inactive.svg'
import softwareVersion from './sidebaricon/software_version.svg'
import softwareVersionInactive from './sidebaricon/software_version_inactive.svg'
import dashboardActive from './icons/layout.png'
import dashboardInactive from './icons/layoutInactive.png'
import emailLogs from './icons/emailAdmin.png'
import emailLogsInactive from './icons/emailAdminInactive.png'

const defaultAdminToolSideBar = {
    tools: [
        {
            icon: {
                active: UsersStats,
                inActive: UsersStatsInactive
            },
            name: "Statistics",
            path: "/admin/users_stats",
        },
        {
            icon: {
                active: dashboardActive,
                inActive: dashboardInactive
            },
            name: "Dashboard",
            path: "/admin",
            selected: true,
        },
        {
            name: 'Control Center',
            path: "/admin/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            icon: {
                active: UsersActive,
                inActive: UsersInactive
            },
            name: "Users",
            path: "/admin/users",
        },
        {
            icon: {
                active: emailLogs,
                inActive: emailLogsInactive
            },
            name: "Email Logs",
            path: "/admin/email_logs",
        },
        // {
        //     icon: {
        //         active: AeroGCS,
        //         inActive: AeroGCSInactive
        //     },
        //     name: "Installations",
        //     path: "/admin/gcs-installation",
        // },
        {
            icon: {
                active: systemIcon,
                inActive: systemIconInative
            },
            name: "Machines",
            path: "/admin/machines",
        },
        {
            icon: {
                active: barChartIcon,
                inActive: barChartIconInactive
            },
            name: "Stats",
            path: "/admin/system_monitoring",
        },
        {
            icon: {
                active: softwareVersion,
                inActive: softwareVersionInactive
            },
            name: "Software Versions",
            path: "/admin/software_versions",
        },
    ]
}

const defaultAeroGCSSideBar = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
            selected: true,
        },
        {
            name: 'Drones',
            path: "/drone_management/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Projects',
            path: "/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Weekly Report',
            path: "/weeklyReport",
            icon: {
                active: weeklyReportIcon,
                inActive: weeklyReportIconInactive
            },
            className: 'profileSettingIcon'
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: '/',
    aeroGCSHelp: true
}

const defaultDronesSideBar = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
            selected: true,
        },
        {
            name: 'Drones',
            path: "/drone_management/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Projects',
            path: "/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },],
    aeroGCSHelp: true,
    selectedPath: '/drone_management/drones'
}

const aeroGCSPlans = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
            selected: true,
        },
        {
            name: 'Drones',
            path: "/drone_management/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Projects',
            path: "/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Imagesets',
            path: "/remote_mission/image_collection",
            icon: {
                active: Imagesets,
                inActive: ImagesetsInactive
            },
            className: "sidebaricon-plan"
        },
        {
            name: 'Videos',
            path: "/remote_mission/my_videos",
            icon: {
                active: Videos,
                inActive: VideosInactive
            },
            className: "sidebaricon-plan"
        },
        {
            name: 'Flight logs',
            path: "/remote_mission/flightLogs",
            icon: {
                active: FlightLogs,
                inActive: FlightLogsInactive
            },
            className: "sidebaricon-flightlogs"
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: '/projects'
}

const defaultProjectSideBar = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
            selected: true,
        },
        {
            name: 'Drones',
            path: "/drone_management/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Projects',
            path: "/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    aeroGCSHelp: true,
    selectedPath: '/projects'
}

const defaultRequestADroneSideBar = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
            selected: true,
        },

        {
            name: 'Drones',
            path: "/drone_management/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Projects',
            path: "/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Aerostream',
            path: "/request_a_drone/all_videos",
            icon: {
                active: AeroStream,
                inActive: AeroStreamInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: '/request_a_drone/all_videos',
    aeroGCSHelp: true
}

const defaultFlightPlan = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
            selected: true,
        },
        {
            name: 'Drones',
            path: "/drone_management/drones",
            icon: {
                active: Drones,
                inActive: DronesInactive
            },
        },
        {
            name: 'Control Center',
            path: "/drone_control_center/all_drones",
            icon: {
                active: ControlCenter,
                inActive: ControlCenterInactive
            },
        },
        {
            name: 'Projects',
            path: "/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Plan',
            path: "/remote_mission/assignDroneToPlan",
            icon: {
                active: Plans,
                inActive: PlansInactive
            },
            className: "sidebaricon-plan"
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: "/remote_mission/assignDroneToPlan",
    aeroGCSHelp: true
}

const defaultSurveyFlightPlan = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
            selected: true,
        },
        {
            name: 'Projects',
            path: "/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: "/projects",
    aeroGCSHelp: true
}

const defaultSprayingFlightPlan = {
    tools: [
        {
            name: 'AeroGCS',
            path: '/',
            icon: {
                active: AeroGCS,
                inActive: AeroGCSInactive
            },
            selected: true,
        },
        {
            name: 'Projects',
            path: "/projects",
            icon: {
                active: Projects,
                inActive: ProjectsInactive
            },
        },
        {
            name: 'Plan',
            path: "/remote_mission/assignDroneToPlan",
            icon: {
                active: Plans,
                inActive: PlansInactive
            },
            className: "sidebaricon-plan"
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: "/remote_mission/assignDroneToPlan",
    aeroGCSHelp: true
}

const defaultAnalyticToolSideBar = {
    tools: [
        {
            name: 'User Analytics',
            path: "/analytictool/user_statistics",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: '/analytictool/user_statistics',
    help: true
}

const defaultServicesSideBar = {
    tools: [
        {
            name: 'Services',
            path: "/",
            icon: {
                active: Services,
                inActive: ServicesInactive
            },
        },
        {
            name: 'Teams',
            path: "/teams",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Planner',
            path: "/planner",
            icon: {
                active: Teams,
                inActive: TeamsInactive
            },
        },
        {
            name: 'Settings',
            path: "/profile-settings",
            icon: {
                active: Settings,
                inActive: SettingsInactive
            },
            className: 'profileSettingIcon'
        },
    ],
    selectedPath: '/',
    help: true
}

export const sideBarPaths = {
    "/": defaultAeroGCSSideBar,
    "/invoice": { ...defaultAeroGCSSideBar, selectedPath: '/profile-settings' },
    "/remote_mission/flights_completed_hours": defaultAeroGCSSideBar,
    "/remote_mission/flight_logs_by_droneId": defaultAeroGCSSideBar,
    "/remote_mission/flights_statistics": defaultAeroGCSSideBar,
    "/remote_mission/flight_details": defaultAeroGCSSideBar,
    '/remote_mission/flight_status_logs': defaultAeroGCSSideBar,
    '/remote_mission/detailedlog': defaultAeroGCSSideBar,
    '/remote_mission/all_flights_logs': defaultAeroGCSSideBar,
    '/allFlightPlans': defaultAeroGCSSideBar,
    '/remote_mission/assignDroneToPlan': aeroGCSPlans,
    '/remote_mission/image_collection': { ...aeroGCSPlans, selectedPath: '/remote_mission/image_collection' },
    '/remote_mission/my_videos': { ...aeroGCSPlans, selectedPath: '/remote_mission/my_videos' },
    '/drone_management/drones': defaultDronesSideBar,
    '/drone_management/drone_details': defaultDronesSideBar,
    '/drone_management/drone/errorLogs': defaultDronesSideBar,
    '/projects': { ...defaultDronesSideBar, selectedPath: '/projects' },
    '/plans': defaultProjectSideBar,
    '/remote_mission/flightLogs': ({ ...aeroGCSPlans, selectedPath: '/remote_mission/flightLogs' }),
    '/remote_mission/selectLocation': aeroGCSPlans,
    '/aerogcsenterprise/flights': defaultAeroGCSSideBar,
    '/user_flight_locations': defaultDronesSideBar,
    '/teams/givenAccess': { ...defaultAeroGCSSideBar, selectedPath: '/teams' },
    '/aerogcsenterprise/flights/pilots': defaultAeroGCSSideBar,
    '/aerogcsenterprise/flights/acres': defaultAeroGCSSideBar,
    '/request_a_drone/all_videos': defaultRequestADroneSideBar,
    '/remote_mission/flightPlan': defaultFlightPlan,
    "/remote_mission/surveyFlightPlan": defaultSurveyFlightPlan,
    '/remote_mission/sprayingFlightPlot': defaultSprayingFlightPlan,
    '/remote_mission/sprayingFlightPlan': defaultSprayingFlightPlan,
    '/teams': { ...defaultAeroGCSSideBar, selectedPath: '/teams' },
    '/profile-settings': { ...defaultAeroGCSSideBar, selectedPath: '/profile-settings' },
    '/aeromegh_plans': { ...defaultAeroGCSSideBar, selectedPath: '/profile-settings' },
    '/usage_statistics': { ...defaultAeroGCSSideBar, selectedPath: '/profile-settings' },
    '/analytictool/user_statistics': defaultAnalyticToolSideBar,
    '/analytictool/userdetails': defaultAnalyticToolSideBar,
    '/analytictool/user_tracking_logs': defaultAnalyticToolSideBar,
    '/analytictool/user_screen_logs': defaultAnalyticToolSideBar,
    '/analytictool/customStatistics': defaultAnalyticToolSideBar,
    '/weeklyReport': { ...defaultAeroGCSSideBar, selectedPath: '/weeklyReport' },
    '/admin': { ...defaultAdminToolSideBar, selectedPath: '/admin' },
    '/admin/cropsAndAgrochemicals': { ...defaultAdminToolSideBar, selectedPath: '/admin' },
    '/admin/users': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/email_logs': { ...defaultAdminToolSideBar, selectedPath: '/admin/email_logs' },
    '/admin/gcs-installation': { ...defaultAdminToolSideBar, selectedPath: '/admin/gcs-installation' },
    '/admin/machines': { ...defaultAdminToolSideBar, selectedPath: '/admin/machines' },
    '/admin/allsubscription': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/new_subscription': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/discount_coupons': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/subscription_items': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/userdetails': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/user_tracking_logs': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/user_screen_logs': { ...defaultAdminToolSideBar, selectedPath: '/admin/user_screen_logs' },
    '/admin/edituser': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/assign_services': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/user_manage_plan': { ...defaultAdminToolSideBar, selectedPath: '/admin/users' },
    '/admin/users_stats': { ...defaultAdminToolSideBar, selectedPath: '/admin/users_stats' },
    '/admin/system_monitoring': { ...defaultAdminToolSideBar, selectedPath: '/admin/system_monitoring' },
    '/admin/software_versions': { ...defaultAdminToolSideBar, selectedPath: '/admin/software_versions' },
    '/admin/all_drones_locations': { ...defaultAdminToolSideBar, selectedPath: '/admin' },
    '/admin/drone_flight_locations': { ...defaultAdminToolSideBar, selectedPath: '/admin' },
}

import React from "react";
import Searchimg from "../../icons/SearchIcon.svg";
import "./SearchHeader.css";
const Search = ({
    bgcolor,
    placeholder,
    globalState,
    handleStateChange,
    isDisabled,
    style
}) => {
    const handleChange = (e) => {
        handleStateChange("searchString", e.target.value);
    };

    return (
        <div className="Search_wrapper" style={style}>
            <input type="text" value={globalState?.searchString || ""} onChange={(e) => { if (!isDisabled) handleChange(e) }} placeholder={placeholder}
                style={{ backgroundColor: bgcolor ? bgcolor : "#F4F4F4", cursor: isDisabled ? "not-allowed" : "", opacity: isDisabled ? "0.5" : "1" }} />
            <div className="icon">
                <img src={Searchimg} style={{ height: "18px" }} />
            </div>
        </div>
    )
}


export default Search;
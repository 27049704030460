import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import Loader from './icons/loader.svg';
import Search from "./Icon/Search.png";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CopyIcon from './icons/copy.png'
import Edit from './icons/pen.png'
import CopyActiveIcon from './icons/tick.png'
import fail from './assets/error.png';
import { Link, StaticRouter, Redirect } from 'react-router-dom';
import moment from 'moment';
import { Switch, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import btnAdd from './icons/btnAdd.svg';
import { AnimatedModal } from './ReusableComponents/AnimatedModal.js'
import closeButton from './close-button.png';
import dateFormat from 'dateformat';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';

export default class TaskAndVmList extends Component {

    state = {
        user: {},
        type: "",
        userdisplay: [],
        tasks: [],
        currentpage: 1,
        userperpage: 10,
        counts: 0,
        key: " ",
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        today: new Date(),
        createIcon: "",
        popupMessage: "",
        vms: [],
        copied: [],
        addVMPopup: false,
        vm_name: null,
        ip_address: null,
        status: false,
        az_subscription_id: null,
        resource_group: null,
        available: false,
        tasktype: 1,
        showAddVMPopup: false,
        statusFilter: "all",
        editTaskPopup: false,
        taskStatus: "",
        task_name: "",
        task_id: "",
        task_vm: "",
        task_type: 1,
        cancel: false,
        fromDate: '-30610245208',
        toDate: Date.now(),
        taskData: {
            "date_created": null,
            "images": 0,
            "classes": null,
            "task_name": "",
            "status": "",
            "assigned_vm": null,
            "video_task": false,
            "video_id": null,
            "fps": 0,
            "tasktype": 3,
            "first_name": "",
            "last_name": "",
            "username": "",
            "project_name": "",
            "plan_name": "",
            "collection_name": "",
            "file_name": ""
        },
        createdBy: "",

        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        task_parameters: "",
        accuracy: "",
        updateVMpopup: false,
        vmDetailsPopup: false,
        singleVmData: "",
        updateFlag: false,
        cancelFlag: false,
        vmid: ""
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    openAddVMPopup = () => {
        this.setState(state => ({
            ...state,
            vm_name: null,
            ip_address: null,
            status: false,
            available: false,
            tasktype: 1,
            resource_group: null,
            az_subscription_id: null,
            addVMPopup: true
        }))
    }
    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }
   

    handleSwitchChange = (event) => {
        let t = event.target;
        this.setState((state) => ({
            ...state,
            [t.name]: t.checked,
        }));
    };

    changeVMStatus = (id) => {
        API.changeVMStatus(id).then(result => {
            this.getAllVMS()
        }).catch(error => {
            console.log(error)
        })
    }
 
    componentWillMount() {
        document.title = "Task List - Admin";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), async () => {
                await this.getAllVMS();
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), async () => {
                    await this.getAllVMS();
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    setVmValues = (vm) => {
        this.setState((state) => ({
            ...state,
            vmid: vm.id,
            vm_name: vm.az_vm_name,
            ip_address: vm.ip_address,
            status: vm.status === 'on' ? true : false,
            available: vm.available,
            tasktype: vm.tasktype,
            resource_group: vm.az_resource_group,
            az_subscription_id: vm.az_subscription_id,
            updateFlag: true,
            addVMPopup: true,
        }))
    }



    addNewVM = (event) => {
        event.preventDefault(); // to prevent default behaviour of form component
        if (!this.state.cancelFlag) {
            let { vm_name, ip_address, resource_group, az_subscription_id, status, available, tasktype } = this.state
            if (!vm_name || !ip_address || !resource_group || !az_subscription_id) {
                this.setState((state) => ({
                    ...state,
                    addVMPopup: false,
                }))
            }
            else {
                this.setState((state) => ({
                    ...state,
                    addVMPopup: false,
                    itemName: "VM",
                    processPopupIcon: "WAIT",
                    processAction: "ADD",
                    processMessage: null,
                    showProcessPopup: true,
                }))

                let newVM = {
                    "vm_name": vm_name,
                    "ip_address": ip_address,
                    "status": status ? "on" : "off",
                    "az_subscription_id": az_subscription_id,
                    "resource_group": resource_group,
                    "tasktype": tasktype,
                    "available": available
                }
                API.addNewVM(newVM).then((result) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "VM",
                        processPopupIcon: "COMPLETE",
                        processAction: "ADD",
                        processMessage: null,
                        showProcessPopup: true,
                    }), () => this.getAllVMS())
                }).catch((error) => {
                    this.setState((state) => ({
                        ...state,
                        itemName: "VM",
                        processPopupIcon: "ERROR",
                        processMessage: error.message,
                        showProcessPopup: true,
                    }))
                })
            }
        }

    }

    updateVM = (event) => {
        event.preventDefault(); // to prevent default behaviour of form component
        let { vm_name, ip_address, resource_group, az_subscription_id, status, available, tasktype } = this.state

        this.setState((state) => ({
            ...state,
            addVMPopup: false,
            itemName: "VM",
            processPopupIcon: "WAIT",
            processAction: "UPDATE",
            processMessage: null,
            showProcessPopup: true,
        }))

        let updateVM = {
            "az_vm_name": vm_name,
            "ip_address": ip_address,
            "status": status ? "on" : "off",
            "az_sub_id": az_subscription_id,
            "az_res_group": resource_group,
            "tasktype": tasktype,
            "available": available
        }
        API.updateVM(updateVM, this.state.vmid).then((result) => {
            this.setState((state) => ({
                ...state,
                itemName: "VM",
                processPopupIcon: "COMPLETE",
                processAction: "UPDATE",
                processMessage: null,
                showProcessPopup: true,
                updateFlag: false,
            }), () => this.getAllVMS())
        }).catch((error) => {
            this.setState((state) => ({
                ...state,
                itemName: "VM",
                processPopupIcon: "ERROR",
                processMessage: error.message,
                showProcessPopup: true,
                updateFlag: false,
            }))
        })
    }

  

    copyById = (textToCopy) => {
        navigator.clipboard.writeText(textToCopy)
    }
    // getAll VM function starts here---------- 
    getAllVMS = async () => {
        this.setState(state => ({
            ...state,
            machineCounts: 0,
        }))
        await API.getAllVms().then(async (data) => {
            this.setState((state) => ({
                ...state,
                vms: data.vm_data,
                loading: false,
                machineCounts: data.vm_data.length
            }))
        }, (e) => {
            console.log(e);
        })
    }

    render() {
        const { user, type, currentpage, userperpage, counts, vm_name, fromDate, toDate, status, ip_address, resource_group, az_subscription_id, available, tasktype } = this.state;

        return (
            this.state.user && this.state.user.first_name ? <div className="wrapper">

                {/* ------------------ Task Details Popup--------------------- */}
                <AnimatedModal
                    isOpen={this.state.taskDetailsPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            taskDetailsPopup: false,
                        }))
                    }}
                    height="540px"
                    width="530px"
                >
                    <div style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '22px', fontWeight: '500' }}>Task Details</div>
                    <img
                        src={closeButton} style={{ width: "22px", height: "22px", position: "absolute", top: "15px", right: "20px", cursor: "pointer" }} onClick={() => {
                            this.setState((state) => ({
                                ...state,
                                taskDetailsPopup: false
                            }))
                        }}></img>
                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                    <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column", width: "95%", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                            <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                <div className='objectKey' >Task Name</div>
                                <div className='objectValue'>{this.state.taskData.task_name.length > 30 ?
                                    this.state.taskData.task_name.substring(0, 30) + "..." : this.state.taskData.task_name}</div>
                            </div>
                            <div style={{ height: "60px", margin: "0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                            <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Status</div>
                                <div className='objectValue'>{this.state.taskData.status}</div>
                            </div>
                        </div>
                        <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                            <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                <div className='objectKey' >Plan</div>
                                <div className='objectValue'>{this.state.taskData.plan_name.length > 25 ?
                                    this.state.taskData.plan_name.substring(0, 30) + "..." : this.state.taskData.plan_name}</div>
                            </div>
                            <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                            <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Project</div>
                                <div className='objectValue'>{this.state.taskData.project_name.length > 20 ?
                                    this.state.taskData.project_name.substring(0, 20) + "..." : this.state.taskData.project_name}</div>
                            </div>
                        </div>
                        <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                            <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Task type</div>
                                <div className='objectValue'>{this.state.taskData.tasktype == 1 ? "Training" :
                                    this.state.taskData.tasktype == 2 ? "Detection" :
                                        this.state.taskData.tasktype == 3 ? "Orthomosaic" : "Annotation Report"}</div>
                            </div>
                            <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                            <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Assigned VM</div>
                                <div className='objectValue'>{this.state.task_vm ? this.state.task_vm : "-"}</div>
                            </div>
                        </div>
                        {this.state.taskData.video_task ? (
                            <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>Video name</div>
                                    <div className='objectValue'>{this.state.taskData.file_name.length > 25 ?
                                        this.state.taskData.file_name.substring(0, 25) + "..." : this.state.taskData.file_name}</div>
                                </div>
                                <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>Frames Per Second</div>
                                    <div className='objectValue'>{this.state.taskData.fps}</div>
                                </div>
                            </div>) : (
                            <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>Imageset</div>
                                    <div className='objectValue'>{this.state.taskData.collection_name.length > 30 ?
                                        this.state.taskData.collection_name.substring(0, 30) + "..." : this.state.taskData.collection_name}</div>
                                </div>
                                <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                    <div className='objectKey'>No. of Images</div>
                                    <div className='objectValue'>{this.state.taskData.images}</div>
                                </div>
                            </div>
                        )}
                        <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                            <div style={{ margin: '10px 0px', width: "50%", display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Created By</div>
                                <div className='objectValue'>{this.state.createdBy.length > 25 ?
                                    this.state.createdBy.substring(0, 25) + "..." : this.state.createdBy}</div>
                            </div>
                            <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                            <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                <div className='objectKey'>Created Date</div>
                                <div className='objectValue'>{dateFormat(this.state.taskData.date_created, "dd mmm yyyy")}</div>
                            </div>
                        </div>

                    </div>
                </AnimatedModal>
                {/* ------------------------------------------------ */}

                <AnimatedModal
                    isOpen={this.state.editTaskPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            editTaskPopup: false,
                        }))
                    }}
                    height="320px"
                    width="400px"
                >
                    <div style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '22px', fontWeight: '500' }}>Edit task status</div>
                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                    <form onSubmit={this.updateStatus} >
                        <div style={{ margin: '10px 50px' }}>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "1%" }}>Task Name</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    disabled
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="vm_name"
                                    value={this.state.task_name}
                                />
                            </div>

                            <div style={{ display: "flex", marginTop: "4%", justifyContent: "space-between" }}>
                                <div>
                                    <div className='sub-add-user-form-label' style={{ display: "flex" }}>Task Status</div>
                                    <select
                                        className="sub-add-user-form-text"
                                        style={{ width: "298px", borderRadius: "5px" }}
                                        name='taskStatus'
                                        value={this.state.taskStatus}
                                        onChange={this.onChangeListener}
                                    >
                                        {/* <option value={"Created"}>Created</option> */}
                                        <option value={"Pending"}>Pending</option>
                                        {/*<option value={"In Progress"}>In Progress</option>
                                             <option value={"Completed"}>Completed</option> */}
                                        <option value={"Failed"}>Failed</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        editTaskPopup: false,
                                        cancel: true
                                    }));
                                }}
                            >
                                Cancel
                            </button>
                            {this.state.taskStatus ?
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    type='submit'
                                >
                                    Save
                                </button>
                                : <button disabled style={{ opacity: '0.5' }}
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                >
                                    Save
                                </button>}
                        </div>
                    </form>
                </AnimatedModal>

                {/* ------------------ Vm Details Popup--------------------- */}
                <AnimatedModal
                    isOpen={this.state.vmDetailsPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            vmDetailsPopup: false,
                        }))
                    }}
                    height="440px"
                    width="530px"
                >
                    <div style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '22px', fontWeight: '500' }}>VM Details</div>
                    <img
                        src={closeButton} style={{ width: "22px", height: "22px", position: "absolute", top: "15px", right: "20px", cursor: "pointer" }} onClick={() => {
                            this.setState((state) => ({
                                ...state,
                                vmDetailsPopup: false
                            }))
                        }}></img>
                    {this.state.singleVmData ?
                        <div>
                            <div style={{ height: '5px', width: '100%' }}><hr /></div>
                            <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column", width: "95%", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                    <div style={{ margin: '10px 0px', width: "35%", display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey' >VM Name</div>
                                        <div className='objectValue'>{this.state.singleVmData.az_vm_name}</div>
                                    </div>
                                    <div style={{ height: "60px", margin: "0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                    <div style={{ margin: '10px 0px', display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>Id</div>
                                        <div style={{ fontSize: "12px", margin: "5px 0px" }}>{this.state.singleVmData.id}</div>
                                    </div>
                                </div>
                                <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                    <div style={{ margin: '10px 0px', width: "35%", display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>Resource group</div>
                                        <div className='objectValue'>{this.state.singleVmData.az_resource_group}</div>
                                    </div>
                                    <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                    <div style={{ margin: '10px 0px', display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey' >Subscription Id</div>
                                        <div style={{ fontSize: "12px", margin: "5px 0px" }}>{this.state.singleVmData.az_subscription_id}</div>
                                    </div>
                                </div>

                                <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                    <div style={{ margin: '10px 0px', width: "35%", display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>IP address</div>
                                        <div className='objectValue'>{this.state.singleVmData.ip_address}</div>
                                    </div>
                                    <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                    <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>Status</div>
                                        <div className='objectValue'>{this.state.singleVmData.status}</div>
                                    </div>
                                </div>

                                <div style={{ margin: '1% 3%', display: "flex", width: "90%" }}>
                                    <div style={{ margin: '10px 0px', width: "35%", display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>Task type</div>
                                        <div className='objectValue'>{this.state.singleVmData.tasktype == 1 ? "Training" :
                                            this.state.singleVmData.tasktype == 2 ? "Detection" :
                                                this.state.singleVmData.tasktype == 3 ? "Orthomosaic" : "-"}</div>
                                    </div>
                                    <div style={{ height: "60px", margin: "2% 4% 0% 4%", borderLeft: "1px solid #C8C8C8" }}></div>
                                    <div style={{ margin: '10px 5px', display: "flex", flexDirection: "column" }}>
                                        <div className='objectKey'>Available</div>
                                        <div className='objectValue'>{this.state.singleVmData.available ? "Yes" : "No"}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <></>
                    }

                </AnimatedModal>

                <AnimatedModal
                    isOpen={this.state.addVMPopup}
                    onRequestClose={() => {
                        this.setState((state) => ({
                            ...state,
                            addVMPopup: false,
                            updateFlag: false
                        }))
                    }}
                    height="550px"
                    width="550px"
                >
                    <div className='content-text' style={{ textAlign: "center", margin: '10px 0px', height: '30px', fontSize: '20px', fontWeight: '500' }}>{this.state.updateFlag ? "Update Virtual Machine" : "Add New Virtual Machine"}</div>
                    <div style={{ height: '5px', width: '100%' }}><hr /></div>
                    <form onSubmit={this.state.updateFlag ? this.updateVM : this.addNewVM} >
                        <div style={{ margin: '10px 50px' }}>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "1%" }}>VM Name</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="vm_name"
                                    placeholder="Enter VM Name"
                                    onChange={this.onChangeListener}
                                    value={vm_name}
                                />
                            </div>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>IP address</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="ip_address"
                                    placeholder="e.g. 0.0.0.0 to 255.255.255.255"
                                    onChange={this.onChangeListener}
                                    value={ip_address}
                                    pattern='\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b'
                                />
                            </div>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Resource group</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="resource_group"
                                    placeholder="Enter Resource Group"
                                    onChange={this.onChangeListener}
                                    value={resource_group}
                                />
                            </div>
                            <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Azure Subscription ID</div>
                            <div className="add-user-form-text-wrapper">
                                <input
                                    className="sub-add-user-form-text"
                                    type="text"
                                    name="az_subscription_id"
                                    placeholder="Enter Azure Subscription Id"
                                    onChange={this.onChangeListener}
                                    value={az_subscription_id}
                                />
                            </div>
                            <div style={{ display: "flex", marginTop: "4%", justifyContent: "space-between" }}>
                                <div>
                                    <div className='sub-add-user-form-label' style={{ display: "flex" }}>Task Type</div>
                                    <select
                                        className="sub-add-user-form-text"
                                        style={{ width: "200px", borderRadius: "5px", fontSize: "12px" }}
                                        name='tasktype'
                                        value={tasktype}
                                        onChange={this.onChangeListener}
                                    >
                                        <option value={1}>Training</option>
                                        <option value={2}>Detection</option>
                                        <option value={3}>Orthomosaic</option>
                                    </select>
                                </div>
                                <div>
                                    <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Status</div>
                                    <Switch label="Status" onChange={(e) => this.handleSwitchChange(e)} name="status" checked={this.state.status} />
                                </div>
                                <div>
                                    <div className='sub-add-user-form-label' style={{ display: "flex", marginTop: "2%" }}>Available</div>
                                    <Switch label="Status" onChange={(e) => this.handleSwitchChange(e)} name="available" checked={this.state.available} />
                                </div>
                            </div>
                        </div>
                        <div style={{ margin: '50px 50px 10px 50px', display: 'flex', justifyContent: 'space-between' }}>
                            <button
                                className="addcustomer-button-empty-dashboard popup-btn-left"
                                onClick={() => {
                                    this.setState((state) => ({
                                        ...state,
                                        addVMPopup: false,
                                        updateFlag: false,
                                        cancelFlag: true
                                    }));
                                }}
                            >
                                Cancel
                            </button>
                            {this.state.updateFlag ?
                                <button
                                    className="add-user-form-submit-button popup-btn-right btn-ok"
                                    type='submit'
                                >
                                    Update
                                </button>
                                :
                                vm_name && ip_address && resource_group && az_subscription_id ?
                                    <button
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                        type='submit'
                                    >
                                        Add
                                    </button>
                                    : <button disabled style={{ opacity: '0.5' }}
                                        className="add-user-form-submit-button popup-btn-right btn-ok"
                                    >
                                        Add
                                    </button>
                            }

                        </div>
                    </form>
                </AnimatedModal>

                {/* --------------Activity Popup ----------------------- */}

                <ActivityPopup item={this.state.itemName} open={this.state.showProcessPopup} icon={this.state.processPopupIcon} action={this.state.processAction} msg={this.state.processMessage} close={() => this.closeActivityPopup()} />

                {/* --------------------------------------------------------------- */}

                <div className="right-content-new"
                    style={{ zIndex: 0 }}
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar-new" style={{ display: "flex" }}><div className="top-bar-text-new">Machines</div>
                    </div>
                    <div className="main-content">
                        <div style={{ marginTop: "10px", display: "flex", width: "100%", justifyContent: "space-between" }}>
                            <div className="services-content-title"> Virtual Machines : {this.state.machineCounts}</div>
                            <div className="add-user-form-text-wrapper" style={{ marginLeft: "25px", marginRight: '5px' }}>
                                <img src={btnAdd} style={{ height: '30px', width: '34px', cursor: 'pointer' }} onClick={() => this.openAddVMPopup()} />
                            </div>
                        </div>
                        {this.state.loading ? (
                            <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                <img src={Loader} style={{ height: "20vh", width: "100vw", margin: "auto" }} />
                            </div>) : (
                            <table className="customers-table">
                                <tr className="customers-table-header-row" style={{ position: "inherit" }}>
                                    <th>IP address</th>
                                    <th>Edit</th>
                                    <th>Name</th>
                                    <th>Resource Group</th>
                                    <th>Status</th>
                                    <th>Available</th>
                                    <th>Task Type</th>
                                </tr>
                                {this.state.vms.length === 0 ?
                                    <tr className="customers-table-data-row" align="center" style={{ height: "45px" }}>
                                        <td colspan="6">No Machines Available</td>
                                    </tr> :
                                    this.state.vms.map((vm, i) =>
                                        <tr className="customers-table-data-row" style={{ height: "45px" }} >
                                            <td onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    singleVmData: vm,
                                                    vmDetailsPopup: true
                                                }))
                                            }} style={{ cursor: "pointer" }}>{vm.ip_address} </td>
                                            <td><img src={Edit} onClick={() => {
                                                this.setVmValues(vm)
                                            }} style={{ marginLeft: "5px", height: '14px', width: "14px", cursor: "pointer" }} /></td>
                                            <td onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    singleVmData: vm,
                                                    vmDetailsPopup: true
                                                }))
                                            }} style={{ cursor: "pointer" }}>{vm.az_vm_name}</td>
                                            <td onClick={() => {
                                                this.setState((state) => ({
                                                    ...state,
                                                    singleVmData: vm,
                                                    vmDetailsPopup: true
                                                }))
                                            }} style={{ cursor: "pointer" }}>{vm.az_resource_group}</td>
                                            <td>{vm.status}</td>
                                            <td>
                                                <Switch onClick={() => { this.changeVMStatus(vm.id) }} checked={vm.available} />
                                            </td>
                                            <td>
                                                {
                                                    (() => {
                                                        return `${vm.tasktype == 3 ? 'Orthomosaic' : vm.tasktype == 2 ? 'Detection' : 'Training'}`
                                                    })()
                                                }
                                            </td>
                                        </tr>
                                    )}
                            </table>
                        )
                        }
                    </div>
                </div>
            </div > : <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
            </div>
        );
    }
}


import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Link, Redirect } from 'react-router-dom';
import dateFormat from 'dateformat'
import Loader from './res/loader.gif';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { addUserAnalytic, daysInMonth, getFormatedDate, updateOutTime } from './ReusableComponents/reusableFunctions.js';
import {
  Map,
  GoogleApiWrapper,
  LoadingContainer,
} from "google-maps-react";
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import TableView from './ReusableComponents/TableView/TableView.js';
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs.js';
import flightReplay from "./replayVideo.svg";

let markers = []
const todayDate = new Date()
const startDate = new Date(todayDate.setDate(todayDate.getDate() - todayDate.getDay() + 1)).toISOString().substring(0, 10)
const endDate = new Date(todayDate.setDate(todayDate.getDate() - todayDate.getDay() + 7)).toISOString().substring(0, 10)

// need to remove this page
class RMPFlightsStatistics extends Component {
  state = {
    user: {},
    type: "",
    data: [],
    xAxisLabel: "Days",
    loading: true,
    isFlights: false,
    fromDate: startDate,
    selectedWeekStyle: { backgroundColor: '#2989CF', color: 'white', width: '125px' },
    selectedMonthStyle: { width: '125px' },
    selectedYearStyle: { width: '125px' },
    selectedGoStyle: {},
    toDate: endDate,
    totoalFlightCount: 0,
    color1: "#85b4ff",
    color2: '#b5d2ff',
    color3: "#ebf3ff",
    graphs: [
      { unit: 'week' },
      { unit: 'month' },
      { unit: 'year' },

    ],
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    selectedGreaphUnit: "week",
    sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
    logsDisplay: [],
    totalCount: 0,
    userperpage: 10,
    currentpage: 1,
    totalCount: 0,
    firstpage: 0,
    dataLoader: true,
    center: { lat: 19.993982, lng: 73.790416 },
    redirect: false,
    selectedFlight: {},
    yTicks: {
      padding: 7,
      fill: "black",
      display: true,
      fontSize: 12,
      fontFamily: "Poppins",
      beginAtZero: true,
      fontStyle: "normal",
      fontColor: "#666666",
      lineHeight: "14px",
    },
    currentPage: 1,
    operatorCount: 0,
    PAGE_SIZE: 15,
    outId: "",
    dateError: {
      error: false,
      message: ""
    },
    graphData: [],
    timeframe: '1 day',
  }

  parentSidebarSwitch = () => {
    this.setState((state) => ({
      ...state,
      sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
    }))
  }

  getFlightLogs = () => {
    this.setState(state => ({
      ...state,
      logsDisplay: [],
      totalCount: 0,
      dataLoader: true
    }))
    const { currentpage, userperpage } = this.state;
    const lastIndex = currentpage * userperpage;
    const firstpage = lastIndex - userperpage;
    let toDate = this.state.toDate
    let fromDate = this.state.fromDate
    toDate = new Date(toDate + " 23:59:00+00")
    toDate = toDate.getTime() / 1000
    fromDate = new Date(fromDate + " 00:00:00+00")
    fromDate = fromDate.getTime() / 1000
    API.getAllFlightsLogs(" ", fromDate, toDate, firstpage, this.state.userperpage).then(async (data) => {
      let logs = await data.logs;
      let count = await data.count;
      console.log("logs : ", logs);

      // getFormatedDate(logs_info.start_time, logs_info.end_time)
      if (data.logs.length == 0) {
        this.setState(state => ({
          ...state,
          logsDisplay: data.logs,
          totalCount: data.count,
          dataLoader: false,
        }), () => this.inspectFlights())
      }
      else {
        this.setState(state => ({
          ...state,
          logsDisplay: logs?.map(logs_info => { return { ...logs_info, total_flight_time: getFormatedDate(logs_info.start_time, logs_info.end_time) } }),
          totalCount: count,
          dataLoader: false,
        }), () => this.inspectFlights())
      }
    }, (e) => {
      console.log(e);
    })
  }
  // To initialize map
  initMap = (mapProps, map) => {
    const { google } = mapProps;
    this.setState((state) => (
      {
        ...state,
        google: google,
        map: map
      })
    )
    this.inspectFlights();
  };

  firstpage = () => {
    if (this.state.currentpage > 1) {
      this.setState((state) => ({
        ...state,
        currentpage: 1
      }), () => {
        this.getFlightLogs()
      });
    }
  }

  prevpage = () => {
    if (this.state.currentpage > 1) {
      this.setState((state) => ({
        ...state,
        currentpage: this.state.currentpage - 1
      }), () => {
        this.getFlightLogs()
      }
      );
    }
  }
  nextpage = () => {
    if (this.state.currentpage < Math.ceil(this.state.totalCount / this.state.userperpage)) {
      this.setState((state) => ({
        ...state,
        currentpage: state.currentpage + 1
      }), () => {
        this.getFlightLogs()
      });
    }
  }

  lastpage = () => {
    if (this.state.currentpage < Math.ceil(this.state.totalCount / this.state.userperpage)) {
      this.setState((state) => ({
        ...state,
        currentpage: Math.ceil(this.state.totalCount / this.state.userperpage)
      }), () => {
        this.getFlightLogs()
      });
    }
  }

  changepage(num) {
    if (num >= 1 && num <= (Math.ceil(this.state.totalCount / this.state.userperpage))) {
      this.setState((state) => ({
        ...state,
        currentpage: num,
      }), () => this.getFlightLogs());
    }

  }

  //Function to display flights on map
  inspectFlights = () => {
    let toDate = this.state.toDate
    let fromDate = this.state.fromDate
    toDate = new Date(toDate + " 23:00:00+00")
    toDate = toDate.getTime() / 1000
    fromDate = new Date(fromDate + " 00:00:00+00")
    fromDate = fromDate.getTime() / 1000
    API.getAllFlightsLogs(" ", fromDate, toDate, 0, "all", "map").then(async (data) => {
      let flightLogs = data.logs;
      if (this.props.location.state.showMap && flightLogs && flightLogs.length > 0) {
        let { google, map } = this.state
        let count = 0;
        const infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
        });
        let centerAdded = false
        flightLogs.forEach((flight, index) => {
          if (flight.flightSource) {
            let data = flight.flightSource.split("  ")
            let lat = Number(data[0])
            let lng = Number(data[1])
            let latlng = { lat, lng };
            if (!centerAdded) {
              this.setState({ center: latlng })
              centerAdded = true
            }
            const label = "";
            let position = new google.maps.LatLng(latlng)
            const marker = new google.maps.Marker({
              position,
              label,
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                strokeColor: '1px black',
                fillColor: flight.regionsData ? 'yellow' : '#2989CF',
                fillOpacity: 1,
                scale: 12,
                strokeWeight: 1
              }
            });
            markers[count++] = marker
            google.maps.event.addListener(marker, 'click', () => {
              infoWindow.setContent(label);
              this.setState((state) => ({
                ...state,
                selectedFlight: flight,
                redirect: true,
              }));
            });
          }
        })
        let clusterOptions = { styles: [{ textColor: "green", textSize: 14, fontFamily: "Open Sans" }] };
        new MarkerClusterer({ markers, map, clusterOptions });
      }
    }, (e) => {
      console.log(e);
    })
  }

  redirectToFlight = () => {
    let { user, type, selectedFlight } = this.state
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/remote_mission/flight_details",
            state: {
              user,
              type,
              sidebarMode: this.state.sidebarMode,
              flightId: selectedFlight.id,
              planName: selectedFlight.plan_name,
              projectName: selectedFlight.project_name,
              date: selectedFlight.end_time,
              startTime: selectedFlight.start_time,
              end_time: selectedFlight.end_time,
              streamKey: selectedFlight.streamkey,
              software_edition: selectedFlight.software_edition
            },
          }}
        />
      );
    }
  };

  getFlightsStatData = async () => {
    let graphUnit = this.state.selectedGreaphUnit;
    let toDate = this.state.toDate
    let fromDate = this.state.fromDate
    toDate = new Date(toDate + " 23:59:00+00")
    toDate = toDate.getTime() / 1000
    fromDate = new Date(fromDate + " 00:00:00+00")
    fromDate = fromDate.getTime() / 1000
    this.setState((state) =>
    ({
      ...state,
      isFlights: false,
      loading: true,
    }), () => {
      this.firstpage()
    })
    API.flightsStatData(graphUnit, fromDate, toDate).then(async (data) => {
      let yTicks = data.data.every((val, i, arr) => val === arr[0]) ? {
        padding: 7,
        fill: "black",
        display: true,
        fontSize: 12,
        fontFamily: "Poppins",
        beginAtZero: true,
        fontStyle: "normal",
        fontColor: "#666666",
        lineHeight: "14px",
        stepSize: 1
      } : {
        padding: 7,
        fill: "black",
        display: true,
        fontSize: 12,
        fontFamily: "Poppins",
        beginAtZero: true,
        fontStyle: "normal",
        fontColor: "#666666",
        lineHeight: "14px",
      }
      this.setState((state) => ({
        ...state,
        yTicks: yTicks,
        loading: false,
      }), () => {
        this.getFlightLogs()
      })
      data.data.forEach(element => {
        if (element > 0) {
          this.setState((state) =>
          ({
            ...state,
            isFlights: true
          }))
        }

      })
      if (this.state.selectedGreaphUnit == 'week') {
        const data1 = (canvas) => {

          return {
            labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            datasets: [
              {
                data: data.data,
                fill: true,
                backgroundColor:
                  (context) => {
                    if (!context.chart.chartArea) return;
                    const chart = context.chart;
                    const { ctx, data, chartArea: { top, bottom }, } = chart;
                    const gradient = ctx.createLinearGradient(0, top, 0, bottom);
                    gradient.addColorStop(0, this.state.color1);
                    gradient.addColorStop(0.5, this.state.color2);
                    gradient.addColorStop(1, this.state.color3);
                    return gradient
                  },
                borderColor: "#2989cf",
                borderWidth: 0.5,
                tension: 0.5
              },
            ],
          }
        }
        this.setState((state) => ({
          ...state,
          data: data1(),
          xAxisLabel: "Days",
          totoalFlightCount: data.data.length
        }),
          () => {
            try {
              document.getElementsByTagName('canvas')[0].className = "flight-graph";
            } catch (e) {
            }
          }
        );
      }
      else if (this.state.selectedGreaphUnit == 'month') {
        const data1 = () => {

          return {
            labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
            datasets: [
              {
                data: data.data,
                fill: true,
                backgroundColor: (context) => {
                  const chart = context.chart;
                  const { ctx, chartArea } = chart;
                  const gradient = ctx.createLinearGradient(0, 0, 0, 150);
                  gradient.addColorStop(0, this.state.color1);
                  gradient.addColorStop(0.5, this.state.color2);
                  gradient.addColorStop(1, this.state.color3);
                  return gradient
                },
                borderColor: "#2989cf",
                borderWidth: 0.5,
                tension: 0.5
              },
            ],
          }
        }
        this.setState((state) => ({
          ...state,
          data: data1(),
          xAxisLabel: "Days",
        })
          ,
          () => {
            try {
              document.getElementsByTagName('canvas')[0].className = "flight-graph";
            } catch (e) {
            }
          }
        );
      }
      else if (this.state.selectedGreaphUnit == 'year') {
        const data1 = (canvas) => {

          return {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
            datasets: [
              {
                data: data.data,
                fill: true,
                backgroundColor: (context) => {
                  const chart = context.chart;
                  const { ctx, chartArea } = chart;
                  const gradient = ctx.createLinearGradient(0, 0, 0, 150);
                  gradient.addColorStop(0, this.state.color1);
                  gradient.addColorStop(0.5, this.state.color2);
                  gradient.addColorStop(1, this.state.color3);
                  return gradient
                },
                borderColor: "#2989cf",
                borderWidth: 0.5,
                tension: 0.5
              },
            ],
          }
        }
        this.setState((state) => ({
          ...state,
          data: data1(),
          xAxisLabel: "Months",
        })
          ,
          () => {
            try {
              document.getElementsByTagName('canvas')[0].className = "flight-graph";
            } catch (e) {

            }
          }
        );
      }

    },
      (err) => {
        console.log(err);
        this.setState((state) =>
        ({
          ...state,
          loading: false
        }))
      })
  }

  componentWillMount() {
    ChartJS.register(BarElement, CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
      Filler,
    );
    addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
  }

  componentWillUnmount() { updateOutTime(this.state.outId) }

  componentDidMount() {
    // this.generateYearData(2023, '1 day')
    document.title = "Flights - AeroMegh";
    if (!this.props.location.state) {
      API.getUserType().then(([registered, type, user]) => {
        this.setState(state => ({
          ...state,
          registered,
          type,
          user,
        }), () => {
          this.getFlightsStatData()
        })
      }, (e) => {
        this.setState({
          user: {},
          type: 'unknown'
        })
        window.location.replace("/login");
      })
    } else {
      if (this.props.location.state.config) {
        this.setState((state) => ({
          ...state,
          user: this.props.location.state.config.user,
          type: this.props.location.state.config.type
        }), () => {
          this.getFlightsStatData()
        })
      } else if (this.props.location.state.user) {
        this.setState((state) => ({
          ...state,
          user: this.props.location.state.user,
          type: this.props.location.state.type
        }), () => {
          this.getFlightsStatData()
        })
      }
    }
  }

  // Function to generate a random value between 0 and 1000
  generateRandomValue = () => {
    // Generate a random number between 0 and 1
    const random = Math.random();

    // If random number is less than or equal to 0.5, return 0
    // Otherwise, return a random value between 1 and 1000
    return random <= 0.5 ? 0 : Math.floor(Math.random() * 1001);
  }

  // Function to generate a random color
  generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  // generateYearData = (year, timeframe) => {
  //   const data = [];
  //   let increment;

  //   switch (timeframe) {
  //     case '1 min':
  //       increment = 60; // 1 minute in seconds
  //       break;
  //     case '3 min':
  //       increment = 180; // 3 minutes in seconds
  //       break;
  //     case '5 min':
  //       increment = 300; // 5 minutes in seconds
  //       break;
  //     case '15 min':
  //       increment = 900; // 15 minutes in seconds
  //       break;
  //     case '30 min':
  //       increment = 1800; // 30 minutes in seconds
  //       break;
  //     case '1 hr':
  //       increment = 3600; // 1 hour in seconds
  //       break;
  //     case '4 hr':
  //       increment = 14400; // 4 hours in seconds
  //       break;
  //     case '1 day':
  //       increment = 86400; // 1 day in seconds
  //       break;
  //     case '1 week':
  //       increment = 604800; // 1 week in seconds
  //       break;
  //     case '1 month':
  //       // Approximating a month to 30 days for simplicity
  //       increment = 2592000; // 30 days in seconds
  //       break;
  //     default:
  //       increment = 86400; // Default to 1 day
  //       break;
  //   }

  //   // Loop through each increment of time
  //   for (let timestamp = new Date(year, 0, 1).getTime() / 1000; timestamp < new Date(year + 1, 0, 0).getTime() / 1000; timestamp += increment) {
  //     const value = this.generateRandomValue();
  //     // const color = this.generateRandomColor();
  //     data.push({ value, time: timestamp });
  //   }
  //   console.log("dataaaaaaaaaa:", data)
  //   this.setState({
  //     graphData: data
  //   })
  // }

  render() {
    let { user, type, currentpage } = this.state
    return (
      user.username ? <div className="wrapper">
        {this.redirectToFlight()}
        <div className="right-content-new"
          onClick={
            () => {
              this.setState((state) => ({
                ...state, sidebarMode: 'shrunk'
              }))
            }
          }
        >
          <ActivityPopup
            open={this.state.dateError.error}
            msg={this.state.dateError.message}
            close={() => {
              this.setState({
                fromDate: startDate,
                toDate: endDate,
                dateError: {
                  error: false,
                  message: ""
                }
              })
            }}
          />
          <div className="top-bar-new"><div className="top-bar-text-new">
            <BreadCrumbs data={[{ displayName: 'Flights' }]} />
          </div></div>
          <div>
            <div style={{ display: "flex", margin: "1% 0%" }}>
              <div className="services-content-title" style={{ marginLeft: '60px' }}>{"Flights Statistics"}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ marginLeft: "60px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    outline: "solid 2px #2989CF",
                    borderRadius: "2px",
                    width: "360px",
                  }}
                >
                  <div
                    style={{ width: "120px", height: "100%", borderRadius: "0px 0px 0px 0px", textAlign: "center", }}
                    onClick={() => {
                      this.setState(
                        (state) => ({
                          ...state,
                          fromDate: startDate,
                          toDate: endDate,
                          selectedGreaphUnit: 'week'
                        }), () => this.getFlightsStatData()
                      );
                    }}
                    className={
                      this.state.selectedGreaphUnit == 'week'
                        ? "active-div "
                        : "inactive-div "
                    }
                  >
                    This Week
                  </div>
                  <div style={{ backgroundColor: "#2989CF", height: "auto", width: "2px" }} />
                  <div
                    style={{ width: "125px", textAlign: "center", height: "100%", }}
                    onClick={() => {
                      const today = new Date()
                      this.setState(
                        (state) => ({
                          ...state,
                          fromDate: new Date(today.getFullYear(), today.getMonth(), 2).toISOString().substring(0, 10),
                          toDate: new Date(today.getFullYear(), today.getMonth() + 1, 1).toISOString().substring(0, 10),
                          selectedGreaphUnit: 'month'
                        }), () => this.getFlightsStatData()
                      );
                    }}
                    className={
                      this.state.selectedGreaphUnit == 'month'
                        ? "active-div "
                        : "inactive-div "
                    }
                  >
                    This Month
                  </div>
                  <div style={{ backgroundColor: "#2989CF", height: "auto", width: "2px" }} />
                  <div
                    style={{ width: "120px", borderRadius: "0px 0px 0px 0px", textAlign: "center", height: "100%", }}
                    onClick={() => {
                      const today = new Date()
                      this.setState((state) => ({
                        ...this.state,
                        fromDate: new Date(new Date().getFullYear(), 0, 2).toISOString().substring(0, 10),
                        toDate: new Date(new Date().getFullYear(), 11, 32).toISOString().substring(0, 10),
                        selectedGreaphUnit: 'year'
                      }), () => this.getFlightsStatData()
                      );
                    }}
                    className={
                      this.state.selectedGreaphUnit == 'year'
                        ? "active-div "
                        : "inactive-div "
                    }
                  >
                    This Year
                  </div>
                </div>
              </div>
              {/* --------- */}
              <div style={{ display: "flex", paddingRight: "50px" }}>
                <div style={{ marginLeft: "auto", width: "fit-content" }}>
                  <div className="add-user-form-text-wrapper" style={{ display: "flex", paddingLeft: "30px" }} >
                    <label className="add-user-form-label" style={{ fontFamily: "Poppins", fontSize: "12px", paddingTop: "8px", marginLeft: "25px", fontWeight: '600' }}>From:</label>
                    <input
                      id="dateRequired"
                      name="dateRequired"
                      defaultValue={this.state.fromDate}
                      className="add-user-form-text"
                      type="date"
                      max='9000-01-01'
                      onChange={(e) => {
                        let value = e.target.value;
                        if (new Date(value) > new Date()) {
                          this.setState({
                            dateError: {
                              error: true,
                              message: "From date can not be greater than today date!"
                            }
                          })
                        }
                        else {
                          this.setState((state) => ({
                            ...state,
                            dateError: {
                              error: false,
                              message: ""
                            },
                            fromDate: value,
                            selectedGoStyle: {}
                          }))
                        }
                      }}
                      value={this.state.fromDate}
                      required
                      style={{
                        marginLeft: "5px",
                        height: "auto",
                        borderRadius: '10px',
                        paddingLeft: '5px',
                        width: "auto",

                      }}
                    />
                    <label className="add-user-form-label" style={{ fontFamily: "Poppins", fontSize: "12px", paddingTop: "8px", marginLeft: "25px", fontWeight: '600' }}>To:</label>
                    <input
                      id="dateRequired"
                      name="dateRequired"
                      defaultValue={this.state.toDate}
                      className="add-user-form-text"
                      type="date"
                      max='9000-01-01'
                      onChange={(e) => {
                        let value = e.target.value;
                        if (new Date(value) < new Date(this.state.fromDate)) {
                          this.setState({
                            dateError: {
                              error: true,
                              message: "To date can not be less than from date!"
                            }
                          })
                        }
                        else {
                          this.setState((state) => ({
                            ...state,
                            dateError: {
                              error: false,
                              message: ""
                            },
                            toDate: value,
                            selectedGoStyle: {}
                          }))
                        }
                      }}
                      value={this.state.toDate}
                      required
                      style={{
                        marginLeft: "5px",
                        height: "auto",
                        borderRadius: '10px',
                        paddingLeft: '5px',
                        width: "auto",
                      }}
                    />
                    <div className="add-user-form-submit-button"
                      style={{ height: "30px", margin: "2px 10px", cursor: "pointer", textAlign: "center", padding: "10px 20px 10px 20px" }}
                      onClick={() => {
                        this.getFlightsStatData(this.state.graphUnit, this.state.fromDate, this.state.toDate)
                      }}
                    >View</div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginTop: '20px', background: "white", maxWidth: "calc(100% - 100px)" }} className={this.props.location.state.showMap ? "" : "container"}>
              <div style={{ height: "60vh" }}>
                <div style={{ textAlign: "center", paddingTop: "15px" }}>
                  <p style={{ fontSize: "14px", color: "#3C3C3C", fontWeight: "500" }}>Date : {dateFormat(this.state.fromDate, "dd/mm/yyyy") + " to " + dateFormat(this.state.toDate, "dd/mm/yyyy")}</p>
                </div>
                <div>
                  {!this.state.loading && !this.state.dataLoader ?
                    <div style={{ overflow: "hidden", display: "flex", textAlign: "center", height: "100%" }}>
                      {
                        this.props.location.state.showMap ? (
                          <div style={{ overflow: "hidden", height: '48vh', marginLeft: "5%", width: '86vw', position: 'relative' }}>
                            <Map
                              google={this.props.google}
                              onReady={this.initMap}
                              initialCenter={this.state.center ? this.state.center : { lat: 20.008482, lng: 73.756834 }}
                              center={this.state.center}
                              mapType={"satellite"}
                              zoom={15}
                              showIcons={true}
                              disableDefaultUI={true}
                              yesIWantToUseGoogleMapApiInternals
                            ></Map>
                          </div>
                        ) : (
                          <></>
                        )
                      }
                    </div> :
                    <div style={{ height: "30vh", width: "92vw", display: "flex", width: "100%", textAlign: "center" }}>
                      <img src={Loader} style={{
                        height: "auto",
                        width: "auto",
                        margin: "auto"
                      }} />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div style={{ margin: "4% 0% 0% 1%" }}>
              {
                this.state.dataLoader ? (
                  <div style={{ height: "10vh", width: "93vw", display: "flex" }}>
                    <img src={Loader} style={{
                      height: "auto",
                      width: "auto",
                      margin: "auto"
                    }} />
                  </div>
                ) : (
                  <div style={{ position: "sticky", padding: "0 2%", }}>
                    <TableView
                      columns={[
                        { Header: 'Project Name', accessor: 'project_name', width: '16%', isSortable: true },
                        { Header: 'Plan Name', accessor: 'plan_name', width: '16%', isSortable: true },
                        { Header: 'Drone', accessor: 'drone_name', width: '16%', isSortable: true },
                        { Header: 'Start Time', accessor: 'start_time', width: '16%', isSortable: true },
                        { Header: 'End Time', accessor: 'end_time', width: '16%', isSortable: true },
                        { Header: 'Distance (M)', accessor: 'distance_covered', width: '10%', isSortable: true },
                        { Header: 'Area (Acr)', accessor: 'area_covered', width: '10%', isSortable: true },
                        { Header: "Replay", accessor: "link_data", width: '20%', isLink: true, isCenter: true },
                      ]}
                      onClickLink={(flight) => {
                        this.props.history.push("/drone_control_center/fly_view", { flight: flight.id, user: this.state.user, replyData: 0 })
                      }}
                      data={this.state.logsDisplay?.map((flight) => {
                        return ({
                          ...flight,
                          link_data: { img: flightReplay }
                        })
                      }) || []}
                      user={user}
                      count={this.state.totalCount}
                      headingLeft="Flights"
                      onStateChange={(changedState) => { console.log("changedState", changedState); }}
                    />
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div > : <div />
    );
  }
}
export default GoogleApiWrapper({
  apiKey: "AIzaSyBuxfUtRZSEUT2QrbZA8zUsG58lZUYYmwI",
  libraries: ["drawing", "places"],
  LoadingContainer: LoadingContainer,
})((RMPFlightsStatistics));
import React, { useEffect, useState } from 'react'
import { getDroneFlightLocations, getUserType } from './api.js';
import { Popup } from './ReusableComponents/reusableFunctions.js';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
import TableView from './ReusableComponents/TableView/TableView.js';
import Map from "./MapComponent.js";

const AdminDroneFlightLocations = (props) => {

    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true)
    const [locations, setLocations] = useState([])

    const isAdmin = true

    const getDroneFlightLocationsData = async () => {
        try {
            const droneLocations = await getDroneFlightLocations(props.location.state.drone.drone_id, isAdmin)
            console.log('droneLocations', droneLocations)
            setLocations(droneLocations)
            setLoading(false)
        }
        catch (e) {
            setLoading(false)
            Popup.alert("ERROR", e?.message || e)
        }
    }

    const getUser = async () => {
        const [blah, type, user] = await getUserType();
        setUser(user);
        getDroneFlightLocationsData()
    }

    useEffect(() => {
        document.title = "Drone Flight Locations - Admin";
        try {
            if (props.location.state.user && props.location.state.drone) {
                setUser(props.location.state.user);
                getDroneFlightLocationsData()
            } else {
                getUser()
            }
        } catch (e) {
            console.log(e);
            Popup.alert("ERROR", e.message)
        }
    }, []);

    return (
        <div className="wrapper">
            <div className="right-content-new" style={{ zIndex: 0 }}>
                <div className="top-bar-new" style={{ display: "flex" }}>
                    <div className="top-bar-text-new">Flight Locations {`- ${props.location.state.drone.drone_id}` || ''}</div>
                </div>
                <div className="main-content main-content-dcc" style={{ paddingTop: "1px", paddingLeft: "0px", paddingRight: "0px", display: "flex", }}>
                    {loading ? <LoaderComponent />
                        : <Map
                            className="dcc-all-drones-map"
                            recenterMap={true}
                            locationsData={locations}
                            markerClickCallback={(location) => { }}
                            showIcons={true}
                            includePortsInRecenter={true}
                        />
                    }
                </div>

            </div>
        </div>

    );
};
export default AdminDroneFlightLocations
import React from "react";
import { Link } from "react-router-dom";
import Loader from "../icons/loader.svg";
import { isServiceAeroGCS } from "./reusableFunctions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const StatCard = ({ className, leftBorderColor, icon, loading, statCount, statName, action, isDisabled, position, minWidth,
  maxWidth, width, minHeight, maxHeight, height, border, borderRadius, backgroundColor, padding, iconContainer, isHover }) => {
  const location = useLocation()
  return (
    isServiceAeroGCS(location.pathname, true) ?
      action?.actionStatLink?.pathname ?
        <Link to={action?.actionStatLink?.pathname ? { pathname: action.actionStatLink.pathname, state: action.actionStatLink.state } : ""} style={{ height: "100%" }}>
          <div
            className={isDisabled ? "stat-card " : `stat-card ${isHover ? `hover-stat-card` : ''} ${className}`}
            style={{
              minWidth: minWidth || undefined,
              maxWidth: maxWidth || undefined,
              width: width || "100%",
              height: height || "100%",
              minHeight: minHeight || undefined,
              maxHeight: maxHeight || undefined,
              // border: border || "1px solid #C2C2C2",
              // borderRadius: borderRadius || "10px",
              backgroundColor: backgroundColor || "#FCFCFC",
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
              // padding: padding || "10px 20px 10px 35px ",
              fontFamily: "'Lato', sans-serif",
              position: position || "relative",
              filter: isDisabled ? "grayscale(100%)" : "",
              opacity: isDisabled ? "70%" : "100%",
              // boxShadow: '0px 0px 4px 0px #0000001A',
              overflow: 'hidden',
              position: 'relative'
            }}
          >
            <div className="stat-card-left-border" style={{ background: leftBorderColor }} >

            </div>

            {loading ? (
              <div
                style={{
                  width: "80px",
                  // marginLeft: "30px",
                  display: "flex",
                  alignItems: "center ",
                }}
              >
                <img src={Loader} style={{ width: "100%" }} />
              </div>
            ) : (
              <div className="stat-card-info-container" >
                <div className="stat-card-title">
                  {statName}
                </div>
                <div
                  className="stat-card-count"
                >
                  {statCount}
                </div>
              </div>
            )}
            {iconContainer}
            {icon &&
              <div className="stat-card-icon" style={{ width: "40px", height: "40px", marginLeft: "30px" }}>
                <img style={{ width: "100%" }} src={icon} />
              </div>
            }
            {/* {action && (
        <div
          style={{
            position: "absolute",
            right: "10px",
            bottom: "5px",
            fontSize: "10px",
            color: "#1675e0",
            cursor: isDisabled ? "default" : "pointer"
          }}
          onClick={() => {
            if (action.onClickAction) {
              action.onClickAction();
            } else {
            }
          }}
        >
          {action.actionStatLink && !isDisabled ? (
            <Link
              to={{
                pathname: action.actionStatLink.pathname,
                state: action.actionStatLink.state,
              }}
              style={{ textDecoration: "none", color: "#2989CF" }}
            >
              {action.actionStatName}
            </Link>
          ) : (
            action.actionStatName
          )}
        </div>
      )} */}
          </div>
        </Link> :
        <div
          className={isDisabled ? "stat-card " : `stat-card ${isHover ? `hover-stat-card` : ''} ${className}`}
          style={{
            minWidth: minWidth || undefined,
            maxWidth: maxWidth || undefined,
            width: width || "100%",
            height: height || "100%",
            minHeight: minHeight || undefined,
            maxHeight: maxHeight || undefined,
            // border: border || "1px solid #C2C2C2",
            // borderRadius: borderRadius || "10px",
            backgroundColor: backgroundColor || "#FCFCFC",
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            // padding: padding || "10px 20px 10px 35px ",
            fontFamily: "'Lato', sans-serif",
            position: position || "relative",
            filter: isDisabled ? "grayscale(100%)" : "",
            opacity: isDisabled ? "70%" : "100%",
            // boxShadow: '0px 0px 4px 0px #0000001A',
            overflow: 'hidden',
            position: 'relative'
          }}
        >
          <div className="stat-card-left-border" style={{ background: leftBorderColor }} >

          </div>

          {loading ? (
            <div
              style={{
                width: "80px",
                // marginLeft: "30px",
                display: "flex",
                alignItems: "center ",
              }}
            >
              <img src={Loader} style={{ width: "100%" }} />
            </div>
          ) : (
            <div className="stat-card-info-container" >
              <div className="stat-card-title">
                {statName}
              </div>
              <div
                className="stat-card-count"
              >
                {statCount}
              </div>
            </div>
          )}
          {iconContainer}
          {icon &&
            <div className="stat-card-icon" style={{ width: "40px", height: "40px", marginLeft: "30px" }}>
              <img style={{ width: "100%" }} src={icon} />
            </div>
          }

        </div>
      :
      <div
        style={{
          minWidth: minWidth || undefined,
          maxWidth: maxWidth || undefined,
          width: width || "100%",
          height: height || "100%",
          minHeight: minHeight || undefined,
          maxHeight: maxHeight || undefined,
          border: border || "1px solid #C2C2C2",
          borderRadius: borderRadius || "5px",
          backgroundColor: backgroundColor || "#FAFAFA",
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          padding: padding || "10px 20px 10px 28px ",
          fontFamily: "'Poppins', sans-serif",
          position: position || "relative",
          filter: isDisabled ? "grayscale(100%)" : "",
          opacity: isDisabled ? "70%" : "100%",
        }}
      >
        <div style={{ width: "40px", height: "40px" }}>
          <img style={{ width: "100%" }} src={icon} />
        </div>
        {loading ? (
          <div
            style={{
              width: "80px",
              marginLeft: "30px",
              display: "flex",
              alignItems: "center ",
            }}
          >
            <img src={Loader} style={{ width: "100%" }} />
          </div>
        ) : (
          <div style={{ marginLeft: "30px" }}>
            <div
              style={{ fontSize: "15px", fontWeight: "600", color: "#3c3c3c", height: "20px", display: "flex", alignItems: "center" }}
            >
              {statCount}
            </div>
            <div style={{ color: "rgba(0,0,0,0.7)", fontSize: "15px", height: "20px", display: "flex", alignItems: "center" }}>
              {statName}
            </div>
          </div>
        )}

        {action && (
          <div
            style={{
              position: "absolute",
              right: "10px",
              bottom: "5px",
              fontSize: "10px",
              color: "#1675e0",
              cursor: isDisabled ? "default" : "pointer"
            }}
            onClick={() => {
              if (action.onClickAction) {
                action.onClickAction();
              } else {
              }
            }}
          >
            {action.actionStatLink && !isDisabled ? (
              <Link
                to={{
                  pathname: action.actionStatLink.pathname,
                  state: action.actionStatLink.state,
                }}
                style={{ textDecoration: "none", color: "#2989CF" }}
              >
                {action.actionStatName}
              </Link>
            ) : (
              action.actionStatName
            )}
          </div>
        )}
      </div>
  );
};

export default StatCard;

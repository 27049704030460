import React, { Component } from 'react';
import * as API from './api.js';
import './style.css';
import Loader from './icons/loader.svg';
import Search from "./Icon/Search.png";
import dateFormat from 'dateformat';
import { Link, Redirect } from 'react-router-dom';
import { SortComponent } from './ReusableComponents/SortComponent.js';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
import TableView from './ReusableComponents/TableView/TableView.js';

export default class AdminUsersEmailLogs extends Component {
    state = {
        user: {},
        tableState: {
            pageNumber: 1,
            pageSize: 15,
        },
        loading: true,
        allEmailLogs: [],
        emailLogsCount: 0,
        tableLoading: true,
    }

    getAllEmailLogs = () => {
        this.setState({ tableLoading: true })
        API.getAllEmailLogs(undefined, this.state.tableState).then((res) => {
            console.log('res', res);
            this.setState({
                allEmailLogs: res.allEmailLogs || [],
                emailLogsCount: res.totalCount || 0,
                loading: false,
                tableLoading: false
            })

        })
    }

    componentWillMount() {
        document.title = "Users - Admin";
        if (this.props.location.state) {
            let { user } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user
            }), () => {
                this.getAllEmailLogs()
            })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user
                }), () => {
                    this.getAllEmailLogs()
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }


    render() {
        return <div className="wrapper">
            <div className="right-content-new">
                <div className="top-bar-new" style={{ display: "flex" }}><div className="top-bar-text-new">Email Logs</div></div>
                {this.state.loading ? <LoaderComponent />
                    : <div className="main-content-new">
                        <div style={{ height: 'calc(100% - 150px)' }}>
                            <TableView
                                headingLeft={"Email Logs"}
                                isLoading={this.state.tableLoading}
                                onStateChange={(state) => {
                                    this.setState({ tableState: state }, () => { this.getAllEmailLogs() })
                                }}
                                onClickRow={(emailLog) => { }}
                                onClickLink={(emailLog) => { }}
                                data={this.state.allEmailLogs.length != 0 ? this.state.allEmailLogs : []}
                                columns={this.state.allEmailLogs.length != 0 ? [
                                    { Header: "Send To", accessor: "send_to", width: '35%', },
                                    { Header: "Subject", accessor: "subject", width: '40%' },
                                    { Header: "Status", accessor: "status_data", width: '10%' },
                                    { Header: "Date & Time", accessor: "date_time", isDate: true, width: '15%' },
                                ] : []}
                                count={this.state.emailLogsCount || 0}
                            />
                        </div>
                    </div>}
            </div>
        </div >
    }
}
import React, { Component } from "react";
import "./style.css";
import * as API from './api.js';
import VideoPlayer from "./RMPVideoPlayer"
import FlightDetailsBanner from "./AeroGCSEnterprise/Flights Details/FlightDetailsBanner.js";
import TableView from "./ReusableComponents/TableView/TableView.js";
import { BreadCrumbs } from "./ReusableComponents/BreadCrumbs.js";
import LoaderComponent from "./ReusableComponents/LoaderComponent.js";
import AppConstants from "./AppConstants.js";
import { addUserAnalytic, checkURLExist, updateOutTime } from "./ReusableComponents/reusableFunctions.js";
import L from "leaflet";
import InteractiveGraph from "./AeroGCSEnterprise/Components/InteractiveGraph.js";
import CustomGraph from "./AeroGCSEnterprise/Components/CustomeGraph.js";
import EnterprisePip from "./AeroGCSEnterprise/Components/EnterprisePip.js";
import { getActiveTeam } from "./Teams/index.js";
import Map from "./MapComponent.js";

export default class RMFlightDetails extends Component {
    state = {
        key: "AS_TELE_DATA,AS_KEEP_ALIVE",
        key1: "AS_TELE_DATA",
        key2: 'AS_FLIGHT_LOG',
        showDetailedAltGraph: false,
        showDetailedSpeedGraph: false,
        showDetailedBatGraph: false,
        flightId: this.props.flightId,
        requestId: this.props.location.state.request_id || this.props.location.state.requestId,
        streamKey: '',
        flightLogData: [],
        teleData: [],
        altData: [],
        speedData: [],
        batData: [],
        flightData: [],
        videoLink: '',
        projectName: "",
        planName: "",
        date: "",
        startTime: "",
        endTime: "",
        avgAlt: 0,
        avgSpeed: 0,
        user: {},
        type: "",
        loader: true,
        sidebarMode: (this.props.states ? this.props.states.sidebarMode : "shrunk"),
        loaderComponent: true,
        areaCovered: "",
        areaCoveredMethod: '',
        distanceCovered: "",
        droneId: "",
        firmwareVersion: "",
        greenBuildNumber: "",
        droneName: "",
        droneUIN: "",
        software_edition: '',
        plotArea: 0,
        reportData: undefined,
        totalCount: 0,
        filter: {
            pageSize: 15,
            pageNumber: 1,
            key2: 'AS_FLIGHT_LOG'
        },
        areaAltitudeGraphData: [],
        arealiquidGraphData: [],
        areaFlowRateGraphData: [],
        areaSpeedGraphData: [],
        logsLoad: true,
        pipWindowOpen: true,
        outId: ""
    };


    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    convertBatteryVal = (val) => {
        if (val == 65535) {
            return 0;
        }
        else {
            return val
        }
    }

    getReportData = async (flightId) => {
        try {
            let data = await API.getAllGreenSprayReports({ "flightId": flightId })
            if (data.allReports.length > 0) this.setState({ reportData: data.allReports[0] })
        } catch (err) {
            console.log("err : ", err);
        }
    }

    callAfterUser = async () => {
        try {
            const flightDetails = await API.getFlightDetails(this.props.location.state.flightId, false, 0, 0, true)
            let state1 = this.props.location.state;
            let sasToken = this.state.user.sas_token
            let userBLOBContainer = this.state.user.blob_container
            let flightID = state1.flightId
            let plotArea = 0;
            const flightCoords = JSON.parse(flightDetails.flight.plan_boundary)
            if (flightCoords && flightCoords.length > 2) {
                let plotAreaData = L.GeometryUtil.geodesicArea(await flightCoords.map((f => ({ lat: f[0], lng: f[1] }))))
                plotArea = (Number(plotAreaData) / 4046.85642).toFixed(4)
            } else if (state1.areaCoveredMethod == 'plot') {
                plotArea = state1.areaCovered
            }
            await this.getReportData(flightID);
            let videoLink = await checkURLExist(`${AppConstants.BLOB_URL}/${userBLOBContainer}/DroneStreams/${flightID}/.mp4?${sasToken}`) ?
                `${AppConstants.BLOB_URL}/${userBLOBContainer}/DroneStreams/${flightID}/.mp4?${sasToken}`
                : `${AppConstants.BLOB_URL}/${userBLOBContainer}/DroneStreams/${flightID}/${state1.streamKey}.mp4?${sasToken}`
            this.setState((state) => ({
                ...state,
                fenceBoundary: flightDetails.flight.fence_boundary,
                planBoundary: flightDetails.flight.plan_boundary,
                flightId: state1.flightId,
                streamKey: state1.streamKey,
                planName: state1.planName,
                videoLink: videoLink,
                projectName: state1.projectName,
                date: state1.date,
                startTime: state1.startTime,
                endTime: state1.end_time,
                areaCovered: state1.areaCovered,
                areaCoveredMethod: state1.areaCoveredMethod,
                distanceCovered: state1.distanceCovered,
                droneId: state1.droneId,
                firmwareVersion: state1.firmwareVersion,
                greenBuildNumber: state1.greenBuildNumber,
                droneName: state1.droneName,
                droneUIN: state1.droneUIN,
                software_edition: state1.software_edition,
                plotArea: plotArea,
            }))
            this.getLogs()
            API.log(state1.flightId, this.state.key1, 0, 0).then(async (data) => {
                let count = data?.logs?.[0]?.totalCount?.count || 0
                var totalAlt = parseFloat(0);
                let totalSpeed = 0;
                let altData = [];
                let speedData = [];
                let batData = [];
                let areaAltitudeGraphData = []
                let arealiquidGraphData = []
                let areaFlowRateGraphData = []
                let areaSpeedGraphData = []
                let teleData = []
                for (let element of data.logs) {
                    if (element.key == 'AS_TELE_DATA') {
                        let td = JSON.parse(element.data);
                        let t = new Date(element.timestamp).getTime() / 1000;
                        let alt1 = parseFloat(td.alt);
                        let alt = alt1
                        let vel = parseFloat(td.vel);
                        let bat = await this.convertBatteryVal(parseFloat(td.bat))
                        if (td.lat && td.lon) teleData.push({ lat: td.lat, lng: td.lon })
                        altData.push({ value: alt, time: t })
                        speedData.push({ value: vel, time: t })
                        batData.push({ value: bat, time: t })
                        if (td.alt && td.sprayedArea) areaAltitudeGraphData.push({ yAxis: td.alt, xAxis: td.sprayedArea })
                        if (td.liquidConsumed && td.sprayedArea) arealiquidGraphData.push({ yAxis: td.liquidConsumed, xAxis: td.sprayedArea })
                        if (td.flowRate && td.sprayedArea) areaFlowRateGraphData.push({ yAxis: td.flowRate, xAxis: td.sprayedArea })
                        if (td.vel && td.sprayedArea) areaSpeedGraphData.push({ yAxis: td.vel, xAxis: td.sprayedArea })
                        totalAlt += alt;
                        totalSpeed += vel;
                    }
                }
                let avgAlt = parseFloat(totalAlt / count);
                let avgSpeed = parseFloat(totalSpeed / count);
                this.setState({
                    teleData: teleData,
                    altData: altData,
                    speedData: speedData,
                    batData: batData,
                    avgAlt: avgAlt,
                    avgSpeed: avgSpeed,
                    areaAltitudeGraphData: areaAltitudeGraphData,
                    arealiquidGraphData: arealiquidGraphData,
                    areaFlowRateGraphData: areaFlowRateGraphData,
                    areaSpeedGraphData: areaSpeedGraphData,
                    logsLoad: false
                })
            }, (err) => {
                this.setState({ loader: false })
                console.log(err)
            })
        } catch (e) {
            this.setState({ loader: false })
            console.log(e)
        }
    }

    getLogs = () => {
        this.setState({ tableLoader: true }, () => {
            let state = this.props.location.state
            let { key2, pageNumber, pageSize, orderBy, orderType } = this.state.filter;
            API.log(state.flightId, key2, pageSize, (pageNumber - 1) * pageSize, orderBy, orderType).then((data) => {
                let flightLogData = []
                for (let element of data.logs) {
                    if (element.key == 'AS_FLIGHT_LOG') {
                        let temp = JSON.parse(element.data)
                        temp.time = element.timestamp
                        temp.key = element.key
                        flightLogData.push(temp)
                    }
                }
                this.setState({
                    flightLogData: flightLogData,
                    totalCount: data.logs[0].totalCount.count || 0,
                    loader: false,
                    tableLoader: false
                })
            }).catch(err => {
                this.setState({
                    loader: false,
                    activityPopup: {
                        open: true,
                        item: "ERROR",
                        icon: "ERROR",
                        msg: err,
                    }
                })
            })
        })
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        document.title = "RMP - Flight Details";
        getActiveTeam().then(activeTeam => {
            this.setState({ activeTeam }, () => {
                if (this.props.states) {
                    let { user, type } = this.props.states;
                    this.setState((state) => ({
                        ...state,
                        user: user,
                        type,
                        loaderComponent: false,
                    }), () => {
                        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
                        this.callAfterUser()
                    })
                } else {
                    API.getUserType()
                        .then(([blah, type, user]) => {
                            this.setState((state) => ({
                                ...state,
                                user: user,
                                type,
                                loaderComponent: false,
                            }), () => {
                                addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
                                this.callAfterUser();
                            });
                        })
                        .catch((e) => {
                            window.location.replace("/login");
                        })

                }
            })
        })
    }

    render() {
        let { user, type, sidebarMode } = this.state;
        const style = {
            width: '446px',
            height: '334px'
        }
        return (
            <div className="wrapper">
                <div className="right-content-new"  >
                    <div className="top-bar-new">
                        <div className="top-bar-text-new">
                            <BreadCrumbs
                                data={[
                                    {
                                        displayName: "AeroGCS",
                                        pathname: "/",
                                        state: {
                                            user
                                        }
                                    },
                                    {
                                        displayName: "Flights",
                                        pathname: this?.props?.location?.state?.isOwner || this.state?.activeTeam?.isOwner ? "/aerogcsenterprise/flights" : "/drone_management/drone_details",
                                        state: {
                                            user,
                                            drone: this?.props?.location?.state?.drone || ''
                                        }
                                    },
                                    {
                                        displayName: 'Flight Details',
                                    }
                                ]} />
                        </div>
                    </div>
                    <div className="main-content-new" style={{ paddingTop: "20px" }}>
                        {this.state.loader ?
                            <LoaderComponent />
                            : <>
                                {this?.props?.location?.state?.flightId
                                    && this.state.pipWindowOpen && <EnterprisePip
                                        flightId={this?.props?.location?.state?.flightId}
                                        Title={'Flight Replay'}
                                        height='200px'
                                        width='300px'
                                        dragWindow={false}
                                        user={this.state.user}
                                        onClickAction={(e) => {
                                            this.props.history.push("/drone_control_center/fly_view", { flight: this?.props?.location?.state?.flightId, user: this.state.user, replyData: e })
                                        }}
                                        onCloseAction={() => { this.setState({ pipWindowOpen: !this.state.pipWindowOpen }) }}
                                    />}
                                <div className={"container"} style={{ padding: 0, maxWidth: "90%", background: 'none' }}>
                                    <FlightDetailsBanner
                                        user={user}
                                        type={type}
                                        flight={this.state.flightId}
                                        planName={this.state.planName}
                                        projectName={this.state.projectName}
                                        date={this.state.date}
                                        flightID={this.state.flightId}
                                        startTime={this.state.startTime}
                                        endTime={this.state.endTime || this.props.location.state.endTime}
                                        avgAltitude={this.state.avgAlt}
                                        avgSpeed={this.state.avgSpeed}
                                        areaCovered={this.state.areaCovered}
                                        areaCoveredMethod={this.state.areaCoveredMethod}
                                        distanceCovered={this.state.distanceCovered}
                                        droneId={this.state.droneId}
                                        firmwareVersion={this.state.firmwareVersion}
                                        greenBuildNumber={this.state.greenBuildNumber}
                                        droneName={this.state.droneName}
                                        droneUIN={this.state.droneUIN}
                                        software_edition={this.state.software_edition}
                                        plotArea={this.state.plotArea}
                                        reportData={this.state.reportData}
                                        requestId={this.state.requestId}
                                        onReplayFlight={() => { this.props.history.push("/drone_control_center/fly_view", { flight: this.state.flightId, user: user, replyData: 0 }) }}
                                    />
                                    <div className="cards_tray">
                                        <div style={{ textAlign: 'left', width: "49.5%" }}>
                                            <div className="media_title" style={{ width: "49.5%" }}>Routes</div>
                                            <div style={{ width: "100%", height: "400px" }}>
                                                {this.state.teleData.length == 0 ? <LoaderComponent />
                                                    : <Map
                                                        planBoundary={this.state.planBoundary}
                                                        fenceBoundary={this.state.fenceBoundary}
                                                        trailLogs={this.state.teleData}
                                                    />}
                                            </div>
                                        </div>
                                        <div style={{ position: "relative", width: "49.5%", textAlign: 'left' }}>
                                            <div className="media_title">Video/Images</div>
                                            {this.state.videoLink.length > 0 ?
                                                <VideoPlayer
                                                    url={this.state.videoLink}
                                                    playerHeight={'400px'}
                                                    style={style}
                                                /> : <div></div>}
                                        </div>
                                    </div>

                                    <div className="cardstray1" style={{ margin: '0px', padding: '20px 0px 20px 0px', gridGap: '20px' }}>
                                        {this.state.areaFlowRateGraphData.length > 0 && <CustomGraph
                                            style={{ height: "400px", margin: '0px' }}
                                            title={'Flow Rate (L/min)'}
                                            isPriceFromat={true}
                                            tickLength={7}
                                            lineColor={'#238E28'}
                                            topColor={'rgba(35, 142, 40, 0.4)'}
                                            bottomColor={'rgba(35, 142, 40, 0)'}
                                            data={this.state.areaFlowRateGraphData}
                                            loading={this.state.logsLoad}
                                            pointMarkersRadius={'0'}
                                            pointMarkersVisible={false}
                                            lineType={1}
                                            lineWidth={2}
                                            xAxisLable={'Sprayed Area (Acr)'}
                                        />}

                                        {this.state.arealiquidGraphData.length > 0 && <CustomGraph
                                            style={{ height: "400px", margin: '0px' }}
                                            title={'Liquid Consumed (ml)'}
                                            tickLength={7}
                                            lineColor={'#238E28'}
                                            topColor={'rgba(35, 142, 40, 0.4)'}
                                            bottomColor={'rgba(35, 142, 40, 0)'}
                                            data={this.state.arealiquidGraphData}
                                            loading={this.state.logsLoad}
                                            pointMarkersRadius={'0'}
                                            isPriceFromat={true}
                                            pointMarkersVisible={false}
                                            lineType={0}
                                            lineWidth={2}
                                            xAxisLable={'Sprayed Area (Acr)'}
                                        />}

                                        {this.state.areaAltitudeGraphData.length > 0 && <CustomGraph
                                            style={{ height: "400px", margin: '0px' }}
                                            title={'Altitude (m)'}
                                            tickLength={7}
                                            lineColor={'#238E28'}
                                            topColor={'rgba(35, 142, 40, 0.4)'}
                                            bottomColor={'rgba(35, 142, 40, 0)'}
                                            data={this.state.areaAltitudeGraphData}
                                            loading={this.state.logsLoad}
                                            pointMarkersRadius={'0'}
                                            isPriceFromat={true}
                                            pointMarkersVisible={false}
                                            lineType={0}
                                            lineWidth={2}
                                            xAxisLable={'Sprayed Area (Acr)'}
                                        />}

                                        {this.state.areaSpeedGraphData.length > 0 && <CustomGraph
                                            style={{ height: "400px", margin: '0px' }}
                                            title={'Speed (m/s)'}
                                            tickLength={7}
                                            lineColor={'#238E28'}
                                            topColor={'rgba(35, 142, 40, 0.4)'}
                                            bottomColor={'rgba(35, 142, 40, 0)'}
                                            data={this.state.areaSpeedGraphData}
                                            loading={this.state.logsLoad}
                                            pointMarkersRadius={'0'}
                                            isPriceFromat={true}
                                            pointMarkersVisible={false}
                                            lineType={0}
                                            lineWidth={2}
                                            xAxisLable={'Sprayed Area (Acr)'}
                                        />}
                                        {this.state.areaAltitudeGraphData.length == 0 && <InteractiveGraph
                                            showZoom
                                            style={{ height: "400px", margin: '0px' }}
                                            title={'Altitude (m)'}
                                            tickLength={7}
                                            isPriceFromat={true}
                                            dateTimeFormat={'h:MM:ss TT'}
                                            displayCurrentTime={true}
                                            lineColor={'#3988F9'}
                                            topColor={'rgba(57, 136, 249, 0.4)'}
                                            bottomColor={'rgba(57, 136, 249, 0)'}
                                            data={this.state.altData}
                                            loading={this.state.logsLoad}
                                            pointMarkersRadius={'0'}
                                            pointMarkersVisible={false}
                                            lineType={0}
                                            lineWidth={2}
                                            xAxisLable={'Time'}
                                        />}
                                        {this.state.areaSpeedGraphData.length == 0 && <InteractiveGraph
                                            showZoom
                                            style={{ height: "400px", margin: '0px' }}
                                            title={'Speed (m/s)'}
                                            tickLength={7}
                                            isPriceFromat={true}
                                            dateTimeFormat={'h:MM:ss TT'}
                                            displayCurrentTime={true}
                                            lineColor={'#3988F9'}
                                            topColor={'rgba(57, 136, 249, 0.4)'}
                                            bottomColor={'rgba(57, 136, 249, 0)'}
                                            data={this.state.speedData}
                                            loading={this.state.logsLoad}
                                            pointMarkersRadius={'0'}
                                            pointMarkersVisible={false}
                                            lineType={0}
                                            lineWidth={2}
                                            xAxisLable={'Time'}
                                        />}
                                        <InteractiveGraph
                                            showZoom
                                            style={{ height: "400px", margin: '0px' }}
                                            title={'Battery (v)'}
                                            tickLength={7}
                                            isPriceFromat={true}
                                            dateTimeFormat={'h:MM:ss TT'}
                                            displayCurrentTime={true}
                                            lineColor={'#3988F9'}
                                            topColor={'rgba(57, 136, 249, 0.4)'}
                                            bottomColor={'rgba(57, 136, 249, 0)'}
                                            data={this.state.batData}
                                            loading={this.state.logsLoad}
                                            pointMarkersRadius={'0'}
                                            pointMarkersVisible={false}
                                            lineType={0}
                                            lineWidth={2}
                                            xAxisLable={'Time'}
                                        />
                                        <div style={{ height: this.state.totalCount > 15 ? '365px' : '400px', background: "white" }}>
                                            <TableView
                                                isLoading={this.state.tableLoader}
                                                columns={[
                                                    { Header: 'Time', accessor: 'timestamp', width: '25%', isSortable: true },
                                                    { Header: 'Type', accessor: 'type', width: '15%', },
                                                    { Header: 'Details', accessor: 'details', width: '60%', },
                                                ]}
                                                data={this.state.flightLogData.filter((el, i) => {
                                                    if (el.key == 'AS_FLIGHT_LOG') {
                                                        return el
                                                    }
                                                }).map(logs_info => {
                                                    return {
                                                        timestamp: `${new Date(logs_info.time).toLocaleString().split(',')[1]}`,
                                                        type: logs_info.type,
                                                        details: logs_info.message,
                                                        // rowColor: logs_info.type == "ERROR" || logs_info.type == "ERRO" || logs_info.type == "CRIT" || logs_info.type == "EMRG" ? "red"
                                                        //     : logs_info.type == "WARN" || logs_info.type == "ALRT" || logs_info.type == "DBUG" ? "yellow"
                                                        //         : ''
                                                    }
                                                })}
                                                user={user}
                                                count={this.state.totalCount}
                                                onStateChange={(changedState) => {
                                                    this.setState({
                                                        filter: {
                                                            ...this.state.filter,
                                                            pageNumber: changedState.pageNumber,
                                                            orderBy: changedState.orderBy,
                                                            orderType: changedState.orderType,
                                                        }
                                                    }, () => { this.getLogs() })
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div >
        );
    }
}

import React, { useEffect, useState } from 'react'
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal'
import * as API from "../../api"
import AppConstants from '../../AppConstants'
import ButtonWithIcon from '../../ReusableComponents/ButtonWithIcon'
import Loader from "../../../src/icons/loader.svg"
import { BUY_MORE_DRONES, CANCEL_DRONE_ADDON, CANCEL_SUBSCRIPTION } from '../../Profile/ProfileSettings'
import { Popup } from '../../ReusableComponents/reusableFunctions'
import { useHistory } from 'react-router-dom'
import AeroGCSLogo from "../../../src/icons/AeroGCSEnterprise.svg"
const AdjustQuantityPopup = (props) => {
    const history = useHistory()
    const [userDroneQuantity, setUserDroneQuantity] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [loading, setLoading] = useState(true)
    const [note, setNote] = useState({ type: "", msg: "" })
    const [balance, setBalance] = useState(0)

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }

    const displayRazorpay = async (order, options) => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if (!res) {
            Popup.alert("ERROR", 'Razropay failed to load!!')
            return
        }
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        paymentObject.on('payment.failed', function (response) {
            console.log("Error", response)
        });
    }

    const handleCheckout = async () => {
        Popup.alert("WAIT", "Redirecting to payment, please wait...")
        try {
            const data = await API.oneTimePayment({ ...props.dronePlan, id: props.dronePlan.plan_id, qty: (quantity - userDroneQuantity) })
            if (data.success) {
                Popup.close()
                //code to open pay now page
                displayRazorpay(data.order, {
                    key: process.env.REACT_APP_RAZORPAY_KEY, // Replace with your Razorpay key_id
                    amount: data.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    currency: 'INR',
                    name: 'AeroGCS',
                    image: AeroGCSLogo,
                    // description: 'Test Transaction',
                    order_id: data.order.id, // This is the order_id created in the backend
                    handler: async (response) => { //we can use handler or directly callback url here
                        //now we will proceed the payment and check in backend to verify payment
                        const res = await API.verifyRazorPayPayment(response)
                        if (res.success) {
                            history.push(`/payment/success?reference=${res.payment_id}`, {
                                message: "Congratulations, drones successfully added to your account"
                            })
                        } else {
                            history.push(`/payment/failed?reference=${res.payment_id}`, {
                                message: "Something went wrong while processing drones."
                            })
                        }
                    },
                    readonly: { email: true },
                    prefill: {
                        email: props.user.username,
                    },
                    readonly: { email: true },
                    notes: {
                        payment_type: "additional_drone_payment",
                        db_subscription_plan_id: props.dronePlan.plan_id,
                        quantity: Math.abs(quantity - userDroneQuantity),
                        service: AppConstants.SERVICES.AEROGCS
                    },
                    theme: {
                        "color": "#3399cc"
                    }
                })
            } else if (data.subData) {
                Popup.alert("COMPLETE", data.message || 'Subcription updated successfully.')
                props.onRequestClose()
            } else {
                Popup.alert("ERROR", data.message)
            }
        } catch (e) {
            Popup.alert("ERROR", e.message)
        }
    }
    useEffect(() => {
        API.getUserDroneLimits(AppConstants.SERVICES.DRONE_CONTROL_CENTER).then(res => {
            setQuantity(res.addOnDronesLimits)
            setUserDroneQuantity(res.addOnDronesLimits)
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        if (props.pendingPayments?.length > 0)
            setNote({ type: "Attention", msg: "Payments are due,you cannot modify quantity." })
        else
            setNote({ type: "", msg: "" })
    }, [props.pendingPayments?.length])

    useEffect(() => {
        if (!props.dronePlan) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [props.dronePlan])

    return (
        <>
            <AnimatedModal
                isOpen={props.show}
                onRequestClose={props.onRequestClose}
                height={"auto"}
            >
                {/* <div>
                    <button onClick={() => setQuantity(prev => Math.max(prev - 1, 0))}>-</button>
                    <div>{quantity}</div>
                    <button onClick={() => setQuantity(prev => prev + 1)}>+</button>
                    <button onClick={handleDroneSubPayment}>Apply</button>
                </div> */}


                <div style={{
                    boxShadow: "0px 0px 0px 1px rgb(235,238,241)",
                    background: "white",
                    height: "",
                    width: "700px",
                    marginTop: "",
                    overflow: "hidden",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div style={{ backgroundColor: "#EAEAEA", height: "40px", display: "flex", justifyContent: "center", alignItems: "center", font: "15px", color: "#3c3c3c", fontWeight: "500" }}> AEROGCS ADD ON DRONES </div>

                    {loading ?
                        <div style={{ height: "100%", minWidth: "200px", width: "100%", display: "flex", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={Loader} style={{ height: "100%", width: "200px", margin: "auto", paddingLeft: "50px" }} />
                        </div> :
                        <>
                            <div style={{ padding: "20px 100px 5px 100px", borderBottom: "", textAlign: "left", position: "relative", display: "flex", flexDirection: "column", gap: "10px", height: "100%" }}>

                                <table>
                                    <col style={{ width: '50%' }} />
                                    <col style={{ width: '50%' }} />
                                    <tr style={{ borderTop: "1px solid lightgray", borderBottom: "1px solid lightgray" }} >
                                        <th style={{ color: "rgb(65,69,82)", fontWeight: "700", padding: "10px 0px", textAlign: "left" }}>Product</th>
                                        <th style={{ color: "rgb(65,69,82)", fontWeight: "700", padding: "10px 65px", textAlign: "right" }}>Qty</th>
                                    </tr>
                                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                                        <td style={{ color: "rgb(65,69,82)", fontWeight: "500", padding: "10px 0px", textAlign: "left" }}><div>
                                            <div style={{ fontSize: "13px", fontWeight: "500" }}>{(!loading && props.dronePlan.plan_name) || "--"}</div>
                                            <div style={{ fontSize: "11px", fontWeight: "500", color: "#2989cf" }}>{(!loading && props.dronePlan.plan_type) || "--"}</div>
                                        </div></td>
                                        <td style={{ color: "rgb(65,69,82)", fontWeight: "500", padding: "10px 0px", textAlign: "right", display: "flex", justifyContent: "right" }}>
                                            <div style={{ marginLeft: "", fontWeight: "400", fontSize: "14px", display: "flex", alignItems: "center", columnGap: "5px" }}>
                                                <ButtonWithIcon isDisabled={props.pendingPayments?.length > 0} text={"-"} onClick={() => setQuantity(prev => Math.max(prev - 1, 0))} />
                                                <div style={{ transition: "0.5s all", width: "45px", padding: "5px", borderRadius: "5px", outline: "none", border: "1px solid lightgray", textAlign: "center" }}>{quantity}</div>
                                                <ButtonWithIcon isDisabled={props.pendingPayments?.length > 0} text={"+"} onClick={() => setQuantity(prev => prev + 1)} />
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                                {note.type && <div style={{ fontSize: "12px", color: "red" }}>
                                    <span style={{ fontWeight: "500", color: note.type === "Attention" ? "#f44336" : "#2989cf", }}>{note.type}: </span>
                                    <span style={{ color: "#687385" }}>{note.msg}</span>
                                </div>}
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: " 20px 100px" }}>
                                <ButtonWithIcon text={"Back"} height={"30px"} padding={"6px 20px 10px"} onClick={props.onRequestClose} />
                                <ButtonWithIcon isBtnActive height={"30px"} padding={"6px 20px 10px"} isDisabled={props.pendingPayments?.length > 0 || ((Number(quantity) - Number(userDroneQuantity)) === 0) || quantity <= 0} text="Apply" onClick={handleCheckout} />
                            </div>
                        </>
                    }
                </div>
            </AnimatedModal>
        </>
    )
}

export default AdjustQuantityPopup
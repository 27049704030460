import React from "react"
import { Link } from 'react-router-dom'

const PaymentFailed = (props) => {
    return <>
        <div style={{ width: "100%", height: '100vh', display: 'flex', justifyContent: "center", alignItems: "center" }}>
            <div className="page" style={{ padding: "40px", height: "fit-content", position: "relative", width: "745px" }}>
                <div> We regret to inform you that there was an issue with recent payment transaction on {new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}. Unfortunately, the payment could not be successfully processed.</div>

                <div style={{ marginTop: "5px", fontWeight: "500" }}>Payment Details:</div>

                <div><span style={{ fontWeight: "500" }}>Payment id:</span> {props?.location?.state?.payment_id || "-"}</div>
                <div><span style={{ fontWeight: "500" }}>Payment Amount:</span> {props?.location?.state?.payment_id || "-"}</div>
                <div style={{ position: "absolute", bottom: "10px", left: "50%" }}>
                    <Link to={{
                        pathname: "/profile-settings",
                        state: { view: 2 }
                    }} style={{
                        color: "#2989cf"
                    }}>

                        Ok
                    </Link>
                </div>
            </div>
        </div>
    </>
}
export default PaymentFailed
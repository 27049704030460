import React, { Component } from 'react';
import * as API from '../../api.js';
import Loader from '../../icons/loader.svg';
import Avatar from 'react-avatar';
import validator from 'validator';
import { AnimatedModal } from '../../ReusableComponents/AnimatedModal.js';
import { connect } from "react-redux";
import { updateProfileDetails } from '../../redux/index.js';
import { addUserAnalytic, checkSubscriptionAndStorage, updateOutTime } from '../../ReusableComponents/reusableFunctions.js';
import { ActivityPopup } from '../../ReusableComponents/ActivityPopup.js';
import { getActiveTeam } from '../../Teams/index.js';
import NoTeamMember from "../../assets/Icons/NoTeamMember.png";
import AppConstants from '../../AppConstants.js';
import dateFormat from 'dateformat';
import teamsGreenTick from "../../icons/teamsGreenTick.svg"
import teamsDeleteIcon from "../../icons/teamsDeleteIcon.svg"
import teamsInvite from "../../icons/teamsInvite.svg"
import Viewbuttons from '../../ReusableComponents/SearchHeader/ViewButtons.js';
import Button from '../../ReusableComponents/Button/Button.js';
import TableView from '../../ReusableComponents/TableView/TableView.js';
import closeBtn from "../../icons/close.png"
import SubscriptionExpireModal from '../../subscription/Reusable/SubscriptionExpireModal.js';

class AeroGCSEnterpriseTeam extends Component {
    state = {
        inviteMail: "",
        inviteModal: false,
        user: {},
        type: "",
        loading: true,
        showBackIcon: true,
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        validationDisplay: "none",
        validationMessage: "",
        teamsList: [],
        list: [],
        activeTeam: "",
        teamUsers: [],
        isUserTeamOwner: false,
        deleteUserName: "",
        teamLoading: true,
        subscriptionPlan: '',
        subcheck: false,
        itemName: "",
        showProcessPopup: false,
        processPopupIcon: "",
        processAction: "",
        processMessage: "",
        outId: "",
        listView: false,
        showSubscriptionExpirePopup: false
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    closeActivityPopup = () => {
        this.setState((state) => ({
            ...state,
            showProcessPopup: false,
            processMessage: null
        }))
    }

    handleInputChange = (event, state) => {
        this.setState({
            [state]: event.target.value
        })
    }

    getTeamUsers = () => {
        const { activeTeam } = this.state;
        API.getTeamUsers(activeTeam.teamId)
            .then((response) => {
                this.setState((state) => ({
                    ...state,
                    teamUsers: response.teamMembers.sort((a, b) => a.userFullName < b.userFullName ? -1 : (a.userFullName > b.userFullName ? 1 : 0)),
                    loading: false,
                    teamLoading: false,
                }))
            })
            .catch((error) => {
                console.log("fetching with teams Error", error);
            })
    };

    handleChange = (team) => {
        this.setState({
            activeTeam: team,
            teamLoading: true
        }, () => {
            this.getTeamUsers();
        })
    }

    deleteUserFromTeam = (username, name) => {
        this.setState((prevState) => ({
            ...prevState,
            itemName: "Team member",
            processPopupIcon: "WARNING",
            processAction: "DELETE",
            processMessage: "Are you sure you want to delete this team member?",
            showProcessPopup: true,
            deleteUser: username,
            deleteUserName: name ? name : username
        }))
    }

    deleteUserConfirm = () => {
        const { activeTeam, deleteUser } = this.state
        this.setState((prevState) => ({
            ...prevState,
            itemName: "Team member",
            processPopupIcon: "WAIT",
            processAction: "DELETE",
            processMessage: "Removing Member, Please Wait...",
            showProcessPopup: true,
            deleteUser: "",
            teamLoading: true,
        }))
        API.deleteUserFromTeam(activeTeam.teamId, deleteUser).then((response) => {
            this.setState((state) => ({
                itemName: "Team member",
                processPopupIcon: "COMPLETE",
                processAction: "DELETE",
                processMessage: `Removed '${this.state.deleteUserName}' from your team.`,
                showProcessPopup: true,
            }), () => {
                this.getTeamUsers()
            })
        }, (e) => {
            this.setState((state) => ({
                itemName: "Team member",
                processPopupIcon: "ERROR",
                processAction: "DELETE",
                processMessage: e,
                showProcessPopup: true,
            }))
        })
    }

    sendInvite = () => {
        console.log("callllllll")
        const { inviteMail, user, activeTeam } = this.state
        if (inviteMail === user.username) {
            this.setState((prevState) => ({
                ...prevState,
                validationDisplay: "block",
                validationColor: "red",
                validationMessage: "You can not send invitation to yourself."
            }))
        } else if (inviteMail === "") {
            this.setState((prevState) => ({
                ...prevState,
                validationDisplay: "block",
                validationColor: "red",
                validationMessage: "Please fill email to send invitation."
            }))
        } else if (validator.isEmail(inviteMail)) {
            API.inviteUserToTeam(inviteMail, activeTeam.teamId)
                .then((response) => {
                    if (response.status === 201) {
                        this.getTeamUsers()
                        this.setState((prevState) => ({
                            ...prevState,
                            inviteModal: false,
                            validationDisplay: "none",
                            validationMessage: "",
                            inviteMail: "",
                            teamLoading: "true"
                        }))
                    } else {
                        response.json().then((data) => {
                            this.setState((prevState) => ({
                                ...prevState,
                                validationDisplay: "block",
                                validationMessage: data.message,
                                validationColor: "red"
                            }))
                        })
                    }
                })
                .catch((error) => {
                    this.setState((prevState) => ({
                        ...prevState,
                        validationDisplay: "block",
                        validationMessage: error.Error,
                        validationColor: "red"
                    }))
                })

        } else {
            this.setState((prevState) => ({
                ...prevState,
                validationDisplay: "block",
                validationColor: "red",
                validationMessage: "Enter valid email address."

            }))
        }
    };

    getTeamsList = () => {
        API.getTeamsList()
            .then((teams) => {
                this.setState((state) => ({
                    ...state,
                    activeTeam: teams.teams.find(team => team.isOwner),
                    teamsList: teams.teams
                }), () => {
                    this.getTeamUsers()
                })
            })
            .catch((error) => {
                console.log(error);
            })
    };

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        document.title = "Manage Teams";
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
            if (data.error || data.storageExceed || data.subscriptionExpire || !data.isValidStoragePlan || data.isExpired) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
            else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
        })
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type,
            }), () => { this.getTeamsList(); })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type,
                }), () => { this.getTeamsList(); })
            }, (e) => { window.location.replace("/login"); }
            )
        }
    }

    render() {
        let { user, type, inviteModal, validationMessage, validationColor, teamUsers } = this.state
        return (
            <>
                {user.username ?
                    <div className="wrapper">
                        <div className="right-content-new"
                            onClick={
                                () => {
                                    this.setState((state) => ({ ...state, sidebarMode: 'shrunk' }))
                                }
                            }
                        >
                            {
                                this.state.showSubscriptionExpirePopup ?
                                    <SubscriptionExpireModal
                                        data={this.state.showSubscriptionExpirePopup}
                                    /> : <></>
                            }
                            <div className="top-bar-new">
                                <div className="top-bar-text-new">
                                    Teams
                                </div>
                            </div>
                            {this.state.loading ?
                                <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                                    <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                                </div> :
                                <div className="main-content-new team" style={{ background: 'none', padding: "2vh 6%", }} onClick={
                                    () => {
                                        this.setState((state) => ({
                                            ...state, sidebarMode: 'shrunk'
                                        }))
                                    }
                                }>
                                    <div style={{ width: "100%", display: "flex", justifyContent: "space-between", textAlign: "center", marginBottom: '20px' }}>
                                        <div className="teamName total-team-members">
                                            {`Total Members(${teamUsers.length})`}
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            gap: "20px"
                                        }}>
                                            <Button
                                                className={"new-btn-lg"}
                                                text={"Invite"}
                                                isBtnActive
                                                isDisabled={!this.state.activeTeam.isOwner}
                                                onClick={() => {
                                                    this.setState({
                                                        inviteModal: true,
                                                        inviteMail: "",
                                                        validationDisplay: "none",
                                                        validationColor: "red",
                                                        validationMessage: ""
                                                    })
                                                }}
                                            />
                                            <Viewbuttons
                                                globalState={
                                                    {
                                                        selectView: this.state.listView ? 'list' : "grid"
                                                    }
                                                }
                                                handleStateChange={(selectView, view) => {
                                                    this.setState({
                                                        listView: view === 'list' ? true : false
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>


                                    <ActivityPopup
                                        item={this.state.itemName}
                                        open={this.state.showProcessPopup}
                                        icon={this.state.processPopupIcon}
                                        action={this.state.processAction}
                                        msg={this.state.processMessage}
                                        close={() => this.closeActivityPopup()}
                                        onClickOk={() => this.deleteUserConfirm()}
                                    />
                                    {this.state.teamLoading ?
                                        <div style={{ height: "70vh", display: "flex", textAlign: "center" }}>
                                            <img src={Loader} style={{ height: "30vh", margin: "auto" }} />
                                        </div> :
                                        <>
                                            {teamUsers.length > 0 ?
                                                this.state.listView ?
                                                    <div className={'teams-table-height'} >
                                                        <TableView
                                                            count={0}
                                                            showSrNo={true}
                                                            onStateChange={(state) => { }}
                                                            headingLeft={""}
                                                            onClickRow={(row) => {
                                                                if (row.isActive) this.props.history.push("/teams/givenAccess", { 'teamUser': { ...row, status: row.isActive ? "Active" : "InActive" }, user: user })
                                                            }}
                                                            activities={{
                                                                disableDelete: !this.state.activeTeam.isOwner,
                                                                onClickDelete: (teamUser) => { this.deleteUserFromTeam(teamUser.username, teamUser.userFullName) }
                                                            }
                                                            }
                                                            columns={[
                                                                { Header: "Name", accessor: "userFullName", width: '35%', },
                                                                { Header: "Date", accessor: "created_datetime", width: '25%', isDate: true,   },
                                                                { Header: "Status", accessor: "status", width: '25%', },
                                                            ]}
                                                            data={teamUsers?.map((user) => {
                                                                return ({
                                                                    ...user,
                                                                    status: <span style={{ color: user.status === "Active" ? "#238E28" : "#DAB90B" }}>{user.status}</span>,
                                                                    isActive: user.status == "Active"
                                                                })
                                                            }) || []}
                                                        /> </div> :
                                                    <div style={{
                                                        position: "relative",
                                                        height: "calc(100% - 38px)",
                                                        overflow: "auto",
                                                        paddingBottom: '20px'
                                                    }}>
                                                        <div
                                                            className='teams-grid'
                                                        >
                                                            {
                                                                teamUsers.map(teamUser => {
                                                                    return <div className='teams-card'
                                                                        style={{ cursor: teamUser.status == "Active" ? "pointer" : "not-allowed" }}
                                                                        onClick={() => {
                                                                            if (teamUser.status == "Active") this.props.history.push("/teams/givenAccess", { 'teamUser': teamUser, user })
                                                                        }}
                                                                    >
                                                                        {this.state.activeTeam.isOwner &&
                                                                            <div className='teams-delete-container'>
                                                                                <img src={teamsDeleteIcon} onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    this.deleteUserFromTeam(teamUser.username, teamUser.userFullName)
                                                                                }} />
                                                                            </div>
                                                                        }
                                                                        <div style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            flexDirection: 'column',
                                                                            alignItems: "center",
                                                                        }}>
                                                                            {teamUser.avatarUrl ?
                                                                                <img className='teams-avatar-1'
                                                                                    src={`${teamUser.avatarUrl}?${teamUser.sas_token}`}
                                                                                />
                                                                                : <Avatar
                                                                                    className='teams-avatar-2'
                                                                                    color={teamUser.status === "Active" ? AppConstants.PLANNER.STATUS.COMPLETED : teamUser.status === "Inactive" ? AppConstants.PLANNER.STATUS.TO_DO : AppConstants.PLANNER.STATUS.IN_PROGRESS}
                                                                                    size={"77px"}
                                                                                    round={true}
                                                                                    name={teamUser.userFullName}
                                                                                />
                                                                            }
                                                                            <div className='teams-member-name'
                                                                                title={teamUser.userFullName}
                                                                            >
                                                                                {teamUser.userFullName.length > 20 ? `${teamUser.userFullName.substring(0, 20)} ...` : teamUser.userFullName}
                                                                            </div>
                                                                            <div className='teams-date-created'
                                                                            >
                                                                                {teamUser.created_datetime ? dateFormat(teamUser.created_datetime, 'dd mmm yyyy') : '-'}
                                                                            </div>
                                                                            <div
                                                                                className='teams-status-container'

                                                                            >
                                                                                <img src={teamUser.status === "Active" ? teamsGreenTick : teamsInvite} alt='check' />
                                                                                <div
                                                                                    className="status"
                                                                                    style={{
                                                                                        color: teamUser.status === "Active" ? "#238E28" : "#DAB90B"
                                                                                    }}
                                                                                >
                                                                                    {teamUser.status}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                )}
                                                        </div>
                                                    </div>
                                                :
                                                <div className='empty-data-message' style={{ height: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div>
                                                        <img style={{ height: "250px", width: "225px" }} src={NoTeamMember} />
                                                        <div style={{ marginTop: "-20px" }}>This team has no members.</div>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    <AnimatedModal
                                        isOpen={inviteModal}
                                        onRequestClose={() => {
                                            this.setState((state) => ({
                                                ...state,
                                                inviteModal: false,
                                                inviteMail: "",
                                                validationDisplay: "none",
                                                validationColor: "red",
                                                validationMessage: ""
                                            }))
                                        }}
                                        height="294px"
                                        width="577px"
                                    >
                                        <form onSubmit={this.sendInvite} style={{
                                            padding: "28px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                height: "237px",
                                                width: "522px"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    width: "inherit",
                                                    color: "#081425",
                                                    fontSize: "22px",
                                                    fontWeight: 600,
                                                    fontFamily: "Lato"
                                                }}>
                                                    <div>
                                                        Invite User
                                                    </div>
                                                    <img src={closeBtn} alt='i ma close' style={{
                                                        width: "16px",
                                                        height: "16px",
                                                        cursor: "pointer"
                                                    }} onClick={() => {
                                                        this.setState({
                                                            inviteModal: false,
                                                            inviteMail: "",
                                                            validationDisplay: "none",
                                                            validationColor: "red",
                                                            validationMessage: ""
                                                        })
                                                    }} />
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "flex-start",
                                                    width: "inherit"
                                                }}>
                                                    <div style={{
                                                        textAlign: "left",
                                                        color: "#555C67",
                                                        fontWeight: 500,
                                                        marginBottom: "5px"
                                                    }}>
                                                        Email ID
                                                    </div>
                                                    <input
                                                        onInput={(event) => this.handleInputChange(event, "inviteMail")}
                                                        value={this.state.inviteMail}
                                                        name="username"
                                                        className="teams-text-input"
                                                        placeholder="Email"
                                                        autoFocus
                                                    />
                                                    <div style={{
                                                        textAlign: "left",
                                                        color: validationColor,
                                                        fontSize: "12px",
                                                        height: "20px"
                                                    }}
                                                    >
                                                        {validationMessage}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: "space-between",
                                                    width: "inherit"
                                                }}>
                                                    <Button
                                                        className={"new-btn-lg"}
                                                        text={"Cancel"}
                                                        onClick={() => {
                                                            this.setState({
                                                                inviteModal: false,
                                                                inviteMail: "",
                                                                validationDisplay: "none",
                                                                validationColor: "red",
                                                                validationMessage: ""
                                                            })
                                                        }}
                                                    />
                                                    <Button
                                                        className={"new-btn-lg"}
                                                        text={"Invite"}
                                                        type={"submit"}
                                                        isBtnActive={true}
                                                        onClick={() => {
                                                            this.sendInvite()
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </AnimatedModal>
                                </div>
                            }
                        </div>
                    </div > : <div />
                }
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfileDetails: (payload) => dispatch(updateProfileDetails(payload))
    };
};

const mapStateToProps = state => {
    return {
        isLoggedInUserOwner: state.profile.isLoggedInUserOwner,
        SubscriptionData: state.subscription.subscriptionData,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AeroGCSEnterpriseTeam);
import React, { Component } from "react";
import * as API from "./api.js";
import "./style.css";
import Map from "./MapComponent.js";
import VideoPlayer from "./VideoPlayer.js"
import allFeedsButton from './icons/allFeedsButton.png';
import max from './icons/liveFeedmax.png'
import { addUserAnalytic, checkSubscriptionAndStorage, updateOutTime } from './ReusableComponents/reusableFunctions'
import { getActiveTeam } from './Teams/index.js';
import SubscriptionExpireModal from "./subscription/Reusable/SubscriptionExpireModal.js";
import AppConstants from "./AppConstants.js";
import LoaderComponent from "./ReusableComponents/LoaderComponent.js";
import DroneWithBg from "./icons/DroneWithBg.svg"
import Select from "react-dropdown-select";
class DCCAllDrones extends Component {
  state = {
    user: {},
    type: "",
    loading: true,
    recentReplaySidebar: true,
    dronesLoading: true,
    title: "ALL",
    drones: {},
    mini: false,
    outId: "",
    showSubscriptionExpirePopup: false
  };

  changeDrone = (drone) => {
    if (drone) window.open("/drone_control_center/fly_view?drone=" + drone, '_blank');
    // console.log(event.target.value);
  }
  componentWillUnmount() {
    updateOutTime(this.state.outId)
    clearInterval(this.interval)
    clearInterval(this.checkStatusInterval)
  }

  componentWillMount() {
    document.title = "Drone Control Center - All Available Drones - AeroMegh";
    getActiveTeam().then(activeTeam => {
      this.setState({
        activeTeam
      }, () => {
        addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id }))
        checkSubscriptionAndStorage(AppConstants.SERVICES.AEROGCS).then(data => {
          if (data.error || data.storageExceed || data.subscriptionExpire || !data.isValidStoragePlan || data.isExpired) this.setState({ showSubscriptionExpirePopup: { ...data, user: this.props?.location?.state?.user } });
          else this.setState({ showSubscriptionExpirePopup: false, infoPopup: true });
        })

        if (this.props && this.props.location && this.props.location.state) {
          this.setState((prevState) => ({
            ...prevState,
            user: this.props.location.state.user,
            type: this.props.location.state.type,
            sidebarMode: this.props.location.state.sidebarMode,
          }), () => {
            this.getPageData();
            this.setStatusInterval();
          });
        } else {
          API.getUserType().then(([blah, type, user]) => {

            this.setState((state) => ({
              ...state,
              user,
              type,
              sidebarMode: "shrunk"
            }), () => {
              this.getPageData();
              this.setStatusInterval();
            })
          }, (e) => {
            window.location.replace("/login");
          })
        }
      })
    })

  }

  setStatusInterval = () => {
    this.checkStatusInterval = setInterval(() => {
      this.getPageData()
    }, 60000)
  }


  getPageData = () => {
    API.GetAllDronesStatus().then(async allDrones => {
      let allDronesObject = {};
      await allDrones.drones.forEach(drone => {
        allDronesObject[drone.drone_id] = {
          ...drone,
          droneName: drone.drone_name,
          streamURL: `${drone?.uri && drone?.stream_key ? `https://${drone.uri}/streams/${drone?.stream_key}.m3u8` : ''}`,
        }
      })
      this.setState({ dronesLoading: false, drones: allDronesObject, loading: false, })

    }).catch(error => {
      try {
        console.log(JSON.stringify(error));
      } catch (e) {
        console.log(error);
      }
    })
  }



  render() {
    let { user, type, sidebarMode, recentReplaySidebar } = this.state;
    let isDroneLive = Object.keys(this.state.drones).filter((drone) => { return this.state.drones[drone].online }).length > 0
    return (
      <div className="wrapper"
        style={{ overflowY: "hidden" }} >
        <div
          className="right-content-new"
          style={{ height: "100vh", overflowY: "auto", left: "0px", width: "100vw" }}
        >
          {/*  */}
          {
            this.state.showSubscriptionExpirePopup ?
              <SubscriptionExpireModal
                data={this.state.showSubscriptionExpirePopup}
              /> : <></>
          }
          <div className="top-bar-new">
            <div className="top-bar-text-new">Drone Control Center - All Available Drones</div>
          </div>
          {this.state.loading ? <LoaderComponent /> :
            <div className="main-content main-content-dcc" style={{ paddingTop: "1px", paddingLeft: "0px", paddingRight: "0px", display: "flex", }}>
              {this.state.activeTeam.isOwner ? <>
                <Map
                  className="dcc-all-drones-map"
                  drones={this.state.drones}
                  showIcons={true}
                  includePortsInRecenter={true}
                  recenterMap={true}
                  control={true}
                  markerClickCallback={(drone) => {
                    window.open("/drone_control_center/fly_view?drone=" + drone, '_blank');
                  }}
                />
                <div className="droneLiveCountNumber" style={{ backgroundColor: isDroneLive ? '#238E28' : '#868F9C' }}>{
                  Object.keys(this.state.drones).filter((drone) => { return this.state.drones[drone].online }).length}</div>
                <img src={DroneWithBg} className="droneLiveCount" />
                <div className="droneLiveSelect">
                  <Select
                    placeholder="Select Drone"
                    searchable={true}
                    noDataRenderer={() => {
                      return <span style={{ textAlign: 'center', padding: '5px 0px' }}>Drones not available</span>
                    }}
                    options={
                      Object.keys(this.state.drones).filter((drone) => {
                        if (this.state.drones[drone].online == true) {
                          return true
                        } else {
                          return false
                        }
                      }).map((drone, i) => ({
                        label: `${this.state.drones[drone].uin} (${this.state.drones[drone].drone_name})`,
                        value: drone
                      }))
                    }
                    className="custom_dropdown"
                    onChange={(e) => {
                      if (e.length > 0) {
                        this.changeDrone(e[0]?.value)
                      }
                    }
                    }
                  />
                </div>
                <div className="dcc-all-drones-live-feeds">
                  <div className="dcc-all-drones-live-feeds-header">
                    <span className="dcc-all-drones-live-feeds-header-text">
                      Live Feeds
                    </span>
                    <span className="dcc-all-drones-live-feeds-header-button">
                      {Object.keys(this.state.drones).length > 0 ?
                        <>
                          {/* {this.state.mini ? <img src={min}  onClick={() => { this.setState({ mini: false }) }} /> */}
                          {/* : */}
                          <img className="dcc-all-drones-live-feeds-header-button-img" style={{ cursor: isDroneLive ? undefined : "not-allowed", filter: isDroneLive ? '' : 'grayscale(100%)' }} src={max} onClick={() => { if (isDroneLive) this.setState({ mini: !this.state.mini }) }} />
                          {/* } */}
                        </> : <></>}
                      <img className="dcc-all-drones-live-feeds-header-button-img" style={{ cursor: isDroneLive ? undefined : "not-allowed", filter: isDroneLive ? '' : 'grayscale(100%)' }} src={allFeedsButton} onClick={() => { if (isDroneLive) window.open("/drone_control_center/all_feeds", '_blank'); }} />
                    </span>
                  </div>
                  <div className="dcc-all-drones-live-feeds-grid">
                    {
                      Object.keys(this.state.drones).map((drone, i) => (
                        <React.Fragment>
                          {this.state.drones[drone].online && this.state.mini ? <VideoPlayer droneName={this.state.drones[drone]?.drone_name} streamKey={this.state.drones[drone].stream_key} url={this.state.drones[drone].streamURL} /> : <div />}
                        </React.Fragment>
                      ))
                    }
                  </div>
                </div>
              </> : <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", height: "75vh" }}>
                {`You dont have access to drone control center of ${this.state.activeTeam?.teamName} `}
              </div>}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default DCCAllDrones
import React, { Component } from "react";
import * as API from "./api.js";
import "./style.css";
import shareIcon from "./icons/share.png";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import VideoPlayer from "./VideoPlayer.js";
import { Modal } from "react-bootstrap";
import Map from "./MapComponent.js";
import qs from 'qs';
import viewFullscreenFeedButton from './icons/allFeedsButton.png';
import KafkaListener from './KafkaListener.js';
import AltitudeIcon from './res/displacement.svg';
import AttitudeIcon from './res/drone.svg';
import DirectionIcon from './res/compass.svg';
import Popup from "reactjs-popup";
import LocationIcon from './res/locationMarker.svg';
import BatteryIcon from './res/battery.svg';
import ModeIcon from './res/mode.svg';
import SatellitesIcon from './res/satellite.svg';
import VelocityIcon from './res/meter.svg';
import DCCFlyViewUserPinger from './DCCFlyViewUserPinger'
import waiting from './res/waiting.png';
import closeButton from './close-button.png';
import { connect } from "react-redux";
import max from './icons/liveFeedmax.png'
import DroneWithBg from "./icons/DroneWithBg.svg"
import { SetRestrictSesssionExpire } from "./helperComponents/OnIdleHandlerWrapper.js";
import LoaderComponent from "./ReusableComponents/LoaderComponent.js";
import Tippy from "@tippyjs/react";
import 'tippy.js/dist/tippy.css'; // optional
import AppConstants from "./AppConstants.js";
import { addUserAnalytic, checkURLExist, updateOutTime } from "./ReusableComponents/reusableFunctions.js";
import VideoPlayBack from "./ReusableComponents/VideoPlayBack.js";
import waterDrop from "./res/Drop.svg";
import acreIcon from "./res/acre.svg";
import distanceIcon from "./res/distance.svg";
import windIcon from "./res/windIcon.svg";
import wave from "./res/wave.svg";
import locDistance from "./res/locDistance.svg";
import RecentFligthsReplay from "./AeroGCSEnterprise/Components/FligthsReplay/RecentFligthsReplay.js";
import dateFormat from 'dateformat'

let forwardCount = 0;
class MainPageComponent extends Component {
  state = {
    currSliderTime: 0,
    replaySpeed: null,
    replay_player_start_time: 0,
    replay_player_end_time: 0,
    disableBackBtn: true,
    timeoutIds: [],
    user: {},
    type: "",
    loading: true,
    shareLinkText1: "",
    shareLinkText2: "",
    sharableLink: "",
    showLinkPopup: false,
    cipied: false,
    title: "ALL",
    refresh: false,
    flightReplayMode: false,
    drones: {},
    mapKey: 1,
    flightID: "",
    pageNumber: 0,
    activeUsersCount: 0,
    newPlan: {},
    mini: false,
    showNetworkStatsPopup: false,
    bandwidth: 1000000,
    overallConnectivity: -1,
    trailLogs: [],
    projectName: '',
    planName: '',
    logs: [],
    buttonClicked: {
      takeOff: false,
      land: false,
      hover: false,
      rtl: false,
      camOn: false,
      camOff: false
    },
    readyForListening: true,
    flightStatus: <p style={{ color: "green" }}>Status: OK</p>,
    min: false,
    drone: '',
    recentReplaySidebar: true,
    activeComponent: true,
    loadingMap: true,
    firstLoad: true,
    outId: ""
  };

  componentWillUnmount() {
    SetRestrictSesssionExpire(false)
    clearInterval(this.interval)
    clearInterval(this.checkStatusInterval)
    clearInterval(this.streamKeyInterval)
    updateOutTime(this.state.outId)
  }

  convertMode = (modeNumber) => {
    switch (modeNumber) {
      case "0":
        return "Stablize"
      case "1":
        return "Acro"
      case "2":
        return "Alt Hold"
      case "3":
        return "Auto"
      case "4":
        return "Guided"
      case "5":
        return "Loiter"
      case "6":
        return "RTL"
      case "7":
        return "Circle"
      case "8":
        return "Position"
      case "9":
        return "Land"
      case "10":
        return "Of Loiter"
      case "11":
        return "Drift"
      case "12":
        return "Reserved"
      case "13":
        return "Sport"
      case "14":
        return "Flip"
      case "15":
        return "Autotune"
      case "16":
        return "Pos Hold"
      case "17":
        return "Brake"
      case "18":
        return "Throw"
      case "19":
        return "Avoid ADSB"
      case "20":
        return "Guided NOGPS"
      case "21":
        return "Smart RTL"
      case "22":
        return "Flowhold"
      case "23":
        return "Follow"
      case "24":
        return "ZigZag"
      default:
        return "N/A"
    }
  }

  headingToDirection = (heading) => {
    if ((heading >= 337.5 && heading <= 360) || (heading >= 0 && heading <= 22.5)) {
      return "N"
    } else if (heading >= 22.5 && heading <= 67.5) {
      return "NE"
    } else if (heading >= 67.5 && heading <= 112.5) {
      return "E"
    } else if (heading >= 112.5 && heading <= 157.5) {
      return "SE"
    } else if (heading >= 157.5 && heading <= 202.5) {
      return "S"
    } else if (heading >= 202.5 && heading <= 247.5) {
      return "SW"
    } else if (heading >= 247.5 && heading <= 292.5) {
      return "W"
    } else if (heading >= 292.5 && heading <= 337.5) {
      return "NW"
    } else {
      return "?"
    }
  }

  getStatusElement = (status, color, from) => {
    if (status && status.type && status.message) {
      switch (status.type) {
        case "INFO":
          return <p style={{ color: color || "#8df754", marginBottom: "0" }}>{typeof status.timestamp == "number" ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
            : status?.timestamp ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
              : dateFormat(new Date(), 'h:MM:ss TT')}
            :  {status.message}  </p>
          break;
        case "WARN":
          return <p style={{ color: color || "yellow", marginBottom: "0" }}>{typeof status.timestamp == "number" ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
            : status?.timestamp ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
              : dateFormat(new Date(), 'h:MM:ss TT')}
            :  {status.message}  </p>
          break;
        case "ALRT":
          return <p style={{ color: color || "yellow", marginBottom: "0" }}>{typeof status.timestamp == "number" ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
            : status?.timestamp ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
              : dateFormat(new Date(), 'h:MM:ss TT')}
            :  {status.message}  </p>
          break;
        case "DBUG":
          return <p style={{ color: color || "yellow", marginBottom: "0" }}>{typeof status.timestamp == "number" ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
            : status?.timestamp ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
              : dateFormat(new Date(), 'h:MM:ss TT')}
            :  {status.message}  </p>
          break;
        case "ERROR":
          return <p style={{ color: color || "red", marginBottom: "0" }}>{typeof status.timestamp == "number" ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
            : status?.timestamp ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
              : dateFormat(new Date(), 'h:MM:ss TT')}
            :  {status.message}  </p>
          break;
        case "ERRO":
          return <p style={{ color: color || "red", marginBottom: "0" }}>{typeof status.timestamp == "number" ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
            : status?.timestamp ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
              : dateFormat(new Date(), 'h:MM:ss TT')}
            :  {status.message}  </p>
          break;
        case "CRIT":
          return <p style={{ color: color || "red", marginBottom: "0" }}>{typeof status.timestamp == "number" ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
            : status?.timestamp ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
              : dateFormat(new Date(), 'h:MM:ss TT')}
            :  {status.message}  </p>
          break;
        case "EMRG":
          return <p style={{ color: color || "red", marginBottom: "0" }}>{typeof status.timestamp == "number" ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
            : status?.timestamp ? dateFormat(new Date(status.timestamp), 'h:MM:ss TT')
              : dateFormat(new Date(), 'h:MM:ss TT')}
            :  {status.message}  </p>
          break;
      }
    }
    else {
      return ''
      // this.state.flightReplayMode ? ' ' : <p style={{ color: "white", marginBottom: "0", fontWeight: "bold" }}>{status?.timestamp ? dateFormat(new Date(status.timestamp), 'h:MM TT') : dateFormat(new Date(), 'h:MM TT')}: Flight moving safely</p>
    }
  }



  droneTimeOut = (logIndex) => {
    this.setState({ logIndex: logIndex + 1 }, () => {
      this.getCurrentPlayerTime()
      this.replayLogProcessor(logIndex + 1)
    })
  }

  setCustomTimeout = (callback, delay) => {
    const id = setTimeout(() => {
      callback();
      // Remove the id from the array after execution
      const timeoutIds = this.state.timeoutIds.filter(timeoutId => timeoutId !== id);
      this.setState({ timeoutIds })
    }, delay);
    this.state.timeoutIds.push(id);
    this.setState(prev => ({ timeoutIds: prev.timeoutIds }))
    return id;
  }

  clearAllTimeouts = () => {
    this.state.timeoutIds.forEach(timeoutId => clearTimeout(timeoutId));
    this.setState({ timeoutIds: [] })
  }

  removeAdditionalForwardTrailLogs = () => {
    const newTrailLogs = this.state.logs.slice(0, this.state.logIndex).map(log => {
      let data = JSON.parse(log.data)

      const nextFlightLog = this.state.logs.find((log, key) => key > this.state.logIndex && log.key === "AS_FLIGHT_LOG")
      const nextFlightLogData = nextFlightLog?.data ? { ...JSON.parse(nextFlightLog.data), timestamp: nextFlightLog.timestamp } : ''

      switch (log.key) {
        case "AS_FLIGHT_LOG":
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [this.state.flight.drone_id]: {
                ...prevState.drones[this.state.flight.drone_id],
                prevStatus: prevState.drones[this.state.flight.drone_id]?.status,
                status: { ...data, timestamp: log.timestamp },
                nextStatus: nextFlightLogData,
              }
            }
          }))
          break;
        case "AS_TELE_DATA":
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [this.state.flight.drone_id]: {
                ...prevState.drones[this.state.flight.drone_id],
                teleData: data
              }
            }
          }))
          break;
      }
      if (data.lat && data.lon) {
        return ({ lat: data.lat, lng: data.lon })
      }
    }).filter(l => l)
    this.setState({ trailLogs: newTrailLogs }, () => {
      if (!this.state.pauseReplay) {
        this.replayLogProcessor(this.state.logIndex)
      }
    });
  }

  replayBackwardDroneTrail = () => {
    this.setState({ replayBackwards: false, disabledForwardBtn: false, logIndex: this.state.logIndex - 10, }, () => {
      this.getCurrentPlayerTime()
      if (this.state.logIndex <= 0) {
        this.setState({
          disableBackBtn: true,
          logIndex: 0
        }, () => {
          if (!this.state.pauseReplay) {
            this.replayLogProcessor(this.state.logIndex + 1)
          }
        })
      } else {
        this.removeAdditionalForwardTrailLogs()

      }
    })
  }

  createAdditionalForwardTrailLogs = (oldLogIndex) => {
    const additionalTrailLogs = this.state.logs.slice(oldLogIndex, this.state.logIndex).map(log => {
      let data = JSON.parse(log.data)
      switch (log.key) {
        case "AS_FLIGHT_LOG":
          const nextFlightLog = this.state.logs.find((log, key) => key > this.state.logIndex && log.key === "AS_FLIGHT_LOG")
          const nextFlightLogData = nextFlightLog?.data ? { ...JSON.parse(nextFlightLog.data), timestamp: nextFlightLog.timestamp } : ''
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [this.state.flight.drone_id]: {
                ...prevState.drones[this.state.flight.drone_id],
                prevStatus: prevState.drones[this.state.flight.drone_id]?.status,
                status: { ...data, timestamp: log.timestamp },
                nextStatus: nextFlightLogData,
              }
            }
          }))
          break;
        case "AS_TELE_DATA":
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [this.state.flight.drone_id]: {
                ...prevState.drones[this.state.flight.drone_id],
                teleData: data
              }
            }
          }))
          break;
      }
      if (data.lat && data.lon) {
        return ({ lat: data.lat, lng: data.lon })
      }
    }).filter(l => l)
    this.setState({ trailLogs: this.state.trailLogs.concat(additionalTrailLogs) }, () => {
      if (!this.state.pauseReplay) {
        this.replayLogProcessor(this.state.logIndex)
      }
    })
  }

  replayForwardDroneTrail = () => {
    const oldLogIndex = this.state.logIndex
    this.setState({ replayForwards: false, disableBackBtn: false, logIndex: this.state.logIndex + 10, }, () => {
      this.getCurrentPlayerTime()
      if (this.state.logIndex > this.state.logs.length - 1) {
        this.setState({
          disabledForwardBtn: true,
          logIndex: this.state.logs.length - 1
        })
      } else {
        //if current logs are not finished
        this.createAdditionalForwardTrailLogs(oldLogIndex)

      }
    })
  }

  replayLogProcessor = (logIndex) => {
    if (logIndex == this.state.logs.length - 1) {
      this.setState({ logIndex: logIndex + 1 }, () => {
        this.getCurrentPlayerTime()
      }) //this is to make logs found null,so that slider moves to end directly
      return;
    }  // if curr log reached logs length

    if (this.state.replayBackwards) { //if ⏪ is pressed ,stop the video
      return;
    } else if (this.state.replayForwards) { //if ⏩ is pressed,stop the video
      return;
    } else if (this.state.pauseReplay) { //if ⏹️ is pressed ,stop the video
      return;
    }


    this.setState({ disableBackBtn: false })
    let log = this.state.logs[logIndex]
    const nextFlightLog = this.state.logs.find((log, key) => key > logIndex && log.key === "AS_FLIGHT_LOG")
    const nextFlightLogData = nextFlightLog?.data ? { ...JSON.parse(nextFlightLog.data), timestamp: nextFlightLog.timestamp } : ''
    let data = JSON.parse(log.data)
    if (data.lat && data.lon) {
      this.state.trailLogs.push({ lat: data.lat, lng: data.lon })
    }
    switch (log.key) {
      case "AS_FLIGHT_LOG":
        this.setState((prevState) => ({
          ...prevState,
          drones: {
            ...prevState.drones,
            [this.state.flight.drone_id]: {
              ...prevState.drones[this.state.flight.drone_id],
              prevStatus: prevState.drones[this.state.flight.drone_id]?.status,
              status: { ...data, timestamp: log.timestamp },
              nextStatus: nextFlightLogData,
            }
          }
        }))
        break;
      case "AS_TELE_DATA":
        this.setState((prevState) => ({
          ...prevState,
          drones: {
            ...prevState.drones,
            [this.state.flight.drone_id]: {
              ...prevState.drones[this.state.flight.drone_id],
              teleData: data
            }
          }
        }))
        break;
    }

    if (this.props.pip && this.state.trailLogs.length > 0) {
      this.setState({ pauseReplay: true })
    }

    let t = this.state.replaySpeed || 1000
    if (t == 0) {
      this.setState({ logIndex: logIndex + 1 }, () => {
        this.getCurrentPlayerTime()
        this.replayLogProcessor(logIndex + 1)
      })
    } else {
      this.setCustomTimeout(() => this.droneTimeOut(logIndex), t)
    }
  }

  getPageDataFlightReplay = () => {
    this.setState({
      pageNumber: 0,
      loading: true
    }, () => {
      API.getFlightDetails(this.state.flightID, true, this.props.pip ? 5 : -1, this.state.pageNumber, true).then(async (result) => {
        if (result.logs.length > 0) {
          let logs = result.logs;
          const flightLogsIndices = [];
          if (logs.length > 0) {
            logs.forEach((log, index) => {
              if (log?.key == 'AS_FLIGHT_LOG') {
                flightLogsIndices.push({ log, index });
              }
            });
          }
          this.setState({
            logs: result.logs,
            trailLogs: [],
            flightLogsIndices: flightLogsIndices,
            drone: result.flight.drone_id,
            flight: result.flight,
            replay_player_start_time: new Date(result.flight.start_time),
            replay_player_end_time: new Date(result.flight.end_time),
            curr_replay_time: 0,
            projectName: result.flight.project_name,
            planName: result.flight.plan_name,
            logIndex: 0,
            loading: false,
            loadingMap: false,
            refresh: false,
            drones: {
              [result.flight.drone_id]: {
                drone_id: result.flight.drone_id,
                drone_name: result.flight.drone_id,
                droneName: result.flight.drone_id,
                teleData: {
                }
              }
            },
            streamURL: await checkURLExist(`${AppConstants.BLOB_URL}/${this.state.user.blob_container}/DroneStreams/${result.flight.id}/.mp4?${this.state.user.sas_token}`) ?
              `${AppConstants.BLOB_URL}/${this.state.user.blob_container}/DroneStreams/${result.flight.id}/.mp4?${this.state.user.sas_token}`
              : `${AppConstants.BLOB_URL}/${this.state.user.blob_container}/DroneStreams/${result.flight.id}/${result.flight.streamkey}.mp4?${this.state.user.sas_token}`,
          }, () => {
            if (this?.props?.location?.state?.replyData && this.state.firstLoad) {
              let time = this?.props?.location?.state?.replyData.currentTime
              let startTime = this?.props?.location?.state?.replyData.startTime
              this.clearAllTimeouts()
              const curr_timestamp = ((startTime).getTime() + time * 1000)
              this.handleOnChangeReplayTime(curr_timestamp)
            } else {
              this.replayLogProcessor(this.state.logIndex)
            }
          })
        }
      }).catch((e) => {
        console.log(e)
        if (!this.props.pip) {
          window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "") + "/drone_control_center/all_drones");
        }
      })
    })
  }

  getOldLogData = (flightId) => {
    return new Promise((resolve, reject) => {
      API.log(flightId, 'AS_TELE_DATA', 0, 0).then((logData) => {
        for (let i = 0; i < logData.logs.length; i++) {
          let log1 = JSON.parse(logData.logs[i].data)
          this.state.trailLogs.push({ lat: Number(log1.lat), lng: Number(log1.lon) })
        }
        resolve()
      })
    })
  }

  setStatusInterval = () => {
    this.checkStatusInterval = setInterval(() => {
      Object.keys(this.state.drones).forEach((drone, i) => {
        if (this.state.drones[drone].lastActive) {
          let currentTimestamp = new Date();
          let droneLastActive = new Date(this.state.drones[drone].lastActive);
          if (((currentTimestamp - droneLastActive) / 1000) <= 60) {
            this.setState((prevState) => ({
              ...prevState,
              drones: {
                ...prevState.drones,
                [drone]: {
                  ...prevState.drones[drone],
                  online: true
                }
              }
            }))
          } else {
            this.setState((prevState) => ({
              ...prevState,
              drones: {
                ...prevState.drones,
                [drone]: {
                  ...prevState.drones[drone],
                  online: false
                }
              }
            }))
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [drone]: {
                ...prevState.drones[drone],
                online: false
              }
            }
          }))
        }
      })
    }, 6000)
  }

  getPageData = () => {
    API.GetAllDronesStatus(this.state.drone).then(async (allDrones) => {
      const drone = allDrones.drones.find(drone => drone.drone_id === this.state.drone);
      if (allDrones.drone?.length == 0) {
        window.location.replace(process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : "") + "/drone_control_center/all_drones");
      } else {
        if (drone) {
          this.setState({
            projectName: drone.project,
            planName: drone.plan,
          });

          let flightId = drone.flightID ? drone.flightID : drone.flight_id;
          await this.getOldLogData(flightId)
          await this.getFlightData(flightId);

          let droneObject = {
            ...drone,
            teleData: drone.teleData ? drone.teleData : {},
            status: { ...JSON.parse(drone.status), timestamp: new Date().getTime() },
            streamKey: drone.stream_key,
            streamURL: `${drone.uri ? `https://${drone.uri}` : process.env.REACT_APP_STREAMING_BASE}/streams/${drone.stream_key}.m3u8`,
            droneName: drone.drone_name,
          };
          this.setState({
            loading: false,
            drones: { [drone.drone_id]: droneObject },
            streamURL: droneObject.streamURL,
          }, () => {
            if (!qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone || !Object.keys(this.state.drones).includes(this.state.drone)) {
              window.location.replace(`${process.env.REACT_APP_CLIENT_PROTOCOL}://${process.env.REACT_APP_CLIENT_HOST}${process.env.REACT_APP_CLIENT_PORT ? `:${process.env.REACT_APP_CLIENT_PORT}` : ""}/drone_control_center/all_drones`);
            }
          });
        } else {
          window.location.replace(`${process.env.REACT_APP_CLIENT_PROTOCOL}://${process.env.REACT_APP_CLIENT_HOST}${process.env.REACT_APP_CLIENT_PORT ? `:${process.env.REACT_APP_CLIENT_PORT}` : ""}/drone_control_center/all_drones`);
        }
      }
    });

  }

  getFlightData = async (flightId) => {
    try {
      const result = await API.getFlightDetails(flightId, false, 1, 1, true)
      this.setState({
        flight: result.flight
      })
    }
    catch (e) {
      console.error("ERROR", e)
    }
  }

  componentWillMount() {
    SetRestrictSesssionExpire(true)
    if (!this.props.pip) {
      document.title = "Drone Control Center - Fly View";
    }
    addUserAnalytic(this.props.location.pathname).then(id => { this.setState({ outId: id }) })
    if (qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone) {
      this.setState({
        drones: { [qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone]: {} },
        drone: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone
      }, () => {
        this.getPageData();
        this.setStatusInterval()
      })
    } else if (this.props && this.props.location && this.props.location.state) {
      this.setState({
        user: this.props.location.state.user,
        flightID: this.props.location.state.flight,
        flightReplayMode: true,
      }, () => {
        this.getPageDataFlightReplay();
      })
    } else {
      API.getUserType().then(([blah, type, user]) => {
        this.setState((state) => ({
          ...state,
          user,
          type,
          sidebarMode: "shrunk",
          drone: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).drone
        }), () => {
          if (this.state.flightReplayMode) {
            this.getPageDataFlightReplay();
          } else {
            this.getPageData();
            this.setStatusInterval()
          }
        })
      }, (e) => {
        window.location.replace("/login");
      })
    }
  }

  numberToPrecisionString = (n, p) => {
    if (n == undefined || n == null) {
      return "N/A";
    }
    let post = n.toString().split('.')[1];
    if (post) {
      return n.toString().split('.')[0] + "." + post.substring(0, p) + (((p - post.length) > 0) ? "0".repeat(p - post.length) : "");
    } else {
      return n.toString().split('.')[0] + "." + "0".repeat(p);
    }
  }

  networkStatsPopupToggle = () => {
    this.setState((prevState) => ({
      ...prevState,
      showNetworkStatsPopup: !prevState.showNetworkStatsPopup
    }))
  }

  togglePlanRCVDPopup = () => {
    document.getElementById("new-plan-popup-button").click()
  }

  updatePlanGetNew = () => {
    API.getPlan(this.state.newPlan.project, this.state.newPlan.plan).then((results) => {
      let plan = results.mission.map(w => ({
        lat: parseFloat(w.waypoints["MSG x"]),
        lon: parseFloat(w.waypoints["Msg y"])
      }))
      let geofence = results.geofence.map(w => ({
        lat: parseFloat(w.geofence["geofence X"]),
        lon: parseFloat(w.geofence["geofence Y"])
      }))
      this.setState((prevState) => ({
        ...prevState,
        plan,
        geofence,
        mapKey: prevState.mapKey + 1
      }))
    }).catch(err => {
      console.log(err)
    })
  }

  handleReplayBackward = () => {
    this.setState({ replayBackwards: true, replayForwards: false }, () => {
      if (this.state.replayBackwards) {
        this.clearAllTimeouts()
        this.replayBackwardDroneTrail()
      }
    })
  }

  handleReplayForward = () => {
    this.setState({ replayBackwards: false, replayForwards: true }, () => {
      if (this.state.replayForwards) {
        this.clearAllTimeouts()
        this.replayForwardDroneTrail()
      }
    })
  }

  handleToggleReplay = () => {
    this.setState({ pauseReplay: !this.state.pauseReplay }, () => {
      if (this.state.pauseReplay) {
        this.clearAllTimeouts()
      }
      else {
        this.replayLogProcessor(this.state.logIndex)
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pauseReplay !== this.props.pauseReplay) {
      this.handleToggleReplay()
    }
  }


  replayToSpecificTime = () => {
    const newTrailLogs = this.state.logs.slice(0, this.state.logIndex + 1).map(log => {
      let data = JSON.parse(log.data)
      const nextFlightLog = this.state.logs.find((log, key) => key > this.state.logIndex && log.key === "AS_FLIGHT_LOG")
      const nextFlightLogData = nextFlightLog?.data ? { ...JSON.parse(nextFlightLog.data), timestamp: nextFlightLog.timestamp } : ''

      switch (log.key) {
        case "AS_FLIGHT_LOG":
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [this.state.flight.drone_id]: {
                ...prevState.drones[this.state.flight.drone_id],
                prevStatus: prevState.drones[this.state.flight.drone_id]?.status,
                status: { ...data, timestamp: log.timestamp },
                nextStatus: nextFlightLogData,
              }
            }
          }))
          break;
        case "AS_TELE_DATA":
          this.setState((prevState) => ({
            ...prevState,
            drones: {
              ...prevState.drones,
              [this.state.flight.drone_id]: {
                ...prevState.drones[this.state.flight.drone_id],
                teleData: data
              }
            }
          }))
          break;
      }
      if (data.lat && data.lon)
        return ({ lat: data.lat, lng: data.lon })
    }).filter(l => l)
    this.setState({ trailLogs: newTrailLogs }, () => {
      this.replayLogProcessor(this.state.logIndex)
    })
  }

  findClosestTimestampIndex(array, target) {
    let left = 0;
    let right = array.length - 1;

    // If the target is outside the range of the array, return the closest edge index
    if (target <= new Date(array[left].timestamp).getTime()) {
      return left;
    }
    if (target >= new Date(array[right].timestamp).getTime()) {
      return right;
    }

    // Binary search to find the closest value
    while (left <= right) {
      const mid = Math.floor((left + right) / 2);

      // Check if mid is the closest
      if (new Date(array[mid].timestamp).getTime() === target) {
        return mid;
      }

      // If the target is less than the mid value, search the left half
      if (target < new Date(array[mid].timestamp).getTime()) {
        right = mid - 1;
      }
      // If the target is greater than the mid value, search the right half
      else {
        left = mid + 1;
      }
    }

    // After exiting the loop, left and right will be the indices of the closest values
    // Find which of left or right is closer to the target
    const closestLeftIndex = (left >= array.length) ? array.length - 1 : left;
    const closestRightIndex = (right < 0) ? 0 : right;

    if (Math.abs(new Date(array[closestLeftIndex].timestamp).getTime() - target) < Math.abs(new Date(array[closestRightIndex].timestamp).getTime() - target)) {
      return closestLeftIndex;
    } else {
      return closestRightIndex;
    }
  }

  handleOnChangeReplayTime = (time) => {
    if (time == new Date(this.state.replay_player_end_time).getTime()) return
    const logIndex = this.findClosestTimestampIndex(this.state.logs, time)

    if (logIndex >= 0) {
      this.setState({ logIndex }, () => {
        this.getCurrentPlayerTime()
        this.replayToSpecificTime()
      })
    } else {
      console.log("log index not found")
    }

  }

  getCurrentPlayerTime = () => {
    const currLog = this.state.logs[this.state.logIndex]
    let currTime;
    if (currLog) {
      var d = new Date(currLog.timestamp);
      var t = d; //1440516958
      currTime = (t - this.state.replay_player_start_time) / 1000
    } else {
      currTime = (this.state.replay_player_end_time - this.state.replay_player_start_time) / 1000 //move slider to end if no further logs found
    }
    this.setState({ currSliderTime: currTime })
  }

  handleReplaySpeedChange = (speed) => {
    this.setState({ replaySpeed: 1000 / parseFloat(speed) }, () => {
      this.clearAllTimeouts()
      this.replayLogProcessor(this.state.logIndex)
    })
  }

  render() {
    let drone = this.state.drone;
    return (
      <div className="wrapper"
        style={{ overflowY: "hidden" }} >

        <div
          className="right-content-new"
          style={{ height: this.props.height || "100vh", overflowY: "auto", left: "0px", width: this.props.width || "100vw" }}
        >
          <Popup
            style={{ width: "min-content" }}

            trigger={<button id="send-plan-popup-button" style={{
              display: "none",
            }}
            ></button>}
            modal
          >
            <img
              id="close-popup"
              src={closeButton} style={{
                width: "auto",
                height: "26px",
                position: "absolute",
                top: "-13px",
                right: "-13px",
                cursor: "pointer"

              }} onClick={() => { document.getElementById("send-plan-popup-button").click(); }}></img>
            <div >
              <div style={{ width: "100%", display: "flex" }}>
                <img src={waiting} style={{
                  width: "auto",
                  height: "64px",
                  margin: "30px auto"
                }} />
              </div>


              <>
                <div style={{ width: "100%", textAlign: "center", marginBottom: '50px' }}>{this.state.shareLinkText1}</div>
                {/* <div style={{ width: "100%", textAlign: "center", marginBottom: "20px" }}>{this.state.shareLinkText2}</div> */}
              </>

            </div>
          </Popup>
          <Modal
            show={this.state.showLinkPopup}
            onHide={() => {
              this.setState((state) => ({
                ...state,
                showLinkPopup: false,
              }));
            }}
            style={{ marginTop: '150px' }}
          >
            <Modal.Body>
              <div className="create-project-form-row">
                <div className="add-user-form-text-wrapper">
                  <input className="add-user-form-text" value={this.state.sharableLink} style={{ width: '79%', marginLeft: '10%' }} />
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <CopyToClipboard text={this.state.sharableLink}
                  style={{ marginLeft: '32%', width: 'auto', marginTop: '24px', height: '30px', paddingTop: '9px', paddingBottom: '8px' }}
                  onCopy={() => {
                    this.setState({ copied: true })
                    setTimeout(() => {
                      this.setState({ copied: false })
                    }, 1500);
                  }}>
                  <button className="add-user-form-submit-button" style={{ height: '30px', paddingTop: '9px', paddingBottom: '8px' }}>Copy to clipboard</button>
                </CopyToClipboard>
                {this.state.copied == true ? <p style={{ marginLeft: '15px', marginTop: '28px', color: 'green', fontFamily: 'Poppins', fontSize: '13px' }}>Copied</p> : <div />}
              </div>
            </Modal.Body>
            <Modal.Footer>

              <button
                className="addcustomer-button-empty-dashboard"
                onClick={() => {
                  this.setState((state) => ({
                    ...state,
                    showLinkPopup: false,
                  }));
                }}
              >
                cancel
              </button>
              <button
                className="add-user-form-submit-button btn-ok"
                style={{ width: "fit-content" }}
                onClick={() => {
                  this.setState((state) => ({
                    ...state,
                    showLinkPopup: false
                  }))
                }}
              >
                Ok
              </button>
            </Modal.Footer>
          </Modal>

          {!this.props.pip && <div className="top-bar-new">
            <div className="top-bar-text-new">{this.state.drones[drone] && this.state.flight?
              `Fly View - ${this.state.drones[drone].uin?.length > 20 ?
                this.state.drones[drone]?.uin.substring(0, 20) + '...' : this.state.drones[drone]?.uin || this.state.drones[drone]?.drone_name}   |   ${this.state.flight?.id}` :
              "Drone Control Center - Fly View"}
            </div>
          </div>}
          {!this.state.loading && !this.state.flightReplayMode ? <DCCFlyViewUserPinger droneID={this.state.drone} userID={this.state.user.userid} pingersCallback={(pingersTable) => {
            let keys = Object.keys(pingersTable)
            let activeUsersCount = 0
            keys.forEach((key, i) => {
              if (pingersTable[key].online) {
                activeUsersCount++
              }
              if (i == keys.length - 1) {
                this.setState({ activeUsersCount })
              }
            })

          }} /> : <div />}
          {!this.state.loadingMap && !this.props.pip && <RecentFligthsReplay flightID={this.state.flightID}
            onClickFlight={(flight) => {
              this.setState({ flightID: flight.id, logIndex: 0, firstLoad: false, refresh: true }, () => {
                this.clearAllTimeouts()
                this.getPageDataFlightReplay();
              })
            }}
            handleScrollActive={() => { this.setState({ activeComponent: !this.state.activeComponent }) }}
          />}
          {this.state.drone == '' ? <LoaderComponent />
            : <>
              <div
                className="main-content main-content-dcc"
                style={{
                  height: this.props.pip ? "100%" : "",
                  width: "100%",
                  minHeight: "0px",
                  padding: "0px",
                }}
              >
                {this.state.loading ? <LoaderComponent />
                  : <>
                    {this.state.drone && !this.state.flightReplayMode ?
                      <KafkaListener from={'DCCDroneFlyView'} id="dccFVKL" topics={[this.state.drones[this.state.drone].topic]} messageCallback={(topic, message) => {
                        if (topic.split('.').length == 3 && this.state.readyForListening) {
                          Object.keys(this.state.drones).forEach((drone, i) => {
                            if (this.state.drones[drone].topic == topic.split(".")[1]) {
                              this.setState((prevState) => ({
                                ...prevState,
                                drones: {
                                  ...prevState.drones,
                                  [drone]: {
                                    ...this.state.drones[drone],
                                    teleData: {
                                      lat: parseFloat(message.split(' ')[0]),
                                      lon: parseFloat(message.split(' ')[1])
                                    }
                                  }
                                }
                              }), async () => {
                                return
                              })
                            }
                          });
                        } else if (topic.split('.').length == 1 && this.state.readyForListening) {
                          Object.keys(this.state.drones).forEach((drone, i) => {
                            if (this.state.drones[drone].topic == topic) {
                              let parsedMessage = message.message;
                              switch (message.key) {
                                case "AS_TELE_DATA":
                                  if (typeof parsedMessage.data.lat == "string") parsedMessage.data.lat = parseFloat(parsedMessage.data.lat);
                                  if (typeof parsedMessage.data.lon == "string") parsedMessage.data.lon = parseFloat(parsedMessage.data.lon);
                                  this.state.trailLogs.push({ lat: parsedMessage.data.lat, lng: parsedMessage.data.lon })
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    drones: {
                                      ...prevState.drones,
                                      [drone]: {
                                        ...this.state.drones[drone],
                                        teleData: parsedMessage.data,
                                        flightID: message.flightID ? message.flightID : prevState.drones[drone].flightID
                                      }
                                    },
                                    mapKey: "mapKeyN"
                                  }), async () => {
                                    return;
                                  })
                                  break;
                                case "AS_KEEP_ALIVE":
                                  this.setState((prevState) => ({
                                    ...prevState,
                                    drones: {
                                      ...prevState.drones,
                                      [drone]: {
                                        ...this.state.drones[drone],
                                        lastActive: parseInt(message.timestamp) * 1000,
                                        teleData: {
                                          ...prevState.drones[drone].teleData,
                                          lat: parsedMessage.data && parsedMessage.data.lat ? parsedMessage.data.lat : (prevState.drones[drone].teleData ? prevState.drones[drone].teleData.lat : null),
                                          lon: parsedMessage.data && parsedMessage.data.lon ? parsedMessage.data.lon : (prevState.drones[drone].teleData ? prevState.drones[drone].teleData.lon : null)
                                        },
                                        flightID: message.flightID ? message.flightID : prevState.drones[drone].flightID
                                      }
                                    }
                                  }))
                                  break;
                                case "AS_FLIGHT_LOG":
                                  if (this.state.drones[drone].status.timestamp != (new Date(message.timestamp * 1000)).toLocaleString() && this.state.drones[drone].status.message != parsedMessage.data.message) {
                                    this.setState((prevState) => ({
                                      ...prevState,
                                      drones: {
                                        ...prevState.drones,
                                        [drone]: {
                                          ...this.state.drones[drone],
                                          prevStatus: {
                                            ...prevState.drones[drone].status, },
                                          status: {
                                            ...parsedMessage.data,
                                            timestamp: message.timestamp * 1000 || new Date().getTime()
                                          }
                                        }
                                      }
                                    }));
                                  }
                                  break;
                                case "AS_PLAN_RCVD_ACK":
                                  if (parsedMessage.data && parsedMessage.data.project && parsedMessage.data.plan) {
                                    this.setState({
                                      newPlan: parsedMessage.data
                                    })
                                    this.togglePlanRCVDPopup()
                                  }
                                  break;
                                default:
                                  break;
                              }
                            }
                          });
                        }
                      }} /> : <div />}

                    {!this.state.loading ?
                      <div style={{ height: this.props.pip ? 'calc(100% - 50px)' : '100%', width: '100%' }}>
                        <Map
                          replayPlayback={this.state.flightReplayMode}
                          replaySpeed={this.state.replaySpeed}
                          showIcons={!this.props.pip}
                          key={this.state.mapKey + "-mapComp"}
                          className="dcc-all-drones-map"
                          drones={this.state.flightReplayMode ? {
                            [this.state.flight.drone_id]: this.state.drones[this.state.drone]
                          } : {
                            [this.state.drone]: this.state.drones[this.state.drone]
                          }}
                          fenceBoundary={this.state.flight?.fence_boundary}
                          planBoundary={this.state.flight?.plan_boundary}
                          trailLogs={this.state.trailLogs}
                          componentType='DCCflyView'
                          projectName={this.state.projectName}
                          planName={this.state.planName}
                          user={this.state.user}
                          setBaseLayerToState={this.setBaseLayerToState}
                          markerClickCallback={() => { }}
                          flightReplayMode={this.state.flightReplayMode}
                          replayDroneID={this.state.flightReplayMode ? this.state.flight.drone_id : undefined}
                          replayTeleData={this.state.flightReplayMode ? this.state.drones[this.state.flight.drone_id].teleData : undefined}
                        />
                      </div>
                      : <div />}

                    {!this.props.pip &&
                      <Tippy content={this.state.drones[drone]?.droneName || "Drone"}>
                        <div className="dcc-all-drones-live-feeds" style={{ left: "10px", right: "unset" }}>
                          <div style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', width: "fit-content" }} className="dcc-all-drones-live-feeds-header" >

                            <div style={{ margin: '0px', padding: '0 10px' }} className="dcc-all-drones-live-feeds-header-button">
                              <img src={max} className="dcc-all-drones-live-feeds-header-button-img" onClick={() => { this.setState({ mini: !this.state.mini }) }} />
                              {this.state.drones[this.state.drone] && this.state.drones[this.state.drone].project ? <img src={viewFullscreenFeedButton} className="dcc-all-drones-live-feeds-header-button-img"
                                onClick={() => { window.open("/drone_control_center/feed?drone=" + this.state.drone, '_blank'); }} />
                                : <img src={viewFullscreenFeedButton} className="dcc-all-drones-live-feeds-header-button-img" style={{ opacity: '0.5', cursor: 'auto' }} />}
                            </div>
                          </div>
                          <div className="dcc-all-drones-live-feeds-grid">
                            <React.Fragment>
                              {this.state.drones[drone] && this.state.mini ? <VideoPlayer disableSeekToLatest={true} url={this.state.streamURL} /> : <div />}
                            </React.Fragment>
                          </div>
                        </div>
                      </Tippy>}

                    {!this.props.pip && <div className="flyViewStats" style={{ bottom: this.state.flightReplayMode ? "70px" : "20px", }}>

                      <div className="statusTextData">
                        <div className="dcc-fly-view-status2" >
                          {this.getStatusElement(this.state.drones[this.state.drone].prevStatus, "white", 'prevStatus')}
                        </div>
                        <div className="dcc-fly-view-status1" >
                          {this.getStatusElement(this.state.drones[this.state.drone].status, undefined, 'current')}
                        </div>
                        {this.state.flightReplayMode && <div className="dcc-fly-view-status2" >
                          {this.getStatusElement(this.state.drones[this.state.drone].nextStatus, "white", 'nextStatus')}
                        </div>}
                      </div>

                      <div style={{ display: 'flex', marginTop: '10px' }}>
                        <div className="dcc-fly-view-status-data" style={{ gridTemplateColumns: "auto auto auto auto" }}>
                          <div className="dcc-fly-view-status-sub" >
                            <img src={LocationIcon} />
                            <div>
                              <p>{this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.lat, 6)}°</p>
                              <p>{this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.lon, 6)}°</p>
                            </div>
                          </div>
                          <div className="dcc-fly-view-status-sub">
                            <img src={DirectionIcon} />
                            <div>
                              <p>{(this.state.drones[this.state.drone].teleData.dir ? this.headingToDirection(this.state.drones[this.state.drone].teleData.dir) : "N/A")}</p>
                              <p>{(this.state.drones[this.state.drone].teleData.dir ? this.state.drones[this.state.drone].teleData.dir : "N/A")}°</p>
                            </div>
                          </div>
                          <div className="dcc-fly-view-status-sub">
                            <img src={SatellitesIcon} />
                            <div>
                              <p>{(this.state.drones[this.state.drone].teleData.sat ? this.state.drones[this.state.drone].teleData.sat : "N/A") + " sats"}</p>
                            </div>
                          </div>
                          <div className="dcc-fly-view-status-sub">
                            <img src={AltitudeIcon} />
                            <div>
                              <p>{(this.state.drones[this.state.drone].teleData.alt ? this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.alt, 2) : "N/A") + " m"}</p>
                            </div>
                          </div>

                          <div className="dcc-fly-view-status-sub">
                            <img src={VelocityIcon} />
                            <div>
                              <p>{this.state.drones[this.state.drone].teleData.vel ? this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.vel, 5) : "N/A"}</p>
                            </div>
                          </div>
                          <div className="dcc-fly-view-status-sub">
                            <img src={BatteryIcon} />
                            <div>
                              <p>{(this.state.drones[this.state.drone].teleData.bat && this.state.drones[this.state.drone].teleData.bat != "65535" ? this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.bat, 2) : "0") + " V"}</p>
                            </div>
                          </div>
                          <div className="dcc-fly-view-status-sub">
                            <img src={ModeIcon} />
                            <div>
                              <p>{this.state.drones[this.state.drone].teleData.mod ? this.convertMode(this.state.drones[this.state.drone].teleData.mod) : "N/A"}</p>
                            </div>
                          </div>
                          <div className="dcc-fly-view-status-sub">
                            <img src={AttitudeIcon} />
                            <div>
                              <p>{"R: " + this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.rol, 4)}</p>
                              <p>{"P: " + this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.pit, 4)}</p>
                              <p>{"Y: " + this.numberToPrecisionString(this.state.drones[this.state.drone].teleData.yaw, 4)}</p>
                            </div>
                          </div>
                        </div>


                        {this.state.drones[this.state.drone].teleData.liquidConsumed || this.state.drones[this.state.drone].teleData.remainingLiquidPercentage || this.state.drones[this.state.drone].teleData.flowRate ||
                          this.state.drones[this.state.drone].teleData.sprayedArea || this.state.drones[this.state.drone].teleData.sprayedDistance || this.state.drones[this.state.drone].teleData.sprayPumpRate || this.state.drones[this.state.drone].teleData.liveObstacleDistance ?
                          <div className="dcc-fly-view-status-data">

                            <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                              <img src={waterDrop} />
                              <div>
                                <p>Consumed</p>
                                <p>{(this.state.drones[this.state.drone].teleData.liquidConsumed ? this.state.drones[this.state.drone].teleData.liquidConsumed : "N/A")} ml</p>
                              </div>
                            </div>
                            <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                              <img src={acreIcon} />
                              <div>
                                <p>Area Covered</p>
                                <p>{(this.state.drones[this.state.drone].teleData.sprayedArea ? this.state.drones[this.state.drone].teleData.sprayedArea : "N/A")} Acre</p>
                              </div>
                            </div>
                            <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                              <img src={distanceIcon} />
                              <div>
                                <p>Distance Travelled</p>
                                <p>{(this.state.drones[this.state.drone].teleData.sprayedDistance ? this.state.drones[this.state.drone].teleData.sprayedDistance : "N/A")} m</p>
                              </div>
                            </div>
                            <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                              <img src={windIcon} />
                              <div>
                                <p>Pump Rate</p>
                                <p>{this.state.drones[this.state.drone].teleData.sprayPumpRate ? this.state.drones[this.state.drone].teleData.sprayPumpRate : "N/A"} %</p>
                              </div>
                            </div>
                            <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                              <img src={wave} />
                              <div>
                                <p>Flow Rate</p>
                                <p>{this.state.drones[this.state.drone].teleData.flowRate ? this.state.drones[this.state.drone].teleData.flowRate : "N/A"} L/min</p>
                              </div>
                            </div>
                            <div className="dcc-fly-view-status-sub" style={{ width: '150px' }}>
                              <img src={locDistance} />
                              <div>
                                <p>Obs Alt</p>
                                <p>{this.state.drones[this.state.drone].teleData.liveObstacleDistance ? this.state.drones[this.state.drone].teleData.liveObstacleDistance : "N/A"} cm</p>
                              </div>
                            </div>
                          </div>
                          : <></>}

                      </div>
                    </div>}
                    {this.state.flightReplayMode &&
                      <div style={{ position: 'absolute', bottom: 0, left: 0, width: "100%" }}>
                        <VideoPlayBack
                          maxValue={(this.state.replay_player_end_time - this.state.replay_player_start_time) / 1000}
                          value={this.state.currSliderTime}
                          title={this.props.pip && this.state.flight ? `${this.state.flight.project_name} / ${this.state.flight.plan_name}` : undefined}
                          subTitle={this.props.pip && this.state.flight ? `${this.state.flight.id}` : undefined}
                          replayPip={this.props.pip}
                          onHover={this.props.onHover}
                          onChangeValue={this.props.onChangeValue}
                          startTime={this.state.replay_player_start_time}
                          onClickBackward={this.handleReplayBackward}
                          onClickForward={this.handleReplayForward}
                          onTogglePlay={this.handleToggleReplay}
                          onSliderChange={(time) => {
                            this.setState({ currSliderTime: time })
                            this.clearAllTimeouts()
                            const curr_timestamp = ((this.state.replay_player_start_time).getTime() + time * 1000)
                            this.handleOnChangeReplayTime(curr_timestamp)
                          }}
                          onSpeedChange={e => this.handleReplaySpeedChange(e.target.value)}
                        />
                      </div>}

                  </>}
              </div>
            </>
          }
        </div>
      </div >

    );
  }
}

const mapStateToProps = state => {
  return {
    activeTeamId: state.profile.activeTeamId,
    activeTeam: state.profile.activeTeam,
    SubscriptionData: state.subscription.subscriptionData,
    activesubscription: state.activesubscription.activesubscription,
    isLoggedInUserOwner: state.profile.isLoggedInUserOwner
  };
};

export default (connect(mapStateToProps)(MainPageComponent));

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import * as API from './api.js';
import TableView from "./ReusableComponents/TableView/TableView"
import { useHistory } from "react-router-dom"
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs.js';
import SearchHeader from './ReusableComponents/SearchHeader/SearchHeader.js';
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions.js';

const RMPAllFlightPlans = (props) => {
    const { state } = useLocation()
    const history = useHistory();
    const [outId, setOutId] = useState("")
    const [loading, setLoading] = useState(false)
    const [plans, setPlans] = useState([])
    const [totalCount, setTotalCount] = useState(null)
    const [filter, setFilter] = useState({
        searchString: "",
        orderBy: 'date_and_time',
        orderType: 'DESC',
        pageNumber: 1,
        pageSize: 15
    })

    const getAllFlightPlans = () => {
        setLoading(true)
        API.getLatestPlans(filter.searchString || " ", filter.pageSize, filter.pageNumber, filter.orderBy, filter.orderType, state.activeTeamId).then(
            (plans) => {
                setPlans(plans.data)
                setTotalCount(plans.count)
                setLoading(false)
            },
            (err) => {
                console.log(err);
                setLoading(false)
            }
        );
    }


    useEffect(() => { return () => { updateOutTime(outId) } }, [outId]);

    useEffect(() => {
        addUserAnalytic(props.location.pathname).then(id => { setOutId(id) });
        getAllFlightPlans()
    }, [filter])

    return (
        <div style={{ background: "#f5f6f8" }} className="wrapper">
            <div className="right-content-new" >
                <div className="top-bar-new" style={{ display: "flex" }}>
                    <div className="top-bar-text-new">
                        <BreadCrumbs
                            data={[
                                {
                                    displayName: "AeroGCS",
                                    pathname: "/",
                                    state: {
                                        user: state.user
                                    }
                                },
                                {
                                    displayName: 'Plans',
                                }
                            ]} />
                    </div>
                </div>
                <div className='main-content-new' style={{ padding: '0 55px' }}>

                    <div style={{ padding: "10px 0" }}>Plans <span>({totalCount})</span></div>

                    <SearchHeader
                        placeholder={"Search plan.."}
                        onFilterDataChange={(str) => { setFilter({ ...filter, searchString: str.searchString, pageNumber: 1 }) }}
                    />
                    <div className={'all-plans-table-height'} >
                        <TableView
                            isLoading={loading}
                            count={totalCount}
                            onClickRow={(row) => [
                                history.push('/remote_mission/assignDroneToPlan', {
                                    user: state.user,
                                    plan: { plan_name: row.plan_name, planId: row.id },
                                    project: { "project_name": row.project_name, "id": row.project_id }
                                })
                            ]}
                            columns={[
                                { Header: 'Plan Name', accessor: 'plan_name', width: '30', isSortable: true },//isSortable: true
                                { Header: 'Project Name', accessor: 'project_name', width: '30', },
                                { Header: 'Date/Time', accessor: 'date_and_time', width: '30', isDate: true,   isSortable: true },//isSortable: true
                            ]}
                            data={plans}
                            user={state.user}
                            onStateChange={(changedState) => {
                                setFilter({ ...filter, pageNumber: changedState.pageNumber, orderBy: changedState.orderBy, orderType: changedState.orderType })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RMPAllFlightPlans
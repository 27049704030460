import Cookies from "universal-cookie";
import axios from "axios";
import AppConstants from "./AppConstants";
import { getActiveTeamId } from "./Teams";

const cookie = new Cookies(),
  URL = process.env.REACT_APP_CLIENT_PROTOCOL + "://" + process.env.REACT_APP_CLIENT_HOST + (process.env.REACT_APP_CLIENT_PORT ? process.env.REACT_APP_CLIENT_PORT : ""),
  SERVER = process.env.REACT_APP_SERVER_PROTOCOL + "://" + process.env.REACT_APP_SERVER_HOST + (process.env.REACT_APP_SERVER_PORT ? process.env.REACT_APP_SERVER_PORT : ""),
  GET_USER_STORAGE = `${SERVER}/Aeromegh/subscription/getUserStorage`,
  SESSION_CHECK = `${SERVER}/sessionCheck`,
  GET_USERS = `${SERVER}/users`,
  GET_COUNTRIES = `${SERVER}/countries`,
  GET_STATES = `${SERVER}/states`,
  USER_SEARCH = `${SERVER}/user`,
  FEEDBACK = `${SERVER}/feedback`,
  USER_EDIT = `${SERVER}/user`,
  GET_ALL_USERS = `${SERVER}/users`,
  GET_REGISTRATION_STATUS = `${SERVER}/getRegistrationStatus`,
  DELETE_PROJECT = `${SERVER}/Aeromegh/project`,
  DELETE_PLAN = `${SERVER}/Aeromegh/plan`,
  ADD_PROJECT = `${SERVER}/Aeromegh/remoteMission/addProject`,
  ADD_NEW_PROJECT = `${SERVER}/AeroMegh/AeroCapture/addProject`,
  ADD_PLAN = `${SERVER}/Aeromegh/remoteMission/addPlan`,
  EDIT_PLAN = `${SERVER}/Aeromegh/remoteMission/editPlan`,
  GET_AEROSTACK_PLAN = `${SERVER}/AeroMegh/AeroStack/getPlan`,
  IS_PLAN_EXIST = `${SERVER}/Aeromegh/remoteMission/checkPlanExists`,
  FLIGHT_DETAILS = `${SERVER}/AeroMegh/AeroStack/flightDetails`,
  ADMIN_FLIGHT_DETAILS = `${SERVER}/AeroMegh/AeroStack/adminFlightDetails`,
  GET_LATEST_PLANS = `${SERVER}/Aeromegh/remoteMission/aeroplanner/latestPlans`,
  GET_DRONES_OWNED = `${SERVER}/getDronesOwned`,
  USER_AEROMEGH_LOGIN_DIRTY = `${SERVER}/AeroMegh/userLoginDirty`,
  GET_GCS_FLIGHTS = `${SERVER}/AeroMegh/AeroGCSEnterprise/flights`,
  GET_GCS_FLIGHTS_CSV_DATA = `${SERVER}/AeroMegh/AeroGCSEnterprise/flights/csvData`,
  GET_GCS_FLIGHT_STATS = `${SERVER}/AeroMegh/AeroGCSEnterprise/flights/stats`,
  GET_GCS_FLIGHT_GRAPH_STATS = `${SERVER}/AeroMegh/AeroGCSEnterprise/flights/graphStats`,
  GET_GCS_FLIGHT_PILOTS = `${SERVER}/AeroMegh/AeroGCSEnterprise/flights/pilots`,
  GET_GCS_FLIGHT_PILOTS_STATS = `${SERVER}/AeroMegh/AeroGCSEnterprise/flights/pilots/stats`,
  GET_GCS_FLIGHT_PILOTS_GRAPH_STATS = `${SERVER}/AeroMegh/AeroGCSEnterprise/flights/pilots/graphStats`,
  GET_GCS_FLIGHT_ACRES_GRAPH_STATS = `${SERVER}/AeroMegh/AeroGCSEnterprise/flights/acres/graphStats`,
  GET_GCS_DRONES = `${SERVER}/AeroMegh/AeroGCSEnterprise/drones`,
  GET_FIRST_AND_LAST_FLIGHT_TIMES = `${SERVER}/AeroMegh/AeroGCSEnterprise/flights/firstAndLastFlightsTimes`,
  GET_FLIGHTS = `${SERVER}/AeroMegh/AeroStack/allFlights`,
  GET_FLIGTH_LOGS_BY_DRONE_ID = `${SERVER}/Aeromegh/remoteMission/flightLogsByDroneId`,
  GET_WEEKLY_FLIGHT_COUNT = `${SERVER}/AeroMegh/remoteMission/aeroplanner/weeklyFlightsCount`,
  GET_FLIGHTS_STAT_DATA = `${SERVER}/AeroMegh/remoteMission/aeroplanner/getFlightsStatData`,
  GET_FLIGHT_IMAGES = `${SERVER}/AeroMegh/flight/images`,
  UPLOAD_PLAN = `${SERVER}/AeroMegh/AeroStack/sendFetchPlanCommand?`,
  GET_DRONE_PORTS = `${SERVER}/AeroMegh/AeroStack/getDronePorts`,
  GET_LOG = `${SERVER}/AeroMegh/AeroStack/flightLogs`,
  AEROMEGH_PLAN_PURCHASE_ACTIVATE = `${SERVER}/AeroMegh/user/subscription/activate`,
  USER_STATS = `${SERVER}/AeroMegh/stats/users`,
  UPDATE_PASSWORD = `${SERVER}/updatePassword`,
  ALL_DRONES = `${SERVER}/allDrones`,
  GET_INVOICE = `${SERVER}/AeroMegh/invoices`,
  ADMIN_USER_INVOICES = `${SERVER}/AeroMegh/admin/invoices`,
  DRONE = `${SERVER}/drone`,
  OTP_FOR_DELETE_LOGS = `${SERVER}/drone/otpForDeleteLogs`,
  VARIFY_OTP_AND_DELETE_DRONE = `${SERVER}/drone/verifyOtpAndDeleteDroneDataWithDrone`,
  ALL_DRONES_STATUS = `${SERVER}/AeroMegh/getDronesStatus`,
  ADMIN_ALL_DRONES_STATUS = `${SERVER}/AeroMegh/admin/getDronesStatus`,
  GCS_DRONES = `${SERVER}/Aeromegh/GCSDrones`,
  MANUFACTURER = "3873ca27-15de-4255-b256-a0c045d9282a",
  CUSTOMER = "8b28f377-c02f-4f3c-8bd7-f4794c066546",
  DRONE_LATEST_STREAM_KEY = `${SERVER}/AeroMegh/getLatestStreamKey`,
  GET_ALL_FLIGHTS_COMPLETED_HOURS = `${SERVER}/AeroMegh/flight/completedHours`,
  GET_ALL_FLIGHT_LOGS = `${SERVER}/Aeromegh/remoteMission/AllFlightsLogs`,
  GET_REPLAY_FLIGHTS = `${SERVER}/Aeromegh/remoteMission/getflightsReplays`,
  PLANS = `${SERVER}/AeroMegh/Plan`,
  SETTLE_PAYMENT = `${SERVER}/AeroMegh/payments/settlePayment`,
  ADMIN_SEND_INVOICE_REMINDER = `${SERVER}/AeroMegh/admin/sendInvoiceReminder`,
  GET_SHARABLE_LINK_ACCESS = `${SERVER}/AeroMegh/liveVideoStream/sharableLinkStreamAccess`,
  GET_SHARABLE_LINK_DETAILS = `${SERVER}/AeroMegh/liveVideoStream/sharableLinkStreamDetails`,
  GET_SHARABLE_LINK = `${SERVER}/AeroMegh/liveVideoStream/sharableLink`,
  DELETE_RAD_VIDEO = `${SERVER}/AeroMegh/RequestADrone/requester/request`,
  GET_AEROSTREAM_VIDEOS = `${SERVER}/AeroMegh/RequestADrone/requester/videos`,
  GET_AEROSTREAM_VIDEOS_INGCS = `${SERVER}/AeroMegh/RequestADrone/requester/videosInGCS`,
  GET_AEROSTREAM_SINGLE_VIDEO = `${SERVER}/AeroMegh/RequestADrone/requester/video`,
  VIEWER_MINUTES_STATS = `${SERVER}/AeroMegh/user/viewerMinutes`,
  GET_SUBSCRIPTION = `${SERVER}/Aeromegh/user/pgSubscription`,
  GET_ALL_SUBSCRIPTIONS = `${SERVER}/AeroMegh/user/pgSubscription`,
  CANCEL_SUBSCRIPTION = `${SERVER}/razorpay/cancelSubscription`,
  UPDATE_SUBSCRIPTION = `${SERVER}/AeroMegh/user/pgSubscription`,
  VIEWER_HEARTBEAT = `${SERVER}/AeroMegh/viewersHeartbeat/tick`,
  GET_ALL_PROJECTS = `${SERVER}/AeroMegh/AeroCapture/projects`,
  GET_PLANS = `${SERVER}/AeroMegh/AeroCapture/plans`,
  GET_IMAGE_COLLECTIONS = `${SERVER}/AeroMegh/AeroCapture/imageCollections`,
  ADMIN = "da7b31ee-4fc6-4d4c-a870-9673dc1d3a92",
  ADD_IMAGE_COLLECTION_SET = `${SERVER}/AeroMegh/AeroCapture/addImageCollection`,
  DELETE_IMAGES_FROM_COLLECTION = `${SERVER}/AeroMegh/AeroCapture/images/delete`,
  DELETE_AEROCAPTURE_PLAN = `${SERVER}/AeroMegh/AeroCapture/plans`,
  DELETE_IMAGE_COLLECTION = `${SERVER}/AeroMegh/AeroCapture/imageCollections`,
  DELETE_AEROCAPTURE_PROJECT = `${SERVER}/AeroMegh/AeroCapture/projects`,
  GET_DRONE_LOCATION = `${SERVER}/AeroMegh/drone/liveLocation`,
  GET_IMAGE_COLLECTION_IMAGES = `${SERVER}/AeroMegh/AeroCapture/imageCollection/images`,
  REQUEST_SERVICE = `${SERVER}/AeroMegh/requestNewService`,
  AEROMEGH_PLANS = `${SERVER}/AeroMegh/SubModule/getAllSubPlans`,
  GET_PAYMENT_DETAILS = `${SERVER}/getpayment`,
  GET_ROLES = `${SERVER}/AeroMegh/teams/roles`,
  GET_PROJECT_ROLE = `${SERVER}/AeroMegh/teams/project/role`,
  GET_FEATURES_PERMISSIONS = `${SERVER}/AeroMegh/teams/roles/features`,
  GET_TEAM_MEMBERS = `${SERVER}/AeroMegh/AeroGCSEnterprise/getTeamMembers`,
  PROJECT_ACCESS = `${SERVER}/AeroMegh/teams/project/access`,
  INVITE_USER_TO_TEAM = `${SERVER}/AeroMegh/AeroGCSEnterprise/inviteUser`,
  GET_TEAMS_LIST = `${SERVER}/AeroMegh/teams`,
  GET_ASSIGN_DATA = `${SERVER}/AeroMegh/teamAssignedData`,
  GET_ACCESS_PROJECT_LIST = `${SERVER}/AeroMegh/acccessProjects`,
  DELETE_TEAM_MEMBER = `${SERVER}/AeroMegh/AeroGCSEnterprise/deleteMember`,
  GET_PROJECT_DATA = `${SERVER}/AeroMegh/getProjectData`,
  SAVE_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/addNewSubPlan`,
  DELETE_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/deleteSubPlan`,
  UPDATE_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/updateSubPlan`,
  GET_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/getActiveSubPlans`,
  GET_ALL_SUBSCRIPTION_PLANS = `${SERVER}/AeroMegh/SubModule/getAllSubPlans`,
  SAVE_SUBSCRIPTION_ITEM = `${SERVER}/AeroMegh/SubModule/addNewSubItem`,
  DELETE_SUBSCRIPTION_ITEM = `${SERVER}/AeroMegh/SubModule/deleteSubItem`,
  UPDATE_SUBSCRIPTION_ITEM = `${SERVER}/AeroMegh/SubModule/updateSubItem`,
  GET_SUBSCRIPTION_ITEM = `${SERVER}/AeroMegh/SubModule/getAllSubItems`,
  ADD_SUBSCRIPTION_PLAN_ITEM = `${SERVER}/AeroMegh/SubModule/addSubPlanItem`,
  GET_SUBSCRIPTION_PLAN_ITEM = `${SERVER}/AeroMegh/SubModule/getSubPlanItems`,
  UPDATE_SUBSCRIPTION_PLAN_ITEM = `${SERVER}/AeroMegh/SubModule/updateSubPlanItem`,
  DELETE_SUBSCRIPTION_PLAN_ITEM = `${SERVER}/AeroMegh/SubModule/deleteSubPlanItem`,
  ADD_USER_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/addNewUserSubPlan`,
  UPDATE_USER_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/updateUserSubPlan`,
  CANCEL_USER_SUBSCRIPTION_PLAN = `${SERVER}/AeroMegh/SubModule/cancelUserSubPlan`,
  GCS_INSTALLATION_COUNT = `${SERVER}/Aeromegh/gcscount`,
  GET_AEROGCS_STATISTICS = `${SERVER}/AeroMegh/AeroGCSEnterprise/getAllFlightsStatistics`,
  GET_AEROGCS_FLIGHTS_WITH_PILOTS = `${SERVER}/AeroMegh/AeroGCSEnterprise/getFlightDataWithPilots`,
  GET_PLAN_ON_MAP = `${SERVER}/AeroMegh/AeroGCSEnterprise/getPlanOnMap`,
  GET_USER_SUBSCRIPTION_PLANS = `${SERVER}/AeroMegh/SubModule/getUserSubscriptionDetails`,
  GET_VMS = `${SERVER}/AeroMegh/getAllVms`,
  CHANGE_VM_STATUS = `${SERVER}/AeroMegh/changeVmStatus`,
  ADD_NEW_VM = `${SERVER}/AeroMegh/addVm`,
  UPDATE_VM = `${SERVER}/AeroMegh/updateVm`,
  GET_SYSTEM_STATS = `${SERVER}/AeroMegh/AeroGCSEnterprise/stats`,
  GLOBAL_SEARCH = `${SERVER}/Aeromegh/globalSearch`,
  DUPLICATE_IMAGE_SET = `${SERVER}/AeroMegh/copyCollection`,
  ACCEPT_INVITE = `${SERVER}/AeroMegh/shareAssets/acceptInvite`,
  GET_USAGE_STATISTICS = `${SERVER}/AeroMegh/usageStats`,
  USER_AUTH_OTP = `${SERVER}/userOTP`,
  CHANGE_PHONE_NUMBER_REQ_OTP = `${SERVER}/changePhoneNumberReqOTP`,
  UPDATE_NUMBER_WITH_OTP = `${SERVER}/updateNumberWithOTP`,
  USER_VERIFY_OTP = `${SERVER}/verifyUserOTP`,
  GET_OTP_TIMER = `${SERVER}/timerOTP`,
  UPDATE_TWO_STEP_AUTH = `${SERVER}/AeroMegh/user/otp`,
  ADD_SURVEY_DETAILS = `${SERVER}/AeroMegh/Analytic/addSurveyRecord`,
  CHECK_SURVEY_STATUS = `${SERVER}/AeroMegh/Analytic/checkUserSurveyStatus`,
  DOWNLOAD_SURVEY_RECORDS = `${SERVER}/AeroMegh/Analytic/getUserSurveyRecords`,
  ADD_USER_ANALYTIC = `${SERVER}/AeroMegh/Analytic/addUserAnalytic`,
  GET_USER_ANALYTICS = `${SERVER}/AeroMegh/Analytic/getUserAnalytic`,
  GET_USER_PAGE_ANALYTICS = `${SERVER}/AeroMegh/Analytic/getUserPageAnalytic`,
  UPDATE_PAGE_ANALYTICS = `${SERVER}/AeroMegh/Analytic/updateOutTime`,
  GET_USER_STATISTICS = `${SERVER}/AeroMegh/Analytic/getUserStats`,
  GET_USER_SIGNUP_DATA = `${SERVER}/AeroMegh/Analytic/users`,
  DOWNLOAD_SIGNUP_RECORDS = `${SERVER}/AeroMegh/Analytic/usersdata`,
  ADD_USER_CUSTOMER = `${SERVER}/AeroMegh/AeroGCSEnterprise/userCustomer`,
  UPDATE_USER_CUSTOMER = `${SERVER}/AeroMegh/AeroGCSEnterprise/userCustomer`,
  GET_ALL_USER_CUSTOMER = `${SERVER}/AeroMegh/AeroGCSEnterprise/userCustomer`,
  DELETE_USER_CUSTOMER = `${SERVER}/AeroMegh/AeroGCSEnterprise/userCustomer`,
  ADD_USER_CUSTOMER_DRONE_LEASE = `${SERVER}/AeroMegh/AeroGCSEnterprise/userCustomerDroneLease`,
  GET_ALL_USER__DRONES_LEASE = `${SERVER}/AeroMegh/AeroGCSEnterprise/userCustomerDroneLease`,
  DELETE_USER_CUSTOMER_DRONE_LEASE = `${SERVER}/AeroMegh/AeroGCSEnterprise/userCustomerDroneLease`,
  UPDATE_USER_CUSTOMER_DRONE_LEASE = `${SERVER}/AeroMegh/AeroGCSEnterprise/userCustomerDroneLease`,
  ADD_REMOVE_DRONE_LEASE = `${SERVER}/AeroMegh/AeroGCSEnterprise/addRemoveDroneLease`,
  UPDATE_DRONE_RENT = `${SERVER}/AeroMegh/AeroGCSEnterprise/droneRent`,
  GET_LEASE_STAT = `${SERVER}/AeroMegh/AeroGCSEnterprise/leaseStat`,
  ADD_DRONE_LEASE_INVOICE = `${SERVER}/AeroMegh/AeroGCSEnterprise/leaseInvoice`,
  GET_ALL_LEASE_INVOICES = `${SERVER}/AeroMegh/AeroGCSEnterprise/leaseInvoice`,
  UPDATE_LEASE_INVOICES = `${SERVER}/AeroMegh/AeroGCSEnterprise/leaseInvoice`,
  DELETE_LEASE_INVOICE = `${SERVER}/AeroMegh/AeroGCSEnterprise/leaseInvoice`,
  SHARE_INVOICE_PDF = `${SERVER}/AeroMegh/AeroGCSEnterprise/leaseInvoiceShare`,
  ADD_SPRAYING_PLOT = `${SERVER}/AeroMegh/AeroGCSEnterprise/sprayingPlot`,
  UPDATE_SPRAYING_PLOT = `${SERVER}/AeroMegh/AeroGCSEnterprise/sprayingPlot`,
  GET_ALL_SPRAYING_PLOTS = `${SERVER}/AeroMegh/AeroGCSEnterprise/sprayingPlot`,
  DELETE_SPRAYING_PLOT = `${SERVER}/AeroMegh/AeroGCSEnterprise/sprayingPlot`,
  GET_SPRAYING_PLOT_CORDINATES = `${SERVER}/AeroMegh/AeroGCSEnterprise/getSprayingPlotCordinates`,
  GET_ALL_GREEN_SPRAY_REPORTS = `${SERVER}/AeroMegh/AeroGCSEnterprise/greenSprayReports`,
  DELETE_SPRAYING_REPORT = `${SERVER}/AeroMegh/AeroGCSEnterprise/greenSprayReport`,
  GET_GREEN_SPRAY_REPORT_STAT = `${SERVER}/AeroMegh/AeroGCSEnterprise/greenSprayReportStat`,
  ADD_GREEN_SEASON = `${SERVER}/AeroMegh/AeroGCSEnterprise/GCSGreenSeason`,
  UPDATE_GREEN_SEASON = `${SERVER}/AeroMegh/AeroGCSEnterprise/GCSGreenSeason`,
  GET_ALL_GREEN_SEASON = `${SERVER}/AeroMegh/AeroGCSEnterprise/GCSGreenSeason`,
  DELETE_GREEN_SEASON = `${SERVER}/AeroMegh/AeroGCSEnterprise/GCSGreenSeason`,
  LOGIN_USER = `${SERVER}/login`,
  RESET_PASSWORD = `${SERVER}/requestPasswordResetLink`,
  SIGNUP = `${SERVER}/signup`,
  GET_COUNTRY_COUNT = `${SERVER}/AeroMegh/Analytic/countryData`,
  GET_CUSTOM_STATS = `${SERVER}/AeroMegh/Analytic/getCustomStats`,
  GET_INDUSTRY_DATA = `${SERVER}/AeroMegh/Analytic/getIndustryData`,
  GET_NOTIFICATIONS = `${SERVER}/AeroMegh/notifications`,
  GENERATE_API_KEY = `${SERVER}/AeroMegh/generatePulicAPIKey`,
  GET_STORAGE_BY_SERVICES = `${SERVER}/Aeromegh/subscription/getStorageUsage`,
  INCIDENT_REPORT = `${SERVER}/AeroMegh/incidentReport`,
  INCIDENT_REPORTS = `${SERVER}/AeroMegh/incidentReports`,
  GET_ANALYTICS = `${SERVER}/AeroMegh/AeroGCSEnterprise/getAnalyticsForAdmin`,
  DELETE_FLIGHT_DATA = `${SERVER}/AeroMegh/AeroGCSEnterprise/deleteFlightData`,
  DOWNLOAD_DRONE_CSV = `${SERVER}/AeroMegh/AeroGCSEnterprise/drones/csv`,
  ADD_DRONE_ADD_ON = `${SERVER}/AeroMegh/SubModule/admin/add/addOnDroneSubPlan`,
  UPDATE_DRONE_ADD_ON = `${SERVER}/AeroMegh/SubModule/admin/update/addOnDroneSubPlan`,
  GET_ALL_DRONE_LOCATIONS = `${SERVER}/AeroMegh/allDroneLocations`,
  GET_DRONE_FLIGHT_LOCATIONS = `${SERVER}/AeroMegh/droneFlightLocations`,
  GET_ADMIN_STATS = `${SERVER}/AeroMegh/admin/stats`,
  GET_CROP_AND_AGROCHEMICALS = `${SERVER}/AeroMegh/admin/allCrops`,
  DRONE_ACCESS = `${SERVER}/AeroMegh/teams/drone/access`,
  ADMIN_EMAIL_LOGS = `${SERVER}/AeroMegh/admin/emailLogs`

function getCookie(cname) {
  return cookie.get(cname);
}
var response, token;
var SESSION_ID_CHANGED = false;
const sessionCheck = async () => {
  var cookie = getCookie("session_IAMAero");
  if (!cookie || cookie === "undefined") return Promise.reject("Error");
  const options = {
    method: "POST",
    body: JSON.stringify({
      session_id: cookie,
    }),
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: "Bearer a0caa289-2863-4f70-9907-367b6dc084d6",
    },
  };

  const res = await fetch(SESSION_CHECK, options);
  return await res.json();
};

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

const getAllUsers = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(GET_ALL_USERS, options);
  return await res.json();
};

const updatePassword = async (oldPassword, newPassword, token) => {
  let bs64 = Buffer.from(`${oldPassword}:${newPassword}`).toString("base64");
  const options = {
    method: "PUT",
    body: JSON.stringify({ passwords: bs64 }),
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return fetch(UPDATE_PASSWORD, options);
};

export const editPassword = async (oldPassword, newPassword) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  const password = await updatePassword(
    oldPassword,
    newPassword,
    response.access_token
  );

  let ret = await password.json();
  if (ret.status_code == 1) {
    return Promise.reject("Incorrect current Password");
  } else {
    return ret;
  }
};

export const getUsers = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const users = await getAllUsers(token);

  return await users;
};

const userSearch = async (token, username) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${USER_SEARCH}/${username}`, options);
  return await res.json();
};

export const getUserType = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
    return sleep(500).then(() => userType());
  } else {

    return sleep(500).then(() => userType());
  }
};

const getRegistrationStatus = async () => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Basic SUFNQWVybzpwZHJsMjAyMA==`,
    },
  };

  const res = await fetch(GET_REGISTRATION_STATUS, options);
  return await res.json();
};

const getUserRole = (role) => {
  switch (role) {
    case MANUFACTURER:
      return "manufacturer";
    case CUSTOMER:
      return "customer";
    case ADMIN:
      return "admin";
  }
};

const userType = async () => {

  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }


  token = response.access_token;


  var res = await userSearch(token, response.username);


  var role = res.users[0].role_id;

  if (role === MANUFACTURER) {
    const status = await getRegistrationStatus();
    const { registered } = status.mfg_registration_status[0];

    return Promise.resolve([registered, getUserRole(role), res.users[0]]);
  }

  return Promise.resolve([2, getUserRole(role), res.users[0]]);
};

export const deleteDrone = async (droneId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");

  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${DRONE}/${droneId}`, options);
  return res;
};

export const sendOtpForDeleteLogs = async (droneId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");

  const options = {
    method: "POST",
    body: JSON.stringify({ droneId: droneId }),
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(OTP_FOR_DELETE_LOGS, options);
  return res;
}

export const verifyOtpAndDeleteDroneDataWithDrone = async (droneId, otp) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");

  const options = {
    method: "POST",
    body: JSON.stringify({ droneId: droneId, otp: otp }),
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${VARIFY_OTP_AND_DELETE_DRONE}`, options);
  return res;
};


const ownedDrones = async (token, organizationID, rpasModel) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${GET_DRONES_OWNED}/?organizationID=${organizationID}&rpasModel=${rpasModel}`,
    options
  );
  return await res.json();
};

export const getOwnedDrones = async (organizationID, rpasModel) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;


  if (token === "") return Promise.reject("error");

  const res = await ownedDrones(token, organizationID, rpasModel);


  return new Promise((resolve, reject) => {
    if (res.model) {
      return resolve(res);
    }
    return reject(new Error("cannot fetch getDronesOwned`"));
  });
};

export const getAllDrones = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }


  if (token === "") return Promise.reject("error");

  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${response.access_token}`,
    },
  };

  const raw_res = await fetch(`${ALL_DRONES}`, options);
  const res = await raw_res.json();

  let newOrganizations = {};
  res.organizations.forEach((organization) => {
    newOrganizations[organization.id] = organization.name;
  });
  res.organizations = newOrganizations;
  let modelNames = res.models.map((model) => model.model_name);
  res.drones = res.drones.filter(
    (drone) => modelNames.indexOf(drone.model) != -1
  );


  return new Promise((resolve, reject) => {
    return resolve(res);
  });
};

export const logMeOut = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }


  if (token === "") return Promise.reject("error");

  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer e20fa126-7a9c-4fb1-bcb4-ce25e14e6e94`,
    },
  };

  const raw_res = await fetch(
    `${SERVER}/logout/?user=${response.username}`,
    options
  );
  const res = await raw_res.json();



  return new Promise((resolve, reject) => {
    return resolve(res);
  });
};

export const getUserSubbedServices = async (targetUser) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }


  if (token === "") return Promise.reject("error");

  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${response.access_token}`,
    },
  };

  let raw_res = null;
  if (targetUser) {
    raw_res = await fetch(
      `${SERVER}/AeroMegh/subbedServices?user=${targetUser}`,
      options
    );
  } else {
    raw_res = await fetch(`${SERVER}/AeroMegh/subbedServices`, options);
  }

  const apiResponse = await raw_res.json();
  const res = apiResponse.services;

  const images = require.context("./", true);
  res.forEach((service) => {
    service.redirectURL = service.redirecturl;
    // service.image = images("./" + service.image);
  });
  const resp = apiResponse.allServices;
  return new Promise((resolve, reject) => {
    return resolve([res, resp]);
  });
};


export const sendFeedback = async (subject, body) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") {
    return Promise.reject("Error");
  }
  const options = {
    method: "POST",
    body: JSON.stringify({
      subject,
      body,
    }),
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const apiResponse = await fetch(FEEDBACK, options);
  const res = await apiResponse.json();

  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res.message);
    }
    return reject(res);
  });
};

const UserEdit = async (token, user) => {
  const options = {
    method: "PUT",
    body: JSON.stringify({
      user: user,
    }),
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(USER_EDIT, options);

  return await res.json();
};

// get User details API
export const getUser = async (userid, isAdmin) => {

  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${USER_EDIT}/${userid}?isAdmin=${isAdmin}`, options);
  return await res.json();
};

export const Useredit = async (user) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await UserEdit(token, user);



  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res.message);
    } else {
      return reject(res);
    }
  });
};

const UserDelete = async (token, user) => {
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(USER_EDIT + "/" + user.userid, options);
  return await res.json();
};

export const deleteUser = async (user) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") {
    return Promise.reject("Error");
  }

  const res = await UserDelete(token, user);

  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res.message);
    }
    return reject(res);
  });
};

const get_users = async (token, searchString, startIndex, pageSize, sortBy, sort, status, fromDate, toDate) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  let sorting = sortBy && sort ? `&sortBy=${sortBy}&sort=${sort}` : ''
  let queryStr = `?status=${status}&fromDate=${fromDate}&toDate=${toDate}${sorting}`
  const res = await fetch(
    `${GET_USERS}/${encodeURIComponent(searchString)}/${startIndex}/${pageSize}${queryStr}`,
    options
  );
  return await res.json();
};

export const users = async (searchString, startIndex, pageSize, sortBy, sort, status, fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_users(token, searchString, startIndex, pageSize, sortBy, sort, status, fromDate, toDate);


  return new Promise((resolve, reject) => {
    if (res.users) {
      return resolve(res);
    }
    return reject(Error("cannot fetch users"));
  });
};

export const postServicesAssignment = async (services, userid) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }


  if (token === "") return Promise.reject("error");

  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${response.access_token}`,
    },
    body: JSON.stringify(services),
  };

  const raw_res = await fetch(
    `${SERVER}/AeroMegh/subbedServices/${userid}`,
    options
  );
  const res = await raw_res.json();

  return new Promise((resolve, reject) => {
    if (res.message == "services updated successfully") {
      return resolve(res.message);
    }
    return reject(res);
  });
};

const touchUserAeromeghLoginCallAPI = async (token) => {
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(USER_AEROMEGH_LOGIN_DIRTY, options);
  return await res.json();
};

export const touchUserAeromeghLogin = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") {
    return Promise.reject("Error");
  }

  const res = await touchUserAeromeghLoginCallAPI(token);

  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res.message);
    }
    return reject(res);
  });
};

const get_gcs_drones = async (droneId) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${GCS_DRONES}${droneId ? `?droneId=${droneId}` : ''}`, options);
  return await res.json();
};

export const gcsDrones = async (droneId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_gcs_drones(droneId);

  return new Promise((resolve, reject) => {
    if (res.drones) {
      return resolve(res);
    }
    return reject(Error("cannot fetch drones"));
  });
};

export const getGCSDroneFlightErrorLogs = async (params) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      authorization: `Bearer ${token}`,
    },
  };
  const queryString = Object.keys(params)
    .filter(key => params[key] !== undefined && params[key] !== null)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');
  const baseURL = `${SERVER}/Aeromegh/GCSDrones/flightErrorLogs`
  const fullURL = `${baseURL}?${queryString}`;
  const res = await fetch(fullURL, options)

  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      const data = await res.json();
      if (data.errorLogs) {
        return resolve(data);
      } else return reject(data)
    }
    return reject(await res.json());
  });
};

export const getDronesErrorCount = async (drone_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      authorization: `Bearer ${token}`,
    },
  };


  const res = await fetch(`${SERVER}/Aeromegh/GCSDrones/droneErrorCount${drone_id ? `?droneId=${drone_id}` : ""}`, options)

  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      const data = await res.json();
      if (data) {
        return resolve(data);
      } else return reject(data)
    }
    return reject(await res.json());
  });
};

export const getAllDronesErrorCount = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      authorization: `Bearer ${token}`,
    },
  };


  const res = await fetch(`${SERVER}/Aeromegh/GCSDrones/allDroneErrorCount`, options)

  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      const data = await res.json();
      if (data) {
        return resolve(data);
      } else return reject(data)
    }
    return reject(await res.json());
  });
};
export const downloadDronesCSV = async (filter) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  return new Promise(async (resolve, reject) => {
    try {

      fetch(`${DOWNLOAD_DRONE_CSV}?teamId=${await getActiveTeamId()}${filter?.searchString ? `&searchString=${filter?.searchString}` : ""}${filter?.fromDateTime ? `&fromDateTime=${filter?.fromDateTime}` : ""}${filter?.toDateTime ? `&toDateTime=${filter?.toDateTime}` : ""}${filter?.orderBy ? `&orderBy=${filter?.orderBy}` : ""}${filter?.orderType ? `&orderType=${filter?.orderType}` : ""}${filter?.pageSize ? `&pageSize=${filter?.pageSize}` : ""}${filter?.pageNumber ? `&pageNumber=${filter?.pageNumber}` : ""}`, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            link.download = `drones.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : reject("File not found"))
    } catch (err) {
      console.log(err)
    }
  });
};

const delete_gcs_drones = async (droneID) => {
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(GCS_DRONES + "/" + droneID, options);
  return await res.json();
};

export const removeGCSDrones = async (droneID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await delete_gcs_drones(droneID);

  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res);
    }
    return reject(res);
  });
};

const getAllDronesStatus = async (droneId) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${ALL_DRONES_STATUS}${droneId ? `?droneId=${droneId}` : ''}`, options);
  return await res.json();
};

export const GetAllDronesStatus = async (droneId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await getAllDronesStatus(droneId);

  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject(res);
  });
};

const adminGetAllDronesStatus = async (droneId, userid) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${ADMIN_ALL_DRONES_STATUS}${userid ? `?userid=${userid}` : ''}${droneId && userid ? `&droneId=${droneId}` : droneId ? `?droneId=${droneId}` : ''}`, options);
  return await res.json();
};

export const adminGetAllDronesStatusAPI = async (droneId, userid) => {
  console.log(droneId, userid);

  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await adminGetAllDronesStatus(droneId, userid);

  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject(res);
  });
};

const check_Plan = async (token, projectName, planName, projectId) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${IS_PLAN_EXIST}/${encodeURIComponent(projectId)}/${encodeURIComponent(projectName)}/${encodeURIComponent(
      planName
    )}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

export const checkPlan = async (projectName, planName, projectId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await check_Plan(token, projectName, planName, projectId);


  return new Promise((resolve, reject) => {

    if (res) {
      return resolve(res);
    }
    return reject("cannot fetch plan");
  });
};

const check_aerostack_Plan = async (token, projectName, planName) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    GET_AEROSTACK_PLAN +
    "?projectName=" +
    projectName +
    "&planName=" +
    planName +
    "&teamId=" +
    await getActiveTeamId(),
    options
  );
  return await res.json();
};

// get plan
export const getPlan = async (projectName, planName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await check_aerostack_Plan(token, projectName, planName);

  return new Promise((resolve, reject) => {

    if (res) {
      return resolve(res);
    }
    return reject("cannot fetch plan");
  });
};

const get_latest_plans = async (token, searchString, pageSize, pageNumber, orderBy, orderType, teamId) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let url = `${GET_LATEST_PLANS}/${encodeURIComponent(searchString)}/?teamId=${teamId}${pageNumber ? `&pageNumber=${pageNumber}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}${orderBy ? `&orderBy=${orderBy}` : ''}${orderType ? `&orderType=${orderType}` : ''}`
  const res = await fetch(url, options);

  return await res.json();
};

//get latest plans
export const getLatestPlans = async (searchString, pageSize, pageNumber, orderBy, orderType, teamId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_latest_plans(token, searchString, pageSize, pageNumber, orderBy, orderType, teamId);

  return new Promise((resolve, reject) => {

    if (res) {
      return resolve(res);
    }
    return reject("cannot fetch plan");
  });
};

const get_flights = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(GET_FLIGHTS, options);
  return await res.json();
};

//get latest flight logs
export const getFlights = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_flights(token);

  return new Promise((resolve, reject) => {
    if (res) {
      return resolve(res);
    }
    return reject("cannot fetch plan");
  });
};

const get_weekly_flight_count = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(GET_WEEKLY_FLIGHT_COUNT, options);
  return await res.json();
};

//get weekly flight count
export const getWeeklyFlightsCount = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_weekly_flight_count(token);
  // 
  return new Promise((resolve, reject) => {
    if (res) {
      return resolve(res);
    }
    return reject("cannot fetch plan");
  });
};

const upload_plan = async (token, droneId, projectName, planName) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${UPLOAD_PLAN}droneID=${droneId}&projectName=${projectName}&planName=${planName}`,
    options
  );
  return await res.json();
};

//upload plan
export const uploadPlan = async (droneId, projectName, planName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await upload_plan(token, droneId, projectName, planName);
  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot fetch plan");
  });
};

const flight_logs_by_drone_id = async (
  token,
  droneId,
  fromDate,
  toDate,
  startIndex,
  pageSize
) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_FLIGTH_LOGS_BY_DRONE_ID}/${droneId}/${fromDate}/${toDate}/${startIndex}/${pageSize}`;


  const res = await fetch(d, options);
  return await res.json();
};

//get flight logs by drone id
export const getFlightLogsByDroneId = async (
  droneId,
  fromDate,
  toDate,
  startIndex,
  pageSize
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await flight_logs_by_drone_id(
    token,
    droneId,
    fromDate,
    toDate,
    startIndex,
    pageSize
  );
  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot fetch logs");
  });
};

//getLogs:
const get_log = async (
  token,
  flightid,
  keys,
  pageSize,
  pageNumber,
  orderBy,
  orderType
) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${GET_LOG}/${pageSize}/${pageNumber}?flightID=${flightid}&keys=${keys}${orderBy ? `&orderBy=${orderBy}` : ''}${orderType ? `&orderType=${orderType}` : ''}`,
    options
  );
  return await res.json();
};

export const log = async (
  flightid,
  keys,
  pageSize,
  pageNumber,
  orderBy,
  orderType
) => {

  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_log(
    token,
    flightid,
    keys,
    pageSize,
    pageNumber,
    orderBy,
    orderType
  );


  return new Promise((resolve, reject) => {
    if (res.logs) {
      return resolve(res);
    }
    return reject("cannot fetch logs");
  });
};

const get_drone_ports = async () => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(GET_DRONE_PORTS, options);
  return await res.json();
};

export const getdroneports = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_drone_ports();

  return new Promise((resolve, reject) => {
    if (res.ports) {
      return resolve(res);
    }
    return reject(Error("cannot fetch ports"));
  });
};

const get_flight_details = async (flightID, getLogs, pageSize, pageNumber, isFetchFlightData, isAdmin) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    FLIGHT_DETAILS +
    `?teamId=${await getActiveTeamId()}&flightID=${flightID}${isAdmin ? "&isAdmin=true" : ""}${getLogs ? "&logs=true" : ""}${isFetchFlightData ? "&isFetchFlightData=true" : ""}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
    options
  );
  return await res.json();
};

export const getFlightDetails = async (
  flightID,
  getLogs,
  pageSize,
  pageNumber,
  isFetchFlightData,
  isAdmin
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_flight_details(flightID, getLogs, pageSize, pageNumber, isFetchFlightData, isAdmin);

  return new Promise((resolve, reject) => {
    if (res.flight) {
      return resolve(res);
    }
    return reject(Error("cannot fetch ports"));
  });
};

const admin_get_flight_details = async (flightID) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    ADMIN_FLIGHT_DETAILS +
    `?flightID=${flightID}`,
    options
  );
  return await res.json();
};

export const adminGetFlightDetails = async (flightID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await admin_get_flight_details(flightID);

  return new Promise((resolve, reject) => {
    if (res.flight) {
      return resolve(res);
    }
    return reject(Error("cannot fetch ports"));
  });
};

const delete_project = async (token, projectName) => {
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(DELETE_PROJECT + `?project=${projectName}`, options);
  return await res.json();
};

//delete project by project name
export const deleteProject = async (projectName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await delete_project(token, projectName);

  return new Promise((resolve, reject) => {
    if (res.result) {
      return resolve(res);
    }
    return reject(Error("cannot delete project"));
  });
};

const delete_plan = async (token, projectName, planName) => {
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    DELETE_PLAN + `?project=${projectName}&plan=${planName}&teamId=${await getActiveTeamId()}`,
    options
  );
  return res
};

//delete plan by project name and plan name
export const deletePlan = async (projectName, planName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await delete_plan(token, projectName, planName);

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res);
    }
    return reject(Error("Cannot delete plan"));
  });
};

export const createPlan = async (plan) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      ...plan,
      teamId: await getActiveTeamId()
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_PLAN}?teamId=${await getActiveTeamId()}`, options);

  return await res.json();
};

//edit plan
export const editPlan = async (plan) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      ...plan,
      teamId: await getActiveTeamId()
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${EDIT_PLAN}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
};

export const createProject = async (project) => {

  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(project),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_PROJECT}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
};

const get_all_plans = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(PLANS, options);
  return await res.json();
};

//delete plan by project name and plan name
export const getAllPlans = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");
  const res = await get_all_plans(token);

  return new Promise((resolve, reject) => {
    if (res.plans) {
      return resolve(res);
    }
    return reject(Error("cannot delete project"));
  });
};

const get_flight_images = async (
  token,
  flightId,
  imagesPerPage,
  currentPage
) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  let a = `${GET_FLIGHT_IMAGES}/${flightId}/${imagesPerPage}/${currentPage}`;


  const res = await fetch(
    `${GET_FLIGHT_IMAGES}/${flightId}/${imagesPerPage}/${currentPage}`,
    options
  );
  return await res.json();
};

export const getFlightImages = async (flightId, imagesPerPage, currentPage) => {

  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_flight_images(
    token,
    flightId,
    imagesPerPage,
    currentPage
  );

  return new Promise((resolve, reject) => {
    if (res.images) {
      return resolve(res);
    }
    return reject(Error("cannot fetch flight images"));
  });
};

const get_users_stats = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(USER_STATS, options);
  return await res.json();
};

export const getUsersStats = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");
  const res = await get_users_stats(token);

  return new Promise((resolve, reject) => {
    if (res) {
      return resolve(res);
    }
    return reject(Error("cannot delete project"));
  });
};

const latest_drone_stream_key = async (token, droneID) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(DRONE_LATEST_STREAM_KEY + "/" + droneID, options);
  return await res.json();
};

export const latestDroneStreamKey = async (droneID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await latest_drone_stream_key(token, droneID);

  return new Promise((resolve, reject) => {
    if (res.streamKey) {
      return resolve(res);
    }
    return reject(Error("cannot delete project"));
  });
};

const get_flights_stat_data = async (token, graphUnit, fromDate, toDate) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(
    `${GET_FLIGHTS_STAT_DATA}/${graphUnit}/${fromDate}/${toDate}`,
    options
  );

  return await res.json();
};

export const flightsStatData = async (graphUnit, fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_flights_stat_data(token, graphUnit, fromDate, toDate);

  return new Promise((resolve, reject) => {
    if (res.data) {
      return resolve(res);
    }
    return reject(Error("cannot get flight stat data"));
  });
};

const get_all_flights_completed_hours = async (token, searchString, orderBy, orderType, pageNumber, pageSize) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let url = `${GET_ALL_FLIGHTS_COMPLETED_HOURS}/?searchString=${encodeURIComponent(searchString)}${orderBy ? `&orderBy=${orderBy}` : ''}${orderType ? `&orderType=${orderType}` : ''}${pageNumber ? `&pageNumber=${pageNumber}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}`
  const res = await fetch(url, options);

  return await res.json();
};

export const getAllFlightsCompletedHours = async (searchString, orderBy, orderType, pageNumber, pageSize) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("Error");

  const res = await get_all_flights_completed_hours(
    token,
    searchString,
    orderBy,
    orderType,
    pageNumber,
    pageSize
  );

  return new Promise((resolve, reject) => {
    if (res.drones) {
      return resolve(res);
    }
    return reject(Error("cannot get flight stat data"));
  });
};

const get_all_flights_logs = async (
  token,
  droneId,
  fromDate,
  toDate,
  startIndex,
  pageSize,
  type,
) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  let d = `${GET_ALL_FLIGHT_LOGS}/${droneId}/${fromDate}/${toDate}/${startIndex}/${pageSize}?type=${type}`;
  const res = await fetch(d, options);
  return await res.json();
};

//get all flights logs
export const getAllFlightsLogs = async (
  droneId,
  fromDate,
  toDate,
  startIndex,
  pageSize,
  type,
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_all_flights_logs(
    token,
    droneId,
    fromDate,
    toDate,
    startIndex,
    pageSize,
    type
  );

  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot fetch logs");
  });
};

const get_Flights_Replay = async (token, flightId, pageNumber, pageSize) => {
  try {
    const options = {
      method: "GET",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };

    let d = `${GET_REPLAY_FLIGHTS}?flightId=${flightId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const res = await fetch(d, options);
    return await res.json();
  } catch (err) {
    return { message: err }
  }
};

export const getFlightsReplay = async (flightId, pageNumber, pageSize) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;
  if (token === "") return Promise.reject("Error");

  const res = await get_Flights_Replay(token, flightId, pageNumber, pageSize);

  return new Promise((resolve, reject) => {
    if (res.message) {
      return reject(res);
    } else if (res.message != 'An internal server error occured while getting flight logs') {
      resolve(res)
    }

  });
};

const get_sharable_link_access = async (streamID) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      // authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_SHARABLE_LINK_ACCESS}/${streamID}`;
  const res = await fetch(d, options);
  return await res.json();
};

//get sharable link access
export const getSharableLinkAccess = async (streamID) => {
  if (cookie.get(streamID) != undefined) {
    let token = cookie.get(streamID);
    let data = { token: token };

    cookie.set(streamID, token, { path: "/", maxAge: 86400 });
    return data;
  } else {
    const res = await get_sharable_link_access(streamID);

    return new Promise((resolve, reject) => {
      if (res.message != "An internal server error has occured") {

        if (
          res.message == "sharable link access limit is over" ||
          res.message == "sharable link expired"
        ) {
          reject(res.message);
        } else {
          let sharableLinkToken = res.token;
          cookie.set(streamID, sharableLinkToken, { path: "/", maxAge: 86400 });
          return resolve(res);
        }
      }
      return reject("cannot get access");
    });
  }
};

const get_sharable_link_details = async (sharableLinkToken, streamID) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      // authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_SHARABLE_LINK_DETAILS}/${sharableLinkToken}/${streamID}`;
  const res = await fetch(d, options);
  return await res.json();
};

//get sharable link details
export const getSharableLinkDetails = async (streamID) => {
  let sharableLinkToken = cookie.get(streamID);
  if (sharableLinkToken != undefined) {
    const res = await get_sharable_link_details(sharableLinkToken, streamID);
    return new Promise((resolve, reject) => {
      if (res.message != "An internal server error has occured") {

        if (
          res.message == "no running flight found" ||
          res.message == "Invalid Request"
        ) {
          reject(res.message);
        } else {
          // let sharableLinkToken = res.token;
          // cookie.set("sharableLinkToken", sharableLinkToken, { path: "/", maxAge: 86400 });
          return resolve(res);
        }
      }
      return reject("cannot get details");
    });
  } else {
    return new Promise((resolve, reject) => {
      return reject("token expired");
    });
  }
};

const get_sharable_link = async (token, projectName, planName, flightID) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_SHARABLE_LINK}/${projectName}/${planName}/${flightID}`;
  const res = await fetch(d, options);
  return await res.json();
};

//get sharable link stream_id
export const getSharableLink = async (projectName, planName, flightID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_sharable_link(token, projectName, planName, flightID);
  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot update schedule");
  });
};

const activate_aeromegh_plan_subscription_order = async (token, invoiceID) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      invoiceID,
    }),
  };

  let d = `${AEROMEGH_PLAN_PURCHASE_ACTIVATE}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const activateAeroMeghPlanSubscriptionOrder = async (invoiceID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await activate_aeromegh_plan_subscription_order(token, invoiceID);
  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res);
    } else {
      return reject(
        "Failed to activate your subscription. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

const get_invoice = async (token, invoiceID) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_INVOICE}/${invoiceID}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const getInvoice = async (invoiceID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_invoice(token, invoiceID);
  return new Promise((resolve, reject) => {
    if (res.message == "FOUND") {
      return resolve(res);
    } else {
      return reject(
        "Failed to fetch invoice. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

const settle_payment = async (
  token,
  razorPayOrderID,
  razorPayPaymentID,
  razorPaySignature
) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      razorPayPaymentID: razorPayPaymentID,
      razorPaySignature: razorPaySignature,
      razorPayOrderID: razorPayOrderID,
    }),
  };

  let d = `${SETTLE_PAYMENT}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const settlePayment = async (
  razorPayOrderID,
  razorPayPaymentID,
  razorPaySignature
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await settle_payment(
    token,
    razorPayOrderID,
    razorPayPaymentID,
    razorPaySignature
  );
  return new Promise((resolve, reject) => {
    if (res.message == "Settled") {
      return resolve(res);
    } else {
      return reject(
        "Failed to verify payment. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

const get_my_invoices = async (token, pageSize, pageNumber) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_INVOICE}/${pageSize}/${pageNumber}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const getMyInvoices = async (pageSize, pageNumber) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_my_invoices(token, pageSize, pageNumber);
  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res);
    } else {
      return reject(
        "Failed to fetch invoices. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

const admin_get_users_invoices = async (
  token,
  userID,
  pageSize,
  pageNumber
) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${ADMIN_USER_INVOICES}/${userID}/${pageSize}/${pageNumber}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const adminGetUsersInvoices = async (userID, pageSize, pageNumber) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await admin_get_users_invoices(
    token,
    userID,
    pageSize,
    pageNumber
  );
  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res);
    } else {
      return reject(
        "Failed to fetch invoices. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

const admin_send_invoice_reminder = async (token, invoiceID) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${ADMIN_SEND_INVOICE_REMINDER}/${invoiceID}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const adminSendInvoiceReminder = async (invoiceID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await admin_send_invoice_reminder(token, invoiceID);
  return new Promise((resolve, reject) => {
    if (res.message == "SENT") {
      return resolve(res);
    } else {
      return reject(
        "Failed to send invoice reminder. Error: " +
        (res.error || res.message || "Unknown error")
      );
    }
  });
};

const get_countries = async () => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      // authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_COUNTRIES}`;
  const res = await fetch(d, options);
  return await res.json();
};

//get countries
export const getCountries = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_countries();
  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot get countries");
  });
};

const get_states = async (countryCode) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      // authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_STATES}/${countryCode}`;
  const res = await fetch(d, options);
  return await res.json();
};

//get states
export const getStates = async (countryCode) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_states(countryCode);
  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot get states");
  });
};


const get_aerostream_videos = async (token, limit, droneId) => {
  const options = {
    method: "GET",
    // body:JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_AEROSTREAM_VIDEOS}/${limit}${droneId ? `?droneId=${droneId}` : ''}`;
  const res = await fetch(d, options);

  return await res.json();
};

// get aerostream videos
export const getAeroStreamVideos = async (limit, droneId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_aerostream_videos(token, limit, droneId);

  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    } else {
      return reject("cannot get videos");
    }
  });
};

const get_aerostream_videos_inGCS = async (token, project_name, plan_name, limit) => {
  const options = {
    method: "GET",
    // body:JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_AEROSTREAM_VIDEOS_INGCS}/${project_name}/${plan_name}/${limit}`;
  const res = await fetch(d, options);

  return await res.json();
};

export const getAeroStreamVideosInGCS = async (project_name, plan_name, limit) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_aerostream_videos_inGCS(token, project_name, plan_name, limit);

  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot get videos");
  });
};

const delete_rad_video = async (token, flightID) => {
  const options = {
    method: "DELETE",
    // body:JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${DELETE_RAD_VIDEO}/${flightID}`;
  const res = await fetch(d, options);

  return await res.json();
};

// delete rad request video
export const deleteRADVideo = async (flightID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await delete_rad_video(token, flightID);

  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot delete video");
  });
};

const get_aerostream_single_video = async (token, flightID) => {
  const options = {
    method: "GET",
    // body:JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_AEROSTREAM_SINGLE_VIDEO}/${flightID}`;
  const res = await fetch(d, options);

  return await res.json();
};

// get aerostream single video videos
export const getAeroStreamSingleVideo = async (flightID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_aerostream_single_video(token, flightID);

  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot get video");
  });
};

const get_viewer_minutes_stats = async (token) => {
  const options = {
    method: "GET",
    // body:JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${VIEWER_MINUTES_STATS}`;
  const res = await fetch(d, options);

  return await res.json();
};

// get aerostream single video videos
export const getViewerMinutesStats = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_viewer_minutes_stats(token);

  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot get video");
  });
};

const get_aeromegh_plans = async (token) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${AEROMEGH_PLANS}`, options);
  return await res.json();
};

export const getAeromeghPlans = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_aeromegh_plans(token);
  return new Promise((resolve, reject) => {
    if (res.plans) {
      return resolve(res);
    } else {
      return reject(
        "Failed to get plans. Error: " +
        (res.error ||
          res.message ||
          (typeof res === "object" ? JSON.stringify(res) : res) ||
          "Unknown error")
      );
    }
  });
};

//GET_SUBSCRIPTION
const get_subscription = async (token, subscriptionID) => {
  const options = {
    method: "GET",
    // body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_SUBSCRIPTION}/${subscriptionID}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const getSubscription = async (subscriptionID) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_subscription(token, subscriptionID);

  return new Promise((resolve, reject) => {
    if (res.authentication_url) {
      return resolve(res);
    } else {
      return reject(
        "Failed to subscribe plans. Error: " +
        (res.error ||
          res.message ||
          (typeof res === "object" ? JSON.stringify(res) : res) ||
          "Unknown error")
      );
    }
  });
};

//GET_ALL_SUBSCRIPTIONS
const get_all_subscriptions = async (token) => {
  const options = {
    method: "GET",
    // body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${GET_ALL_SUBSCRIPTIONS}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const getAllSubscriptions = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_all_subscriptions(token);

  return new Promise((resolve, reject) => {
    if (res.subscriptions) {
      return resolve(res);
    } else {
      return reject(
        "Failed to subscribe plans. Error: " +
        (res.error ||
          res.message ||
          (typeof res === "object" ? JSON.stringify(res) : res) ||
          "Unknown error")
      );
    }
  });
};

export const cancelSubscription = async (userSubId, modify) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${CANCEL_SUBSCRIPTION}/${userSubId}${modify ? `?modifyPlan=${modify}` : ''}`, options);
  return await res.json();
};

//UPDATE_SUBSCRIPTION
const update_subscription = async (token, subID, data) => {
  const options = {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${UPDATE_SUBSCRIPTION}/${subID}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const updateSubscription = async (subID, data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await update_subscription(token, subID, data);

  return new Promise((resolve, reject) => {
    if (res.message == "UPDATED") {
      return resolve(res);
    } else {
      return reject(
        "Failed to subscribe plans. Error: " +
        (res.error ||
          res.message ||
          (typeof res === "object" ? JSON.stringify(res) : res) ||
          "Unknown error")
      );
    }
  });
};

//VIEWER_HEARTBEAT
const viewer_heartbeat = async (token, data) => {
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  let d = `${VIEWER_HEARTBEAT}`;
  const res = await fetch(d, options);
  return await res.json();
};

export const viewerHeartbeat = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await viewer_heartbeat(token, data);

  return new Promise((resolve, reject) => {
    if (res.message == "OK") {
      return resolve(res);
    } else {
      return reject(
        "Something went wrong. Error: " +
        (res.error ||
          res.message ||
          (typeof res === "object" ? JSON.stringify(res) : res) ||
          "Unknown error")
      );
    }
  });
};


//create aerocapture project
export const createNewProject = async (project) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(project),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_NEW_PROJECT}?teamId=${await getActiveTeamId()}`, options);
  if (res.status === 400) return ({
    error: "Project already exists with same name."
  })
  else if (res.status === 409) return ({
    error: "Project already exists with same name."
  })
  else return await res.json();
};

const get_all_projects = async (token, searchString, pageSize, startIndex, taskType, filterRole, serviceName, fromDate, toDate, orderBy, orderType) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  let d = `${GET_ALL_PROJECTS}/${searchString}/${startIndex}/${pageSize}?teamId=${await getActiveTeamId()}&taskType=${taskType}${filterRole ? `&filterRole=${filterRole}` : ""}&service=${serviceName}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${orderBy ? `&orderBy=${orderBy}` : ""}${orderType ? `&orderType=${orderType}` : ""}`;
  const res = await fetch(d, options);
  return await res.json();
};

//get all aerocapture projects
export const getAllProjects = async (searchString, pageSize, startIndex, taskType, filterRole, serviceName, fromDate, toDate, orderBy, orderType) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_all_projects(token, searchString, pageSize, startIndex, taskType, filterRole, serviceName, fromDate, toDate, orderBy, orderType);

  return new Promise((resolve, reject) => {
    if (res.message != "An internal server error has occured") {
      return resolve(res);
    }
    return reject("cannot fetch projects");
  });
};

const get_plans = async (
  token,
  projectName,
  searchString,
  pageSize,
  pageNumber,
  tasktype,
  projectId,
  fromDate,
  toDate,
  orderBy,
  orderType
) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  // let team = teamId ? teamId : undefined
  let type = tasktype ? tasktype : 0
  let d = `${GET_PLANS}/${encodeURIComponent(projectId)}/${encodeURIComponent(projectName)}/${encodeURIComponent(searchString)}/${pageNumber}/${pageSize}?teamId=${await getActiveTeamId()}&tasktype=${type}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${orderBy ? `&orderBy=${orderBy}` : ""}${orderType ? `&orderType=${orderType}` : ""}`;
  const res = await fetch(d, options);
  return res;
};

//get all aerocapture plans by project name
export const getPlans = async (
  projectName,
  searchString,
  pageSize,
  pageNumber,
  tasktype,
  projectId,
  fromDate,
  toDate,
  orderBy,
  orderType
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  const res = await get_plans(
    token,
    projectName,
    searchString,
    pageSize,
    pageNumber,
    tasktype,
    projectId,
    fromDate,
    toDate,
    orderBy,
    orderType
  );

  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json())
    else resolve([])
  });
};

//Get all task of AeroCapture
export const getImageCollections = async (project_id, plan_id, type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  if (type) {
    const res = await fetch(
      `${GET_IMAGE_COLLECTIONS}/${project_id}/${plan_id}?type=${type}&teamId=${await getActiveTeamId()}`,
      options
    );
    return await res.json();
  } else {
    const res = await fetch(
      `${GET_IMAGE_COLLECTIONS}/${project_id}/${plan_id}?teamId=${await getActiveTeamId()}`,
      options
    );
    return await res.json();
  }
};

export const createImageCollection = async (
  project_id,
  plan_id,
  collection_name
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      project_id: project_id,
      plan_id: plan_id,
      collection_name: collection_name,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_IMAGE_COLLECTION_SET}?teamId=${await getActiveTeamId()}`, options);
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else if (res.status == 409) {
      return resolve(res);
    } else {
      return reject(res.message);
    }
  });
};

//Get all task of AeroCapture
export const getImageCollectionImages = async (
  collection_id,
  searchString,
  pageSize,
  pageNumber
) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_IMAGE_COLLECTION_IMAGES}/${collection_id}/${encodeURIComponent(searchString)}/${pageSize}/${pageNumber}?teamId=${await getActiveTeamId()}`,
    options
  );
  // 
  return await res.json();
};

//Get all task of AeroCapture
export const deleteImages = async (collection_id, images, service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    body: JSON.stringify({
      images: images,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_IMAGES_FROM_COLLECTION}/${collection_id}?service=${service}&teamId=${await getActiveTeamId()}`,
    options
  );

  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      return resolve(res);
    }
    return reject("Cannot delete images");
  });
};

// deleteAerocapturePlan
export const deleteAerocapturePlan = async (plan_id, service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    // body: JSON.stringify({
    //   objects
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_AEROCAPTURE_PLAN}/${plan_id}?service=${service}&teamId=${await getActiveTeamId()}`, options);

  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else {
      const j = await res.json();
      return reject(j.error || j.message || "Unknown error");
    }
  });
};

// delete aerocapture task
export const deleteAerocaptureImageCollection = async (collection_id, service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    // body: JSON.stringify({
    //   objects
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_IMAGE_COLLECTION}/${collection_id}?service=${service}&teamId=${await getActiveTeamId()}`,
    options
  );
  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else {
      const j = await res.json();
      return reject(j.error || j.message || "Unknown error");
    }
  });
};

// delete aerocapture project
export const deleteAerocaptureProject = async (project_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    // body: JSON.stringify({
    //   objects
    // }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_AEROCAPTURE_PROJECT}/${project_id}`,
    options
  );


  return new Promise(async (resolve, reject) => {
    if (res.status == 200) {
      return resolve(res.json());
    } else {
      const j = await res.json();
      return reject(j.error || j.message || "Unknown error");
    }
  });
};

const get_drone_location = async (token, drone_id) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${GET_DRONE_LOCATION}/${drone_id}`, options);
  return await res.json();
};

export const getDroneLocation = async (drone_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }

  token = response.access_token;


  if (token === "") return Promise.reject("error");

  const res = await get_drone_location(token, drone_id);



  return new Promise((resolve, reject) => {
    if (res) {
      return resolve(res);
    }
    return reject(new Error("cannot fetch getDroneLocation`"));
  });
};

//teams starts here
export const getProjectRoleAPI = async (teamId, projectId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${GET_PROJECT_ROLE}/${teamId}/${projectId}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const getFeaturePermissionsAPI = async (teamId, roleId, features) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({ teamId, roleId, features }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${GET_FEATURES_PERMISSIONS}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
}

export const getRolesAPI = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${GET_ROLES}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const getTeamUsers = async (team_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_TEAM_MEMBERS}/${team_id}`, options);
  return await res.json();
};

export const getProjectAccessListAPI = async (teamId, projectId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${PROJECT_ACCESS}/${teamId}/${projectId}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const addProjectAccessAPI = async (projectId, teamId, roleId, username, notificationObj) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({ projectId, teamId, roleId, username, notificationObj }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(PROJECT_ACCESS, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const removeProjectAccessAPI = async (projectId, teamId, roleId, username, notificationObj) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    body: JSON.stringify({ projectId, roleId, notificationObj }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${PROJECT_ACCESS}/${teamId}/${projectId}/${username}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const updateProjectAccessAPI = async (projectId, teamId, username, roleId, notificationObj) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({ projectId, teamId, username, roleId, notificationObj }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(PROJECT_ACCESS, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const inviteUserToTeam = async (userMail, teamId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "user_name": userMail,
      "team_id": teamId
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(INVITE_USER_TO_TEAM, options);
  return await res;
};

export const getTeamsList = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(GET_TEAMS_LIST, options);
  return await res.json();
};

export const getAssigndData = async (memberUsername) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_ASSIGN_DATA}/${memberUsername}`, options);
  return await res.json();
};

export const getAccessProjectList = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_ACCESS_PROJECT_LIST}`, options);
  return await res.json();
};

export const deleteUserFromTeam = async (teamId, username) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    body: JSON.stringify({
      "team_id": teamId,
      "username": username
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(DELETE_TEAM_MEMBER, options);
  return await res;
}

export const request_service = async (serviceTitle) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${REQUEST_SERVICE}/${serviceTitle}`, options);

  return res.json();
};

// Get project releated data for performing delete operation
export const getProjectData = async (type, projectName, projectId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_PROJECT_DATA}/${type}/${projectName}/${projectId}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
}

//subscription plan APIs
export const getSubscriptionPlan = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_SUBSCRIPTION_PLAN}`, options);
  return await res.json();
}

//subscription plan APIs
export const getAllSubscriptionPlans = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_ALL_SUBSCRIPTION_PLANS}`, options);
  return await res.json();
}

export const saveSubscriptionPlan = async (plan_name, status, service_id, plan_type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "plan_name": plan_name,
      "status": status,
      "service_id": service_id,
      "plan_type": plan_type
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(SAVE_SUBSCRIPTION_PLAN, options);
  return await res.json();
};

export const updateSubscriptionPlan = async (plan_id, status, name, cost, discount, description, price_id, plan_type, discount_coupon_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "status": status,
      "cost": cost,
      "discount": discount,
      "description": description,
      "name": name ? name.trim() : name,
      "price_id": price_id ? price_id.trim() : price_id,
      "plan_type": plan_type ? plan_type.trim() : plan_type,
      "discount_coupon_id": discount_coupon_id
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_SUBSCRIPTION_PLAN}/${plan_id}`, options);
  return await res.json();
};

export const deleteSubscriptionPlan = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_SUBSCRIPTION_PLAN}/${id}`, options);
  return await res.json();
}

//subscription  item APIs
export const getSubscriptionItem = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    // body: JSON.stringify({
    //   "type": type,
    //   "data": data
    //   }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_SUBSCRIPTION_ITEM}`, options);
  return await res.json();
}

export const saveSubscriptionItem = async (item_name, units) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "item_name": item_name,
      "unit": units
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(SAVE_SUBSCRIPTION_ITEM, options);
  return await res;
};

export const updateSubscriptionItem = async (item_name, unit) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "item_name": item_name,
      "unit": unit
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(UPDATE_SUBSCRIPTION_ITEM, options);
  return await res;
};

export const deleteSubscriptionItem = async (item_name) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_SUBSCRIPTION_ITEM}/${item_name}`, options);
  return await res.json();
}

// subscription plan item APIs
export const getSubscriptionPlanItems = async (plan_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_SUBSCRIPTION_PLAN_ITEM}/${plan_id}`, options);
  return await res.json();
}

export const saveSubscriptionPlanItem = async (plan_id, item_id, limits) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "sub_id": plan_id,
      "item_id": item_id,
      "limit": limits
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(ADD_SUBSCRIPTION_PLAN_ITEM, options);
  return res;
};

export const updateSubscriptionPlanItem = async (plan_id, item_id, limit) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "sub_id": plan_id,
      "item_id": item_id,
      "limit": limit
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(UPDATE_SUBSCRIPTION_PLAN_ITEM, options);
  return await res.json();
};

export const deleteSubscriptionPlanItem = async (sub_id, item_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_SUBSCRIPTION_PLAN_ITEM}/${sub_id}/${item_id}`, options);
  return await res.json();
}

export const saveUserSubscriptionPlan = async (plan_id, expiry_date, user_id, type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "sub_plan_id": plan_id,
      "expiry_date": expiry_date,
      "user_id": user_id,
      "sub_type": type
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(ADD_USER_SUBSCRIPTION_PLAN, options);
  return res;
};

export const updateUserSubscriptionPlan = async (id, plan_id, expiry_date, user_id, type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "plan_id": plan_id,
      "expiry_date": expiry_date,
      "user_id": user_id,
      "sub_type": type
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_USER_SUBSCRIPTION_PLAN}/${id}`, options);
  return await res.json();
};

export const cancelUserSubscriptionPlan = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${CANCEL_USER_SUBSCRIPTION_PLAN}/${id}`, options);
  return res;
};


export const getGCSCount = async (startIndex, pageSize) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GCS_INSTALLATION_COUNT}` + "/" + startIndex + "/" + pageSize,
    options
  );
  return await res.json();
};

export const getAeroGCSDashboardStatistics = async (fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_AEROGCS_STATISTICS}?fromDate=${fromDate}&toDate=${toDate}`, options);
  return await res.json();
};

export const getAeroGCSFlightsWithPilots = async (droneId, pilot_id, fromDate, toDate, startIndex, pageSize) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_AEROGCS_FLIGHTS_WITH_PILOTS}?droneId=${droneId}&pilot_id=${pilot_id}&fromDate=${fromDate}&toDate=${toDate}&pageSize=${pageSize}&startIndex=${startIndex}`, options);
  return await res.json();
};

export const getUserSubscriptionPlan = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USER_SUBSCRIPTION_PLANS}`, options);
  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json())
    else reject(await res.json())
  })
}

export const getPaymentDetails = async (sessionId, type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_PAYMENT_DETAILS}?sessionId=${sessionId}&type=${type}`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200)
      resolve(await res.json());
    else
      reject(await res.json());
  })
}


export const getHistogram = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${AppConstants.tilesServer}/getMetadata/${data.taskId}/orthomosaic?sas=${data.sas}&blobContainer=${data.blobContainer}&algorithm=${data.algorithm}&filterType=${data.processTileType}&teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

export const getTilesBounds = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${AppConstants.tilesServer}/getTilesBounds/${data.taskId}/${data.tiffType}?sas=${data.sas}&blobContainer=${data.blobContainer}&teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

export const getPlansOnMap = async (project_name, project_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_PLAN_ON_MAP}/${project_id}/${project_name}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
};

// Get all VMs
export const getAllVms = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_VMS}`, options);
  return await res.json();
}


export const changeVMStatus = async (vmId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "vmId": vmId
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${CHANGE_VM_STATUS}`, options);
  return await res.json();
};

export const addNewVM = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_NEW_VM}`, options);
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};

export const updateVM = async (data, vmid) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_VM}/${vmid}`, options);
  return new Promise((resolve, reject) => {
    if (res.status == 200) {
      res.json().then(data => resolve(data))
    }
    else {
      res.json().then(data => reject(data))
    }
  })
};

export const duplicateImageSet = async (userContainer, old_id, new_id, service, filter_out_extensions) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({
      "srcCont": userContainer,
      "destCont": userContainer,
      "srcCol": old_id,
      "destCol": new_id,
      "service": service,
      "filter_out_extensions": JSON.stringify(filter_out_extensions) || ""
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DUPLICATE_IMAGE_SET}?teamId=${await getActiveTeamId()}`, options);
  return res;
};

export const globalSearch = async (searchString, filters) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      searchString: searchString,
      filters: filters,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(GLOBAL_SEARCH, options);
  return await res.json();
};

//User statistics API 
export const getUserStatistics = async (fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USAGE_STATISTICS}/${fromDate}/${toDate}`, options);
  return await res.json();
};

//Add user analytic record 
export const addUserAnalytic = async (pageName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_USER_ANALYTIC + "/" + pageName}`, options);
  return res.json()
};

//Update out time of a page
export const updateUserAnalytic = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_PAGE_ANALYTICS + "/" + id}`, options);
  return res
};

//Get user analytics 
export const getUserAnalytics = async (userId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USER_ANALYTICS + "/" + userId}`, options);
  return await res.json();
};

//Get user page analytics 
export const getUserPageAnalytics = async (userId, pageName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USER_PAGE_ANALYTICS + "/" + userId + "/" + pageName}`, options);
  return await res.json();
};

// Survey API
export const addUserSurvey = async (industry, role, teamSize, phone, country) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      industry,
      role,
      teamSize,
      phone,
      country
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_SURVEY_DETAILS}`, options);
  return await res.json();
};

// Survey API
export const checkSurveyStatus = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${CHECK_SURVEY_STATUS}`, options);
  return await res.json();
};

export const downloadSurveyRecords = async (searchString, sortBy, sort, status, fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    try {
      let sorting = sortBy && sort ? `&sortBy=${sortBy}&sort=${sort}` : ''
      let queryStr = `?status=${status}&fromDate=${fromDate}&toDate=${toDate}${sorting}`
      fetch(`${DOWNLOAD_SURVEY_RECORDS}/${encodeURIComponent(searchString)}/${queryStr}`, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            link.download = `EnterpriseUsers.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : reject("File not found"))
    }
    catch (error) {
      reject(error)
    }
  })
};

export const getUserSignupStatistics = async (industry, country, role, fromDate, toDate, graphUnit) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_USER_STATISTICS}/${industry}/${country}/${role}/${fromDate}/${toDate}/${graphUnit}`, options);
  return await res.json();
};

//Get user signupdata 
export const getUserSingupData = async (industry, country, role, fromDate, toDate, pageSize, startIndex) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_USER_SIGNUP_DATA}/${industry}/${country}/${role}/${fromDate}/${toDate}/${pageSize}/${startIndex}`,
    options
  );
  return await res.json();
}

//Download user signup data CSV
export const downloadUserRecords = async (industry, country, role, fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise((resolve, reject) => {
    try {
      fetch(`${DOWNLOAD_SIGNUP_RECORDS}/${industry}/${country}/${role}/${fromDate}/${toDate}`, options)
        .then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
          .then(uril => {
            var link = document.createElement("a");
            link.href = uril;
            link.download = `UserSignupData.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve(true)
          }) : reject("File not found"))
    }
    catch (error) {
      reject(error)
    }
  })
};

export const addUserCustomer = async (customerName, contactNumber, customerEmail, customerAddress, customerGovIdNo, customerPilotLicenseId, gst_no, cust_type) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      customerName: customerName,
      contactNumber: contactNumber,
      customerEmail: customerEmail,
      customerAddress: customerAddress,
      customerGovIdNo: customerGovIdNo,
      customerPilotLicenseId: customerPilotLicenseId,
      gst_no: gst_no,
      cust_type: cust_type
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_USER_CUSTOMER}`, options);
  return await res.json();
};

export const updateUserCustomer = async (customerName, contactNumber, customerEmail, customerAddress, customerGovIdNo, customerPilotLicenseId, gst_no, customerId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      customerName: customerName,
      contactNumber: contactNumber,
      customerEmail: customerEmail,
      customerAddress: customerAddress,
      customerGovIdNo: customerGovIdNo,
      customerPilotLicenseId: customerPilotLicenseId,
      gst_no: gst_no,
      customerId: customerId
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_USER_CUSTOMER}`, options);
  return await res.json();
};

export const getAllUserCustomer = async (cust_type, limit, searchString) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_ALL_USER_CUSTOMER}/${cust_type}/${limit}${searchString ? ('?searchString=' + searchString) : ""}`,
    options
  );
  return await res.json();
}

export const deleteUserCustomer = async (customerId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_USER_CUSTOMER}/${customerId}`, options);
  return await res.json();
};

export const addUserCustomerDroneLease = async (customer_id, droneid, fromDate, toDate, dronePerDayRent) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "customer_id": customer_id,
      "droneid": droneid,
      "fromDate": fromDate,
      "toDate": toDate,
      "dronePerDayRent": dronePerDayRent,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_USER_CUSTOMER_DRONE_LEASE}`, options);
  return await res.json();
};

export const getAllUserDronesLease = async (customerId, droneId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    customerId ? `${GET_ALL_USER__DRONES_LEASE}?userCustomerId=${customerId}` : droneId ? `${GET_ALL_USER__DRONES_LEASE}?droneId=${droneId}` : `${GET_ALL_USER__DRONES_LEASE}`,
    options
  );
  return await res.json();
}

export const deleteUserCustomerDroneLease = async (leaseId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_USER_CUSTOMER_DRONE_LEASE}/${leaseId}`, options);
  return await res.json();
};

export const updateUserCustomerDroneLease = async (fromDate, toDate, leaseId, droneid, dronePerDayRent) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "fromDate": fromDate,
      "toDate": toDate,
      "leaseId": leaseId,
      "droneid": droneid,
      "dronePerDayRent": dronePerDayRent,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_USER_CUSTOMER_DRONE_LEASE}`, options);
  return await res.json();
};

export const addRemoveDroneLease = async (droneid, lease_drone) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "droneid": droneid,
      "lease_drone": lease_drone
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_REMOVE_DRONE_LEASE}`, options);
  return await res.json();
};

export const updateDroneRent = async (droneid, rentAmount) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "droneid": droneid,
      "rentAmount": rentAmount
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_DRONE_RENT}`, options);
  return await res.json();
};

export const getLeaseStat = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_LEASE_STAT}`,
    options
  );
  return await res.json();
}

export const invoiceData = async (lease_id, discount, withGst, total_amount, droneid, paid_status, customer_id, gst, dailycharges) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "lease_id": lease_id,
      "discount": discount,
      "withGst": withGst,
      "total_amount": total_amount,
      "droneid": droneid,
      "paid_status": paid_status,
      "customer_id": customer_id,
      "gst": gst,
      "dailycharges": dailycharges
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_DRONE_LEASE_INVOICE}`, options);
  return await res.json();
};

export const getLeaseInvoices = async (customerId, droneId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    customerId ? `${GET_ALL_LEASE_INVOICES}?userCustomerId=${customerId}` : droneId ? `${GET_ALL_LEASE_INVOICES}?droneId=${droneId}` : `${GET_ALL_LEASE_INVOICES}`,
    options);
  return await res.json();
};

export const updateInvoiceStaus = async (paid_status, lease_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      "paid_status": paid_status,
      "lease_id": lease_id,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_LEASE_INVOICES}`, options);
  return await res.json();
};

export const deleteLeaseInvoice = async (invoiceId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${DELETE_LEASE_INVOICE}/${invoiceId}`, options);
  return await res.json();
};

export const shareInvoicePdf = async (invoiceId, pdfFile, customerMailId, customerName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "pdfFile": pdfFile,
      "invoiceId": invoiceId,
      "customerMailId": customerMailId,
      "customerName": customerName,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SHARE_INVOICE_PDF}`, options);
  return await res.json();
};

export const loginUser = (username, password, clientName) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ username, password })
    };

    try {
      fetch(`${LOGIN_USER}?clientName=${clientName}`, options)
        .then(response => response.status == "200" ?
          resolve(response.json()) : reject(response.json()))

    } catch (error) {

      reject({ message: "Something went wrong while logging." })
    }
  })
}

export const signUp = (user) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ user, registrationType: user.registrationType })
    };

    try {
      fetch(`${SIGNUP}`, options)
        .then(response => response.status == "201" ?
          resolve(response.json()) : reject(response.json()))

    } catch (error) {

      reject({ message: "Something went wrong while logging." })
    }
  })
}

export const resetPassoword = (email) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: "POST",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        "Authorization": "Bearer 75961307-b913-4fd7-8250-f6182548a3ce",
      },
      body: JSON.stringify({ email })
    };

    try {
      fetch(`${RESET_PASSWORD}`, options)
        .then(response => response.status == "200" ?
          resolve(response.json()) : reject(response.json()))
    } catch (error) {
      reject({ message: "Something went wrong while password reset." })
    }
  })
}

export const sendUserAuthOtp = async (username, password, sendOTPOnMobile = false, sendOTP) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "Authorization": "Bearer 75961307-b913-4fd7-8250-f6182548a3ce",
    },
    body: JSON.stringify({
      username: username, password: password, sendOTPOnMobile, sendOTP
    })
  };
  const res = await fetch(
    `${USER_AUTH_OTP}`,
    options
  );
  return res
};

export const changePhoneNumberReqOTP = async (phone) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({
      phone
    })
  };
  const res = await fetch(
    `${CHANGE_PHONE_NUMBER_REQ_OTP}`,
    options
  );
  return res
};

export const verifyOtp = async (username, password, otp) => {
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "Authorization": "Bearer 75961307-b913-4fd7-8250-f6182548a3ce",
    },
    body: JSON.stringify({
      username: username, password: password, otp: otp
    })
  };
  const res = await fetch(
    `${USER_VERIFY_OTP}`,
    options
  );
  return res
};

export const updateNumberWithOTP = async (phone, otp) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({
      phone, otp
    })
  };
  const res = await fetch(
    `${UPDATE_NUMBER_WITH_OTP}`,
    options
  );
  return res
};

export const getOtpTimer = async (username) => {
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "Authorization": "Bearer 75961307-b913-4fd7-8250-f6182548a3ce",
    },
  };
  const res = await fetch(
    `${GET_OTP_TIMER}?username=${username}`,
    options
  );
  return res
};

export const updateTwoStepAuth = async (flag) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_TWO_STEP_AUTH}/${flag}`, options);
  if (res.status === 202 || res.status === 200) return await res.json();
  else throw (await res.json())
}

export const addSprayingPlot = async (plot_name, project_name, file, myAsset) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      'plot_name': plot_name,
      'project_name': project_name,
      'file': file,
      'myAsset': myAsset,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${ADD_SPRAYING_PLOT}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
};

export const updateSprayingPlot = async (plot_name, plotId, project_name, file, myAsset) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({
      'plot_name': plot_name,
      'plot_id': plotId,
      'project_name': project_name,
      'file': file,
      'myAsset': myAsset,
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${UPDATE_SPRAYING_PLOT}?teamId=${await getActiveTeamId()}`, options);
  return await res.json();
};

export const getAllSprayingPlots = async (project_name, searchString, pageSize, pageNumber, fromDate, toDate, orderBy, orderType) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  let url = `${GET_ALL_SPRAYING_PLOTS}/${project_name}?teamId=${await getActiveTeamId()}${searchString ? `&searchString=${encodeURIComponent(searchString)}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}${pageNumber ? `&pageNumber=${pageNumber}` : ''}${fromDate ? `&fromDate=${fromDate}` : ''}${toDate ? `&toDate=${toDate}` : ''}${orderBy ? `&orderBy=${orderBy}` : ''}${orderType ? `&orderType=${orderType}` : ''}`;
  const res = await fetch(url, options);
  return await res.json();
}

export const getSprayingPlotCordinates = async (plotId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_SPRAYING_PLOT_CORDINATES}/${plotId}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}

export const deleteSprayingPlot = async (plot_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_SPRAYING_PLOT}/${plot_id}?teamId=${await getActiveTeamId()}`,
    options
  );
  return await res.json();
}

export const getCountryData = async (fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_COUNTRY_COUNT}/${fromDate}/${toDate}`,
    options
  );
  return await res.json();
}

export const getCustomStats = async (type, value, fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_CUSTOM_STATS}/${type}/${value}/${fromDate}/${toDate}`,
    options
  );
  return await res.json();
}

export const getIndustryData = async (fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_INDUSTRY_DATA}/${fromDate}/${toDate}`,
    options
  );
  return await res.json();
}

export const getAllGreenSprayReports = async ({ searchString, fromDateTime, toDateTime, orderBy, orderType, pageSize, pageNumber, flightId }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_ALL_GREEN_SPRAY_REPORTS}?${searchString ? searchString : ' '}${fromDateTime ? `&fromDateTime=${fromDateTime}` : ''}${toDateTime ? `&toDateTime=${toDateTime}` : ''}${orderBy ? `&orderBy=${orderBy}` : ''}${orderType ? `&orderType=${orderType}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}${pageNumber ? `pageNumber=&${pageNumber}` : ''}${flightId ? `&flightId=${flightId}` : ''}`,
    options
  );
  return await res.json();
}

export const getGreenSprayReportStat = async (graphUnit, fromDate, toDate) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_GREEN_SPRAY_REPORT_STAT}/${graphUnit}/${fromDate}/${toDate}`,
    options
  );
  return await res.json();
}

export const deleteSprayingReport = async (sprayReportId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_SPRAYING_REPORT}/${sprayReportId}`,
    options
  );
  return await res.json();
}

export const addGreenSeason = async (seasonName, fromDateSeason, toDateSeason) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      'seasonName': seasonName,
      'startDate': fromDateSeason,
      "endDate": toDateSeason
    })
  };

  const res = await fetch(
    `${ADD_GREEN_SEASON}`,
    options
  );
  return await res.json();
};

export const updateGreenSeason = async (id, seasonName, fromDateSeason, toDateSeason) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      'id': id,
      'seasonName': seasonName,
      'start_date': fromDateSeason,
      "end_date": toDateSeason
    })
  };

  const res = await fetch(
    `${UPDATE_GREEN_SEASON}`,
    options
  );
  return await res.json();
};

export const getAllGreenSeason = async (limit) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_ALL_GREEN_SEASON}/${limit}`,
    options
  );
  return await res.json();
}

export const deleteGreenSeason = async (seasonId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${DELETE_GREEN_SEASON}/${seasonId}`,
    options
  );
  return await res.json();
}

//incident report APIs start
export const uploadIncidentFile = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: data,
    headers: {
      "Allow-Control-Access-Origin": URL,
      authorization: `Bearer ${token}`,
    },
  };
  const url = SERVER + `/AeroMegh/incidentReport/uploadFile?teamId=${await getActiveTeamId()}`;
  const res = await fetch(url, options);
  return res
}

export const saveIncidentReport = async (data) => {
  return new Promise(async (resolve, reject) => {

    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await fetch(
        INCIDENT_REPORT,
        options
      );
      if (res.status === 201) res.json().then(data => {
        resolve(data)
      })
      else throw ({ message: "Somothing went wrong" })
    }
    catch (e) {
      console.log(e)
      reject({
        error: "Something went Wrong"
      })
    }
  })
};

export const getIncidentReports = () => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "GET",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    const res = await fetch(
      `${INCIDENT_REPORTS}/${await getActiveTeamId()}`,
      options
    );

    if (res.status === 200) res.json().then(data => {
      resolve(data)
    })
    else res.json().then(data => {
      reject({
        message: data.error || "sothing went wrong while getting incident reports."
      })
    })
  })
}

export const deleteIncidentReport = (id) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "DELETE",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    const res = await fetch(
      `${INCIDENT_REPORT}/${id}`,
      options
    );

    if (res.status === 200) res.json().then(data => {
      resolve(data)
    })
    else res.json().then(data => {
      reject({
        message: data.error || "sothing went wrong while deleting incident reports."
      })
    })
  })
}

export const getNotifications = async (limit) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(
    `${GET_NOTIFICATIONS}/${await getActiveTeamId()}${limit ? `?limit=${limit}` : ''}`,
    options
  );
  return await res.json();
}

export const generateAPIKey = async (apiName) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({
      "apiName": apiName
    }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GENERATE_API_KEY}`, options);
  return await res.json();
}

export const getAllAPIkeys = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GENERATE_API_KEY}`, options);
  return await res.json();
}

export const deleteAPIKey = async (id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GENERATE_API_KEY}/${id}`, options);
  return await res.json();
}

export const getStorageUserByService = (serviceName) => {
  return new Promise(async (resolve, reject) => {
    if (!SESSION_ID_CHANGED) {
      SESSION_ID_CHANGED = true;
      response = await sessionCheck();
    }
    token = response.access_token;
    const options = {
      method: "GET",
      headers: {
        "Allow-Control-Access-Origin": URL,
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
    };
    const res = await fetch(
      `${GET_STORAGE_BY_SERVICES}/${serviceName}?teamId=${await getActiveTeamId()}`,
      options
    );
    if (res.status === 200) res.json().then(data => {
      resolve(data)
    })
    else {
      resolve({ error: "Something went wrong while getting storage usage." })
    }
  })
}

export const importProjectFromService = async (projectId, fromService, toService) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    body: JSON.stringify({ projectId: projectId, fromService: fromService, toService: toService }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/AeroMegh/importProjectFromToservice`, options);
  if (res.status === 200) return await res.json();
  else if (res.status === 400) return ({
    error: "Project is already exist with same name."
  })
  else return await res.json();
};


export const getUserStorage = async (userid) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };

  const res = await fetch(`${GET_USER_STORAGE}${userid ? `?userid=${userid}` : ""}`, options);

  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json());
    else reject({
      error: "Something went wrong while getting user storage details."
    })
  })
}


export const createOrder = async (order) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ order })
  };
  const res = await fetch(`${SERVER}/razorpay/createOrder`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const oneTimePayment = async (order) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ order })
  };
  const res = await fetch(`${SERVER}/razorpay/oneTimePayment`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}


export const updateDroneSubscription = async (order) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ order })
  };
  const res = await fetch(`${SERVER}/razorpay/updateDroneSubscription`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const verifyRazorPayPayment = async (data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ response: data })
  };
  const res = await fetch(`${SERVER}/razorpay/verifyPayment`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const getPendingPayments = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/AeroMegh/SubModule/getPendingPayments`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const getUserDroneLimits = async (service) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/AeroMegh/SubModule/getUserDroneLimits?service=${service}`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const getUserSubscriptionDiscount = async (coupon_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/AeroMegh/SubModule/getUserSubscriptionDiscount/${coupon_id}`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const addDiscountCoupon = async (user_id, discountCoupon) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/adminTool/addDiscountCouponByAdmin/${discountCoupon.discount}?userid=${user_id}&coupon_name=${discountCoupon?.coupon_name}`, options);
  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const getAllDiscountCoupons = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/getAllDiscountCoupons`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const deleteDiscountCoupon = async (coupon_id) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/deleteDiscountCoupon/${coupon_id}`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const editDiscountCoupon = async (discountCoupon) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(discountCoupon)
  };
  const res = await fetch(`${SERVER}/editDiscountCoupon`, options);
  return new Promise(async (resolve, reject) => {

    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const getAnalytics = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };

      const res = await fetch(`${GET_ANALYTICS}`, options);
      res.json().then(result => {
        resolve(result);
      }).catch(err => {
        reject({ "Error ": err })
      })
    })
  }
};

// get all fligts
export const getGCSFlights = async ({ pilots, drones, fromDateTime, toDateTime, orderBy, orderType, pageSize, pageNumber, projectName, planName }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };

      try {
        const res = await fetch(`${GET_GCS_FLIGHTS}?pilots=${JSON.stringify(pilots || [])}&drones=${JSON.stringify(drones || [])}&teamId=${await getActiveTeamId()}${fromDateTime ? `&fromDateTime=${fromDateTime}` : ""}${toDateTime ? `&toDateTime=${toDateTime}` : ""}${orderBy ? `&orderBy=${orderBy}` : ""}${orderType ? `&orderType=${orderType}` : ""}${pageSize ? `&pageSize=${pageSize}` : ""}${pageNumber ? `&pageNumber=${pageNumber}` : ""}${projectName ? `&projectName=${projectName}` : ""}${planName ? `&planName=${planName}` : ""}`, options);
        if (res.status === 200) resolve((await res.json()).flights)
        else if (res.status === 204) resolve([])
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while getting flights."
        })
      }
    })
  }
}

export const getGCSFlightsCSVData = async ({ pilots, drones, fromDateTime, toDateTime, orderBy, orderType, projectName, planName }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };

      try {
        const res = await fetch(`${GET_GCS_FLIGHTS_CSV_DATA}?pilots=${JSON.stringify(pilots || [])}&drones=${JSON.stringify(drones || [])}&teamId=${await getActiveTeamId()}${fromDateTime ? `&fromDateTime=${fromDateTime}` : ""}${toDateTime ? `&toDateTime=${toDateTime}` : ""}${orderBy ? `&orderBy=${orderBy}` : ""}${orderType ? `&orderType=${orderType}` : ""}${projectName ? `&projectName=${projectName}` : ""}${planName ? `&planName=${planName}` : ""}`, options);
        if (res.status === 200) resolve((await res.json()).flights)
        else if (res.status === 204) resolve([])
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while getting flights."
        })
      }
    })
  }
}

// get gcs flight stats
export const getGCSFlightStats = async ({ pilots, drones, fromDateTime, toDateTime, projectName, planName }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await fetch(`${GET_GCS_FLIGHT_STATS}?pilots=${JSON.stringify(pilots || [])}&drones=${JSON.stringify(drones || [])}&teamId=${await getActiveTeamId()}${fromDateTime ? `&fromDateTime=${fromDateTime}` : ""}${toDateTime ? `&toDateTime=${toDateTime}` : ""}${projectName ? `&projectName=${projectName}` : ""}${planName ? `&planName=${planName}` : ""}`, options);
        if (res.status === 200) resolve((await res.json()).flightsStats)
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while getting flights statistics."
        })
      }

    })
  }
}

// get gcs flight stats
export const getGCSFlightsGraphStats = async ({ pilots, drones, fromDateTime, toDateTime, projectName, planName }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await fetch(`${GET_GCS_FLIGHT_GRAPH_STATS}?pilots=${JSON.stringify(pilots || [])}&drones=${JSON.stringify(drones || [])}&teamId=${await getActiveTeamId()}${fromDateTime ? `&fromDateTime=${fromDateTime}` : ""}${toDateTime ? `&toDateTime=${toDateTime}` : ""}${projectName ? `&projectName=${projectName}` : ""}${planName ? `&planName=${planName}` : ""}`, options);
        if (res.status === 200) resolve((await res.json()).flightsGraphStats)
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while getting flights graph statistics."
        })
      }

    })
  }
}

export const getGCSFlightPilotsStats = async ({ drones, fromDateTime, toDateTime, orderBy, orderType, pageSize, pageNumber }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await fetch(`${GET_GCS_FLIGHT_PILOTS_STATS}?drones=${JSON.stringify(drones || [])}&teamId=${await getActiveTeamId()}${fromDateTime ? `&fromDateTime=${fromDateTime}` : ""}${toDateTime ? `&toDateTime=${toDateTime}` : ""}${orderBy ? `&orderBy=${orderBy}` : ""}${orderType ? `&orderType=${orderType}` : ""}${pageSize ? `&pageSize=${pageSize}` : ""}${pageNumber ? `&pageNumber=${pageNumber}` : ""}`, options);
        if (res.status === 200) resolve((await res.json()).pilotsStats)
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while getting flights pilots statistics."
        })
      }

    })
  }
}

export const getGCSFlightPilots = async (filter) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await fetch(`${GET_GCS_FLIGHT_PILOTS}?drones=${JSON.stringify(filter?.drones || [])}&teamId=${await getActiveTeamId()}${filter?.fromDateTime ? `&fromDateTime=${filter?.fromDateTime}` : ""}${filter?.toDateTime ? `&toDateTime=${filter?.toDateTime}` : ""}${filter?.orderBy ? `&orderBy=${filter?.orderBy}` : ""}${filter?.orderType ? `&orderType=${filter?.orderType}` : ""}${filter?.pageSize ? `&pageSize=${filter?.pageSize}` : ""}${filter?.pageNumber ? `&pageNumber=${filter?.pageNumber}` : ""}`, options);
        if (res.status === 200) resolve((await res.json()).pilots)
        else if (res.status === 204) resolve([])
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while getting flights pilots."
        })
      }

    })
  }
}

export const getSystemStats = async (interval, from_date, to_date) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const queryStr = `?interval=${interval}&from_date=${from_date}&to_date=${to_date}`;

  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${GET_SYSTEM_STATS}${queryStr}`, options);
  if (res.status === 200) return await res.json();
  else if (res.status === 400) return ({
    error: "Something went wrong"
  })
};

export const getGCSFlightPilotsGraphStats = async ({ drones, fromDateTime, toDateTime, orderBy, orderType, pageSize, pageNumber }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await fetch(`${GET_GCS_FLIGHT_PILOTS_GRAPH_STATS}?drones=${JSON.stringify(drones || [])}&teamId=${await getActiveTeamId()}${fromDateTime ? `&fromDateTime=${fromDateTime}` : ""}${toDateTime ? `&toDateTime=${toDateTime}` : ""}${orderBy ? `&orderBy=${orderBy}` : ""}${orderType ? `&orderType=${orderType}` : ""}${pageSize ? `&pageSize=${pageSize}` : ""}${pageNumber ? `&pageNumber=${pageNumber}` : ""}`, options);
        if (res.status === 200) resolve((await res.json()).pilotsGraphStats)
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while getting flights pilots graph statistics."
        })
      }

    })
  }
}

export const getGCSFlightsAcresGraphStats = async ({ pilots, drones, fromDateTime, toDateTime }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await fetch(`${GET_GCS_FLIGHT_ACRES_GRAPH_STATS}?pilots=${JSON.stringify(pilots || [])}&drones=${JSON.stringify(drones || [])}&teamId=${await getActiveTeamId()}${fromDateTime ? `&fromDateTime=${fromDateTime}` : ""}${toDateTime ? `&toDateTime=${toDateTime}` : ""}`, options);
        if (res.status === 200) resolve((await res.json()).flightsAcresGraphStats)
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while getting flights acres graph statistics."
        })
      }
    })
  }
}

export const getGCSDrones = async (filter) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await fetch(`${GET_GCS_DRONES}?teamId=${await getActiveTeamId()}${filter?.searchString ? `&searchString=${filter?.searchString}` : ""}${filter?.fromDateTime ? `&fromDateTime=${filter?.fromDateTime}` : ""}${filter?.toDateTime ? `&toDateTime=${filter?.toDateTime}` : ""}${filter?.orderBy ? `&orderBy=${filter?.orderBy}` : ""}${filter?.orderType ? `&orderType=${filter?.orderType}` : ""}${filter?.pageSize ? `&pageSize=${filter?.pageSize}` : ""}${filter?.pageNumber ? `&pageNumber=${filter?.pageNumber}` : ""}`, options);
        if (res.status === 200) resolve(await res.json())
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while getting flights pilots graph statistics."
        })
      }

    })
  }
}

export const getFirstAndLastFlightTimeAndDate = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "GET",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await fetch(`${GET_FIRST_AND_LAST_FLIGHT_TIMES}?teamId=${await getActiveTeamId()}`, options);
        if (res.status === 200) resolve((await res.json()).dates)
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while getting flights data."
        })
      }

    })
  }
}

export const deleteFlightData = async (flightId) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  if (token === "") return Promise.reject("Error");
  else {
    return new Promise(async (resolve, reject) => {
      const options = {
        method: "DELETE",
        headers: {
          "Allow-Control-Access-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await fetch(`${DELETE_FLIGHT_DATA}/${flightId}`, options);
        if (res.status === 200) resolve(await res.json())
        else reject(await res.json())
      } catch (e) {
        reject({
          message: "Something went wrong while deleting flight data."
        })
      }
    })
  }
}

export const addDroneAddOn = async (Data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(Data)
  };
  const res = await fetch(ADD_DRONE_ADD_ON, options);
  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const updateDroneAddOn = async (Data) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(Data)
  };
  const res = await fetch(UPDATE_DRONE_ADD_ON, options);
  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}


export const registerDrone = async (drone) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(drone)
  };
  const res = await fetch(`${SERVER}/AeroMegh/drone/registration`, options);
  return new Promise(async (resolve, reject) => {
    if (res.status === 201) resolve(await res.json());
    else reject(await res.json())
  })
}

export const updateErrorNotificationLastVisited = async (last_visited) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  const res = await fetch(`${SERVER}/AeroMegh/errorNotification/${last_visited}`, options);
  return new Promise(async (resolve, reject) => {
    if (res.status === 200) resolve(await res.json());
    else reject(await res.json())
  })
}

export const generateFlightsWeeklyReport = async (fromDateTime, toDateTime) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {

    fetch(`${SERVER}/AeroMegh/AeroGCSEnterprise/report/download${(toDateTime && fromDateTime) ? `?toDateTime=${toDateTime}&fromDateTime=${fromDateTime}` : ""}`, options).then(response => response.status == "200" ? response.blob().then(blob => window.URL.createObjectURL(blob))
      .then(uril => {
        var link = document.createElement("a");
        link.href = uril;
        link.download = `weekly_report.pdf`;
        document.body.appendChild(link);
        link.click();
        resolve(true)
      }) : response.status == '202' ? resolve(202) : reject("File not found"));
  })
}


export const getAllEmailsWithSettings = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {

    const res = await fetch(`${SERVER}/AeroMegh/AeroGCSEnterprise/emailSettings`, options)
    const data = await res.json()
    if (res.status == 200) {
      resolve(data.emails)
    } else {
      reject(data)
    }
  })
}


export const updateEmailSettings = async (emails) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({ emails })
  };
  return new Promise(async (resolve, reject) => {

    const res = await fetch(`${SERVER}/AeroMegh/AeroGCSEnterprise/emailSettings`, options)
    const data = await res.json()
    if (res.status == 200) {
      resolve(data)
    } else {
      reject(data)
    }
  })
}



export const addEmailToSettings = async (email) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    console.log(email)
    const res = await fetch(`${SERVER}/AeroMegh/AeroGCSEnterprise/emailSettings/${email}`, options)
    const data = await res.json()
    if (res.status == 200) {
      resolve(data.email)
    } else {
      reject(data)
    }
  })
}


export const deleteEmailFromSettings = async (email) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "DELETE",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    console.log(email)
    const res = await fetch(`${SERVER}/AeroMegh/AeroGCSEnterprise/emailSettings/${email}`, options)
    const data = await res.json()
    if (res.status == 200) {
      resolve(data.deletedEmailID)
    } else {
      reject(data)
    }
  })
}


export const getAllSoftwareVersions = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {

    const res = await fetch(`${SERVER}/AeroMegh/admin/softwareVersion`, options)
    const data = await res.json()
    if (res.status == 200) {
      resolve(data?.software_versions || [])
    } else {
      reject(data)
    }
  })
}



export const addSoftwareVersion = async (software_version) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "POST",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({ software_version })
  };
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${SERVER}/AeroMegh/admin/softwareVersion`, options)
    const data = await res.json()
    if (res.status == 200) {
      resolve(data?.addedVersion)
    } else {
      reject(data)
    }
  })
}


export const updateSoftwareVersion = async (software_version) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "PUT",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
    body: JSON.stringify({ software_version })
  };
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${SERVER}/AeroMegh/admin/softwareVersion/${software_version.id}`, options)
    const data = await res.json()
    if (res.status == 200) {
      resolve(data?.updatedVersion)
    } else {
      reject(data)
    }
  })
}


export const getAllDroneLocations = async (searchString, isAdmin, { pageSize, pageNumber, orderBy, orderType, selectedUser, selectedDrone }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${GET_ALL_DRONE_LOCATIONS}?searchString=${searchString}${isAdmin ? `&isAdmin=${isAdmin}` : ''}${selectedUser?.length > 0 ? `&selectedUser=${selectedUser[0].value.userid}` : ''}${selectedDrone?.length > 0 ? `&selectedDrone=${selectedDrone[0].value.drone_id}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}${pageNumber ? `&pageNumber=${pageNumber}` : ''}${orderBy ? `&orderBy=${orderBy}` : ''}${orderType ? `&orderType=${orderType}` : ''}`, options)
    const data = await res.json()
    if (res.status == 200) {
      resolve(data)
    } else {
      reject(data)
    }
  })
}

export const getDroneFlightLocations = async (droneId, isAdmin) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${GET_DRONE_FLIGHT_LOCATIONS}/${droneId}?teamId=${await getActiveTeamId()}${isAdmin ? `&isAdmin=${isAdmin}` : ''}`, options)
    const data = await res.json()
    if (res.status == 200) {
      resolve(data.locations)
    } else {
      reject(data)
    }
  })
}

export const getAllAdminStat = async () => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${GET_ADMIN_STATS}`, options)
    const data = await res.json()

    if (res.status == 200) {
      resolve(data)
    } else {
      reject({ message: "Something wents wrong !" })
    }
  })
}

export const getCropsAndAgrochemicals = async (searchString, { pageSize, pageNumber, orderBy, orderType }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${GET_CROP_AND_AGROCHEMICALS}?${searchString ? `&searchString=${searchString}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}${pageNumber ? `&pageNumber=${pageNumber}` : ''}${orderBy ? `&orderBy=${orderBy}` : ''}${orderType ? `&orderType=${orderType}` : ''}`, options)
    const data = await res.json()

    if (res.status == 200) {
      resolve(data)
    } else {
      reject({ message: "Something wents wrong !" })
    }
  })
}

export const getDroneAccessListAPI = async (teamId, droneId, uin) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${DRONE_ACCESS}/${teamId}/${droneId}/${uin}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const addDroneAccessAPI = async (droneId, uin, teamId, roleId, username, notificationObj) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "POST",
    body: JSON.stringify({ droneId, uin, teamId, roleId, username, notificationObj }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(DRONE_ACCESS, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const removeDroneAccessAPI = async (droneId, uin, teamId, roleId, username, notificationObj) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = await {
    method: "DELETE",
    body: JSON.stringify({ droneId, uin, roleId, notificationObj }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(`${DRONE_ACCESS}/${teamId}/${droneId}/${uin}/${username}`, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const updateDroneAccessAPI = async (droneId, uin, teamId, username, roleId, notificationObj) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = await response.access_token;
  const options = {
    method: "PUT",
    body: JSON.stringify({ droneId, uin, teamId, username, roleId, notificationObj }),
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      authorization: `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch(DRONE_ACCESS, options);
      if (res.status === 200) resolve(await res.json())
      else throw (await res.json())
    } catch (e) {
      console.log(e)
      reject()
    }
  })
};

export const getAllEmailLogs = async (searchString, { pageSize, pageNumber, orderBy, orderType }) => {
  if (!SESSION_ID_CHANGED) {
    SESSION_ID_CHANGED = true;
    response = await sessionCheck();
  }
  token = response.access_token;
  const options = {
    method: "GET",
    headers: {
      "Allow-Control-Access-Origin": URL,
      "Content-Type": "application/json",
      "authorization": `Bearer ${token}`,
    },
  };
  return new Promise(async (resolve, reject) => {
    const res = await fetch(`${ADMIN_EMAIL_LOGS}?${searchString ? `&searchString=${searchString}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}${pageNumber ? `&pageNumber=${pageNumber}` : ''}${orderBy ? `&orderBy=${orderBy}` : ''}${orderType ? `&orderType=${orderType}` : ''}`, options)
    const data = await res.json()

    if (res.status == 200) {
      resolve(data)
    } else {
      reject({ message: "Something wents wrong !" })
    }
  })
}

import * as API from './api.js';
import './style.css';
import Loader from './icons/loader.svg';
import React, { Component } from 'react';
import './style.css';
import CsvDownload from 'react-json-to-csv'
import ExportCSV from "../src/assets/Export.png"
import TableView from './ReusableComponents/TableView/TableView.js';
import LoaderComponent from './ReusableComponents/LoaderComponent.js';
import { BreadCrumbs } from './ReusableComponents/BreadCrumbs.js';
import { ActivityPopup } from './ReusableComponents/ActivityPopup.js';
import NoContent from './ReusableComponents/NoContent.js';
import InteractiveGraph from './AeroGCSEnterprise/Components/InteractiveGraph.js';
import { addUserAnalytic, updateOutTime } from './ReusableComponents/reusableFunctions.js';
export default class RMDetailedLog extends Component {

    state = {
        user: {},
        key2: 'AS_TELE_DATA',
        teleData: [],
        log: [],
        log1: [],
        type: "",
        loading: true,
        logsLoad: true,
        userperpage: 15,
        currentpage: 1,
        totalCount: 0,
        taskdisplay: [],
        logsDisplay: [],
        sidebarMode: (this.props.location.state ? this.props.location.state.sidebarMode : "shrunk"),
        activityPopup: undefined,
        rollGraphData: [],
        pitchGraphData: [],
        yawGraphData: [],
        outId: ""
    }

    parentSidebarSwitch = () => {
        this.setState((state) => ({
            ...state,
            sidebarMode: (state.sidebarMode == "shrunk" ? "expanded" : "shrunk")
        }))
    }

    componentWillUnmount() { updateOutTime(this.state.outId) }

    componentWillMount() {
        document.title = "Flight Status Logs - RMP";
        if (this.props.location.state) {
            let { user, type } = this.props.location.state;
            this.setState((state) => ({
                ...state,
                user,
                type
            }), () => { addUserAnalytic(this.props.location.pathname).then(id => this.setState({ outId: id })) })
        } else {
            API.getUserType().then(([blah, type, user]) => {
                this.setState((state) => ({
                    ...state,
                    user,
                    type
                }), () => {
                    addUserAnalytic(this.props.location.pathname)
                        .then(id => {
                            this.setState((state) => ({
                                outId: id
                            }))
                        })
                })
            }, (e) => {
                window.location.replace("/login");
            })
        }
    }

    getDownloadLogs = () => {
        this.setState({ logsLoad: true })
        let state = this.props.location.state
        API.log(state.flightId, this.state.key2, 0, 0).then((result) => {
            let logs = []
            let rollGraphData = []
            let pitchGraphData = []
            let yawGraphData = []

            for (let l of result.logs) {
                let dt = JSON.parse(l.data)
                let t = new Date(l.timestamp)
                let t1 = t.toLocaleTimeString()
                let d = t.toDateString()
                dt.time = `${t1}, ${d}`
                logs.push(dt)
                let date = new Date(l.timestamp).getTime() / 1000
                if (dt.rol && date) rollGraphData.push({ value: dt.rol, time: date })
                if (dt.pit && date) pitchGraphData.push({ value: dt.pit, time: date })
                if (dt.yaw && date) yawGraphData.push({ value: dt.yaw, time: date })
            }
            this.setState(state => ({
                ...state,
                log1: logs,
                rollGraphData: rollGraphData,
                pitchGraphData: pitchGraphData,
                yawGraphData: yawGraphData,
                logsLoad: false
            }))
        })

    }

    getLogs = () => {
        this.setState((state) => ({
            ...state,
            loading: true
        }), () => {
            const { currentpage, userperpage } = this.state;
            const lastIndex = currentpage * userperpage;
            const firstpage = lastIndex - userperpage;
            let state = this.props.location.state

            API.log(state.flightId, this.state.key2, userperpage, firstpage).then((result) => {
                let logs = result.logs;
                let count = 0;
                this.setState(state => ({
                    ...state,
                    log: logs,
                    totalCount: result.logs[0]?.totalCount.count,
                    loading: false
                }))
                let temp1 = result.logs;
                result.logs.forEach(element => {
                    if (element.key == 'AS_TELE_DATA') {
                        let temp = JSON.parse(element.data)
                        temp.time = element.timestamp
                        temp.key = element.key
                        count++
                        if (count == temp1.length) {
                            this.setState({ loading: false })
                        }
                    }
                });
            }).catch(err => {
                this.setState({
                    loading: false,
                    activityPopup: {
                        open: true,
                        item: "ERROR",
                        icon: "ERROR",
                        msg: err,
                    }
                })
            })
        })
    }

    downloadCSV = (data) => {
        const csvRows = [];
        const headers = ['alt', 'bat', 'dir', 'lat', 'lon', 'mod', 'pit', 'rol', 'sat', 'vel', 'yaw', 'timestamp', 'liquidConsumed',
            'sprayedArea', 'sprayedDistance', 'sprayPumpRate', 'flowRate', 'liveObstacleDistance', 'remainingLiquidPercentage', 'time'];
        csvRows.push(headers.join(','));
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }
        const csvString = csvRows.join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'export.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    componentDidMount() {
        this.getLogs()
        this.getDownloadLogs()
    }

    render() {
        let { user } = this.props.location.state
        const { flightId, keys, log, } = this.state
        return (
            <div className="wrapper">
                <ActivityPopup
                    item={this.state.activityPopup?.item}
                    open={this.state.activityPopup?.open}
                    icon={this.state.activityPopup?.icon}
                    action={this.state.activityPopup?.action}
                    msg={this.state.activityPopup?.msg}
                    close={() => { this.setState({ activityPopup: undefined }) }}
                    onClickOk={() => { }}
                />
                <div className="right-content-new"
                    onClick={
                        () => {
                            this.setState((state) => ({
                                ...state, sidebarMode: 'shrunk'
                            }))
                        }
                    }
                >
                    <div className="top-bar-new" style={{ display: "flex" }}><div className="top-bar-text-new">
                        <BreadCrumbs
                            data={[
                                {
                                    displayName: "AeroGCS",
                                    pathname: "/",
                                    state: {
                                        user
                                    }
                                },
                                {
                                    displayName: "Flights",
                                    pathname: "/aerogcsenterprise/flights",
                                    state: {
                                        user
                                    }
                                },
                                {
                                    displayName: "Flights Details",
                                    pathname: "/remote_mission/flight_details",
                                    state: { ...this.props.location.state }
                                },
                                {
                                    displayName: 'Detailed Logs',
                                }
                            ]}
                        />
                    </div>
                    </div>

                    <div className="main-content-new" style={{ height: "calc(100vh - 100px)" }}>
                        {this.state.loading && this.state.logsLoad ? <div style={{ height: "85vh", width: "91vw", display: "flex", textAlign: "center" }}>
                            <img src={Loader} style={{ height: "30vh", width: "100vw", margin: "auto" }} />
                        </div> :
                            <>
                                <div className='page-name' style={{ display: "flex", margin: "", width: "100%", justifyContent: "space-between", alignItems: 'center' }}>
                                    {/* <span>Flight Status Logs</span> */}
                                    <div style={{ marginLeft: "auto", width: "fit-content" }}>
                                        <div className="export-csv-button new-btn-lg" onClick={() => this.downloadCSV(this.state.log1)}>
                                            <img src={ExportCSV} className='export-csv-icon' />
                                            <span>
                                                Export CSV
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="graph">
                                    <InteractiveGraph
                                        showZoom
                                        style={{ height: "300px" }}
                                        title={'Roll'}
                                        tickLength={7}
                                        isPriceFromat={true}
                                        lineColor={"#FFB001"}
                                        topColor={'rgba(255, 176, 1, 0.4)'}
                                        bottomColor={'rgba(255, 176, 1, 0)'}
                                        dateTimeFormat={'h:MM:ss TT'}
                                        displayCurrentTime={true}
                                        data={this.state.rollGraphData}
                                        lineType={0}
                                        loading={this.state.logsLoad}
                                    />
                                    <InteractiveGraph
                                        showZoom
                                        style={{ height: "300px" }}
                                        title={'Pitch'}
                                        tickLength={7}
                                        isPriceFromat={true}
                                        lineColor={"#A70F2F"}
                                        topColor={'rgba(167, 17, 47, 0.4)'}
                                        bottomColor={'rgba(167, 17, 47, 0)'}
                                        dateTimeFormat={'h:MM:ss TT'}
                                        displayCurrentTime={true}
                                        data={this.state.pitchGraphData}
                                        lineType={0}
                                        loading={this.state.logsLoad}
                                    />
                                    <InteractiveGraph
                                        showZoom
                                        style={{ height: "300px" }}
                                        title={'Yaw'}
                                        tickLength={7}
                                        isPriceFromat={true}
                                        lineColor={"#0000FF"}
                                        topColor={'rgba(0, 0, 255, 0.4)'}
                                        bottomColor={'rgba(0, 0, 255, 0)'}
                                        dateTimeFormat={'h:MM:ss TT'}
                                        displayCurrentTime={true}
                                        data={this.state.yawGraphData}
                                        lineType={0}
                                        loading={this.state.logsLoad}
                                    />
                                </div>
                                <div className={"rmdetailed-log-table-height"}>
                                    <TableView

                                        isLoading={this.state.loading}
                                        onClickRow={false}
                                        columns={[
                                            { Header: 'Time', accessor: 'timestamp', width: '10%', },
                                            { Header: 'Roll / Pitch / Yaw', accessor: 'rol', width: '20%', },
                                            { Header: 'Altitude', accessor: 'alt', width: '10%', },
                                            { Header: 'Speed', accessor: 'vel', width: '10%', },
                                            { Header: 'Latitude', accessor: 'lat', width: '20%', },
                                            { Header: 'Longitude', accessor: 'lon', width: '20%', },
                                            { Header: 'Battery', accessor: 'bat', width: '10%', }
                                        ]}
                                        data={log.map(logs => {
                                            return {
                                                ...logs,
                                                timestamp: new Date(logs?.timestamp).toLocaleString().split(',')[1],
                                                rol: parseFloat(JSON.parse(logs.data).rol).toFixed(2) + "," + parseFloat(JSON.parse(logs.data)?.pit).toFixed(2) + "," + parseFloat(JSON.parse(logs.data)?.yaw).toFixed(2),
                                                alt: parseFloat(JSON.parse(logs.data).alt),
                                                vel: JSON.parse(logs.data)?.vel ? parseFloat(JSON.parse(logs.data)?.vel).toFixed(4) : 0,
                                                lat: JSON.parse(logs.data)?.lat,
                                                lon: JSON.parse(logs.data)?.lon,
                                                bat: JSON.parse(logs.data)?.bat == 65535 ? 0 : (JSON.parse(logs.data)?.bat),
                                            }
                                        })}
                                        user={user}
                                        count={this.state.totalCount}
                                        onStateChange={(changedState) => {
                                            this.setState((state) => ({
                                                ...state,
                                                currentpage: changedState.pageNumber,
                                            }), () => {
                                                this.getLogs()
                                            })
                                        }}
                                    />
                                </div>
                            </>}
                    </div>
                </div>
            </div>
        );
    }
}
